import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import CookiePolicyScreen from '@app/components/policy/CookiePolicyScreen';

const CookiePolicyPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <CookiePolicyScreen />
    </>
  );
};

export default CookiePolicyPage;
