import SignUpAddDetailsPageForm from '@app/components/signUp/SignUpAddDetailsPageForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';

const SignUpAddDetailsPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <SignUpAddDetailsPageForm />
    </>
  );
};

export default SignUpAddDetailsPage;
