import { Dispatch, PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Company, FileUploadRequest } from '@app/interfaces/interfaces';
import {
  QueryCompanyRequest,
  addStatusToCompany,
  createCompany,
  getCompaniesWithPagination,
  getCompanyDetails,
  getCompanyListWithQuery,
  updateCompany,
} from '@app/api/company.api';
import { uploadFile } from '@app/api/file.api';
import { UploadProps } from 'antd/lib/upload';
import { removeSpaceAndRepalce } from '@app/utils/utils';
import { getImageSignedURL } from './userSlice';

export interface CompanyState {
  company: Company | null;
}

export interface UpdateCompanyRequest {
  companyDetails: {
    name?: string;
    city?: string;
    state?: string;
    country?: string;
    address?: string;
    image?: UploadProps | string;
  };
  companyId: string;
}

export interface AddStatusToCompanyRequest {
  companyId: string;
  statusDetails: {
    title: string;
    description: string;
    order: number;
    color: string;
  };
}

const initialState: CompanyState = {
  company: null,
};

export const doCreateCompany = createAsyncThunk('companies/createCompany', async (companyPayload: Company) =>
  createCompany(companyPayload),
);

export const doUpdateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (companyPayload: UpdateCompanyRequest, { dispatch }) => {
    return updateCompany(companyPayload).then(async (company) => {
      company && (await setCompanyDetails(company, dispatch));
      return company;
    });
  },
);

export const doAddStatusToCompany = createAsyncThunk(
  'companies/addStatusToCompany',
  async (payload: AddStatusToCompanyRequest) => addStatusToCompany(payload),
);

export const getCompanyList = createAsyncThunk(
  'companies/getCompanies',
  async (projectPayload: QueryCompanyRequest) => {
    const res = await getCompanyListWithQuery(projectPayload);
    return res;
  },
);

export const doGetCompaniesWithPagination = createAsyncThunk(
  'companies/fetchCompanyListWithPagination',
  async (projectPayload: QueryCompanyRequest) => {
    const res = await getCompaniesWithPagination(projectPayload);
    return res;
  },
);

export const doFetchCompanyDetails = createAsyncThunk(
  'companies/getCompanyDetails',
  async (companyId: string, { dispatch }) => {
    return getCompanyDetails(companyId).then(async (company) => {
      company && (await setCompanyDetails(company, dispatch));
      return company;
    });
  },
);

export const doUploadCompanyPicture = createAsyncThunk(
  'companies/updateCompanyPicture',
  async (filePayload: FileUploadRequest) => uploadFile(filePayload),
);

export const setCompany = createAction<PrepareAction<Company>>('companies/setCompany', (newCompany) => {
  return {
    payload: newCompany,
  };
});

export const setCompanyDetails = async (company: Company, dispatch: Dispatch): Promise<Company> => {
  if (company.image) {
    const companyName = removeSpaceAndRepalce(company.name, '-');
    const imageURL: string | null =
      companyName &&
      company?.image?.name &&
      (await getImageSignedURL(company?.image?.name, `${companyName}/${company?.image?.entity}`));
    if (imageURL) {
      company.image.signedURL = imageURL.toString();
    }
  }
  dispatch(setCompany(company));
  return company;
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCompany, (state, action) => {
      state.company = action.payload;
    });
  },
});

export default companySlice.reducer;
