import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';

const ProjectLayout: React.FC = () => {
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  const { isTablet } = useResponsive();
  const projectData = useAppSelector((state) => state?.project.projectDetails);
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/projects');

  useEffect(() => {
    isTablet && location.pathname === '/projects' && navigate(`${projectData?.id}`);
  }, [isTablet, location.pathname, navigate, projectData?.id]);

  return (
    <>
      <PageTitle></PageTitle>
      <Row gutter={[30, 30]}>
        {isMenuShown && (
          <Col xs={24} md={24} xl={30}>
            <Outlet />
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProjectLayout;
