import { Dates } from '@app/constants/Dates';
import { ANY_OBJECT } from '@app/interfaces/interfaces';
import { capitalize, sortAscendingDecending } from 'utils/utils';

export const userColumns: Array<ANY_OBJECT> = [
  {
    title: 'tables.key',
    dataIndex: 'id',
    width: '10%',
    editable: false,
    align: 'center' as const,
    render: (text: string, record: ANY_OBJECT, index: number) => index + 1,
    sorter: (a: ANY_OBJECT, b: ANY_OBJECT) => a.id - b.id,
  },
  {
    title: 'tables.firstName',
    dataIndex: 'firstname',
    width: '15%',
    editable: true,
    inputType: 'text',
    align: 'center' as const,
    sorter: (a: ANY_OBJECT, b: ANY_OBJECT) => sortAscendingDecending(a.firstname, b.firstname),
    render: (text: string) => (text && capitalize(text)) || '-',
  },
  {
    title: 'tables.lastName',
    dataIndex: 'lastname',
    width: '15%',
    editable: true,
    inputType: 'text',
    align: 'center' as const,
    sorter: (a: ANY_OBJECT, b: ANY_OBJECT) => sortAscendingDecending(a.lastname, b.lastname),
    render: (text: string) => (text && capitalize(text)) || '-',
  },
  {
    title: 'tables.email',
    dataIndex: 'email',
    width: '15%',
    editable: true,
    inputType: 'text',
  },
  {
    title: 'tables.phone',
    dataIndex: 'phone',
    width: '15%',
    editable: true,
    inputType: 'text',
    align: 'center' as const,
    render: (text: string, record: ANY_OBJECT) => `${record.phoneprefix || ''} ${text || '-'}`,
  },
  {
    title: 'tables.role',
    dataIndex: 'role',
    width: '20%',
    editable: true,
    inputType: 'roleSelect',
    align: 'center' as const,
    render: (text: string, record: ANY_OBJECT) => `${capitalize(record?.role?.role) || '-'}`,
  },
  {
    title: 'tables.birthdate',
    dataIndex: 'birthdate',
    width: '25%',
    editable: true,
    inputType: 'date',
    align: 'center' as const,
    render: (text: string) => Dates.format(Dates.getDate(text), 'D MMMM YYYY') || '-',
  },
];
