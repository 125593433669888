import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import PayAttentionScreen from '@app/components/payAttentionScreen/PayAttention';

const PayAttentionPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <PayAttentionScreen />
    </>
  );
};

export default PayAttentionPage;
