import React from 'react';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import feedback from '@app/assets/images/feedback.svg';
import arrowCircleRight from '@app/assets/images/arrow-circle-right.svg';
import * as S from './feedback.styles';
import TextArea from 'antd/lib/input/TextArea';
import StarRating from '../common/StarRating/StarRating';

const FeedBack: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart gap={10} justifyContent="flex-end" alignItem="center">
          <S.Description fontSize={20} fontWeight={'bold'} marginB={20} marginT={20}>
            {t('common.congratulations')}
          </S.Description>
        </S.BoxPart>
        <S.BoxPart className="topImg">
          <Image width={320} src={feedback} preview={false} alt="security-illustrator" />
        </S.BoxPart>
        <S.BoxPart gap={10} marginTop="28" justifyContent="flex-end" alignItem="center">
          <S.DescriptionTwo fontSize={16} textAlign="center" isBorderDisable={true} paddingX={0}>
            {t('common.dealCompletedDescription')}
          </S.DescriptionTwo>
        </S.BoxPart>
        <S.BoxPart gap={10} marginTop="28" justifyContent="flex-end" alignItem="center">
          <StarRating />
        </S.BoxPart>
        <S.FormItem name="message" rules={[{ required: true, message: 'Enter message' }]}>
          <TextArea placeholder={t('contact.needHelpMessagePlaceholder')} />
        </S.FormItem>
        <S.BoxPart justifyContent="flex-end" className="proceed-button">
          <S.BtnBlue type="primary" htmlType="submit">
            {t('common.sendFeedback')}
            <Image src={arrowCircleRight} preview={false} />
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default FeedBack;
