import React from 'react';
import { Button, Input, Space, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Option } from '@app/components/common/selects/Select/Select';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import verifyIcon from 'assets/icons/verify-vector.svg';
import verifyGrayIcon from 'assets/icons/verify-gray-vector.svg';
import * as S from '../SignUpForm.styles';
import { Fileprops } from '@app/interfaces/interfaces';
import { carManufacturer } from '@app/constants/carManufacturerList';

interface FormValues {
  [key: string]: string | UploadFile | null;
}

export interface SendInvitationsProps {
  enableCamera: (imageType: string) => void;
  formValues: FormValues;
  isLoading: boolean;
  addCaptureImage: (image: File, base64: string) => void;
  setImageSide: (imageSide: string) => void;
  verifyCarId: () => void;
  carIdVerified: boolean;
  validateCarId: (carId: string) => boolean;
}

export const Step3: React.FC<SendInvitationsProps> = ({
  formValues,
  enableCamera,
  isLoading,
  addCaptureImage,
  setImageSide,
  verifyCarId,
  carIdVerified,
  validateCarId,
}) => {
  const { t } = useTranslation();

  const selectOptions = carManufacturer.map((carManufacture, index) => (
    <Option key={index} value={carManufacture.toLocaleLowerCase()}>
      {carManufacture}
    </Option>
  ));

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    method: 'POST',
    accept: 'image/*',
    beforeUpload: (file) => {
      const isImage = file.type === 'image/*';
      return isImage;
    },
    onChange: (info) => {
      fileReaderHandler(info.file);
    },
  };

  const fileReaderHandler = (file: Fileprops) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      addCaptureImage(file as File, event.target?.result as string);
    };
    reader.readAsDataURL(file as Blob);
  };

  return (
    <>
      {' '}
      {isLoading ? (
        <Spinner spinning={isLoading} />
      ) : (
        <S.FormCol>
          <S.FormTitleBlack>{t('signup.stepForm.carInformation')}</S.FormTitleBlack>
          <S.FormDescription>{t('signup.stepForm.extraInfo')}</S.FormDescription>
          <S.FormConatiner>
            <S.FormItem
              name="carManufacture"
              label={t('signup.stepForm.selectCarManufacture')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.CustomeSelect
                filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
                size="middle"
              >
                {selectOptions}
              </S.CustomeSelect>
            </S.FormItem>
            <S.FormItem
              name="carPrice"
              label={t('signup.stepForm.carPrice')}
              rules={[{ required: true, message: t('signup.stepForm.carPriceIsRequired') }]}
            >
              <Input placeholder={t('signup.stepForm.enterPrice')} />
            </S.FormItem>
            <S.TakePhotoWrapper>
              <S.FormItem
                name="carDocImage"
                label={t('signup.stepForm.carDocument')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Space.Compact style={{ width: '100%' }}>
                  <Button type="primary" onClick={() => enableCamera('cardoc')}>
                    {t('common.takephoto')}
                  </Button>
                  <Input placeholder={t('common.carDocument')} disabled value={formValues.carDocImage as string} />
                </Space.Compact>
              </S.FormItem>
              <S.NoteWrapper justifyContent={'flex-end'}>
                <Upload {...props}>
                  <S.UploadFile onClick={() => setImageSide('cardoc')}>{t('common.uploadFile')}</S.UploadFile>
                </Upload>
              </S.NoteWrapper>
            </S.TakePhotoWrapper>
            <S.FormItem
              name="carId"
              label={t('signup.stepForm.carId')}
              rules={[
                { required: true, message: t('signup.stepForm.carPriceIsRequired') },
                { len: 7, message: t('signup.stepForm.carIdLengthError', { length: 7 }) },
              ]}
            >
              <S.CarWrapper>
                <Input placeholder={t('signup.stepForm.carId')} />
                <S.VerifyButton disabled={!validateCarId(formValues.carId as string)} onClick={verifyCarId}>
                  <S.VerifyIcon src={!validateCarId(formValues.carId as string) ? verifyGrayIcon : verifyIcon} />
                  {t('common.verify')}
                </S.VerifyButton>
              </S.CarWrapper>
            </S.FormItem>
            {carIdVerified && (
              <S.VerifySuccess>
                <S.Strong>{t('common.wellDone')}</S.Strong> {t('common.yourCardIsVerified')}
              </S.VerifySuccess>
            )}
          </S.FormConatiner>
        </S.FormCol>
      )}
    </>
  );
};
