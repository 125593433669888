import React from 'react';
import {
  GlobalOutlined,
  PlusSquareOutlined,
  UsergroupAddOutlined,
  ProjectOutlined,
  RocketOutlined,
} from '@ant-design/icons';
export interface SidebarNavigationItem {
  title: string;
  key: string;
  routeKey: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  isModel?: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  // {
  //   title: 'common.dashboard',
  //   key: 'dashboard',
  //   routeKey: 'dashboard',
  //   // TODO use path variable
  //   url: '/',
  //   icon: <DashboardOutlined />,
  // },
  {
    title: 'common.operations',
    key: 'operations',
    routeKey: 'operations',
    icon: <PlusSquareOutlined />,
    children: [
      // {
      //   title: 'common.createUser',
      //   key: 'create-user',
      //   routeKey: 'users',
      //   url: '/users/create-user',
      //   icon: <UserOutlined />,
      // },
      {
        title: 'common.users',
        key: 'list-user',
        routeKey: 'users',
        url: '/users/list-users',
        icon: <UsergroupAddOutlined />,
      },
      {
        title: 'common.translations',
        key: 'translations',
        routeKey: 'translations',
        url: '/translations',
        icon: <GlobalOutlined />,
      },
      {
        title: 'common.statusOfInvitation',
        key: 'invities',
        routeKey: 'invities',
        url: 'invities/list-invities',
        icon: <RocketOutlined />,
      },
    ],
  },
  {
    title: 'common.deals',
    key: 'deals',
    routeKey: 'deals',
    url: '/projects/list-projects',
    icon: <ProjectOutlined />,
  },
];
