import { httpApi } from '@app/api/http.api';
import { Status } from '@app/components/apps/kanban/interfaces';
import { CreateStatusRequest } from '@app/store/slices/statusSlice';

export interface StatusQueryResponse {
  limit: number;
  page: number;
  results: Status[];
  totalPages: number;
  totalResults: number;
}

export const createStatus = async (projectPayload: CreateStatusRequest): Promise<Status> => {
  return httpApi
    .post<Status>(`status/createStatus`, projectPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getAllStatus = async (): Promise<StatusQueryResponse> => {
  return httpApi
    .get<StatusQueryResponse>(`status/getStatus`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
