import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import SomethingWrongScreen from '@app/components/errors/SomethingWrongScreen';

const SomethingWrongPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <SomethingWrongScreen />
    </>
  );
};

export default SomethingWrongPage;
