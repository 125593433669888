import { Dispatch, PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Project,
  createProject,
  getProjectDetails,
  addStatusToProject,
  updateProject,
  getProjectListWithQuery,
  QueryProjectRequest,
  getProjectsWithPagination,
  sendInvitation,
  InviteToProject,
  ProjectCompletedNotificaiton,
  sendNotification,
  deleteProject,
  fetchProjectFiles,
} from '@app/api/project.api';
import type { UploadProps } from 'antd';
import { removeSpaceAndRepalce } from '@app/utils/utils';
import { getImageSignedURL } from './userSlice';
import { FileUploadRequest } from '@app/interfaces/interfaces';
import { uploadFile } from '@app/api/file.api';
import { FilesModel, UserModel } from '@app/domain/UserModel';

export interface CreateProjectRequest {
  title?: string;
  description?: string;
  createdBy?: string;
  dueDate?: string;
  company?: string;
  image?: UploadProps | string;
  status?: string | null;
  attachments?: FilesModel[] | string[];
  carId?: string | null;
  carPrice?: string | null;
  seller?: string[] | UserModel[];
  buyer?: string[] | UserModel[];
  isSellerPaid?: boolean;
  isBuyerPaid?: boolean;
  sellerSignedContractAt?: Date;
  buyerSignedContractAt?: Date;
  sellerBankAccountDetails?: string;
  recipe?: UploadProps | string;
}

export interface UpdateProjectRequest {
  projectDetails: CreateProjectRequest;
  projectId: string;
  companyId?: string;
}

export interface ProjectState {
  projectList: Project[] | null;
  projectDetails: Project | null;
  loading: boolean;
}

export interface AddStatToPRojectRequest {
  projectId: string;
  statusId: string;
}

const initialState: ProjectState = {
  projectList: [],
  projectDetails: null,
  loading: false,
};

export const setProjectList = createAction<PrepareAction<[]>>('projects/setProjectList', (projectList) => {
  return {
    payload: projectList,
  };
});

export const setProject = createAction<PrepareAction<Project>>('projects/projectDetails', (newProject) => {
  return {
    payload: newProject,
  };
});

export const doCreateProject = createAsyncThunk(
  'projects/createProject',
  async (projectPayload: CreateProjectRequest, { dispatch }) => {
    const res = await createProject(projectPayload);
    await setProjectDetails(res, dispatch);
    return res;
  },
);

export const doUpdateProject = createAsyncThunk(
  'projects/updateProject',
  async (projectPayload: UpdateProjectRequest, { dispatch }) =>
    updateProject(projectPayload).then(async (res) => {
      getProjectListWithQuery({ company: projectPayload.companyId }).then((res) => {
        dispatch(setProjectList(res));
      });
      setProjectDetails(res, dispatch);
      return res;
    }),
);

export const doUpdateProjectDetails = createAsyncThunk(
  'projects/updateProject',
  async (projectPayload: UpdateProjectRequest, { dispatch }) =>
    updateProject(projectPayload).then(async (res) => {
      setProjectDetails(res, dispatch);
      return res;
    }),
);

export const getProjectList = createAsyncThunk(
  'projects/getProjects',
  async (projectPayload: QueryProjectRequest, { dispatch }) => {
    const res = await getProjectListWithQuery(projectPayload);
    dispatch(setProjectList(res));
    return res;
  },
);

export const doGetProjectsWithPagination = createAsyncThunk(
  'projects/fetchProjectListWithPagination',
  async (projectPayload: QueryProjectRequest) => {
    const res = await getProjectsWithPagination(projectPayload);
    return res;
  },
);

export const getProjectById = createAsyncThunk('projects/getProjectById', async (projectId: string, { dispatch }) =>
  getProjectDetails(projectId).then(async (res) => {
    await setProjectDetails(res, dispatch);
    return res;
  }),
);

export const doDeleteProject = createAsyncThunk('projects/deleteProject', async (projectId: string) => {
  const res = await deleteProject(projectId);
  return res;
});

export const doAddStatusToProject = createAsyncThunk(
  'projects/addStatusToProject',
  async (addStatusPayload: AddStatToPRojectRequest, { dispatch }) => {
    await addStatusToProject(addStatusPayload).then(async (res) => {
      await setProjectDetails(res, dispatch);
    });
  },
);

export const doUploadProjectPicture = createAsyncThunk(
  'projects/updateProjectPicture',
  async (filePayload: FileUploadRequest) => uploadFile(filePayload),
);

export const doUploadRecipe = createAsyncThunk('projects/updateProjectRecipe', async (filePayload: FileUploadRequest) =>
  uploadFile(filePayload),
);

export const doSendInvitation = createAsyncThunk(
  'projects/sendInvitation',
  async (invitationPayload: InviteToProject) => {
    return await sendInvitation(invitationPayload);
  },
);

export const doSendNotification = createAsyncThunk(
  'projects/sendNotification',
  async (notificationPayload: ProjectCompletedNotificaiton) => {
    return await sendNotification(notificationPayload);
  },
);

export const doFetchFiles = createAsyncThunk('projects/fetchFiles', async (projectId: string) => {
  const res = await fetchProjectFiles(projectId);
  return res;
});

export const setProjectDetails = async (project: Project, dispatch: Dispatch): Promise<Project> => {
  if (project.image) {
    const company = project?.company ? removeSpaceAndRepalce(project?.company?.name, '-') : 'common';
    const imageURL: string | null =
      company &&
      project?.image?.name &&
      (await getImageSignedURL(project?.image?.name, `${company}/${project?.image?.entity}`));
    const attachmentsURL: string[] = [];
    if (project?.attachments) {
      for (const document of project?.attachments) {
        const documentURL: string | null = await getImageSignedURL(document?.name, `${company}/${document?.entity}`);
        if (documentURL) {
          attachmentsURL.push(documentURL);
        }
      }
    }
    if (imageURL) {
      project.image.signedURL = imageURL.toString();
    }

    if (project?.attachments?.length) {
      project.attachments = project.attachments.map((document: FilesModel, index: number) => {
        return {
          ...document,
          signedURL: attachmentsURL[index],
        };
      });
    }
  }
  dispatch(setProject(project));
  return project;
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectById.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getProjectById.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setProjectList, (state, action) => {
      state.projectList = action.payload;
    });
    builder.addCase(setProject, (state, action) => {
      state.projectDetails = action.payload;
    });
  },
});

export default projectSlice.reducer;
