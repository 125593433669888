import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { UserTableRow, QueryTable } from 'api/table.api';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import {
  doUpdateUser,
  fetchUserListWithPagination,
  doDeleteUser,
  getImageSignedURL,
} from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkHTTPStatus, capitalize, getRoutePermissionAccessCode, removeSpaceAndRepalce } from '@app/utils/utils';
import { useTranslation } from 'react-i18next';
import { Table } from 'components/common/Table/Table';
import { Dates } from '@app/constants/Dates';
import * as S from './UsersTable.styles';
import { Button } from 'components/common/buttons/Button/Button';
import { Btn } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { FilterIcon } from 'components/common/icons/FilterIcon';
import { Form, Space, TablePaginationConfig } from 'antd';
import { useLoader } from '@app/hooks/useLoader';
import { EditableCell } from '../editableTable/EditableCell';
import { getRolesList } from '@app/store/slices/roleSlice';
import { Role } from '@app/api/role.api';
import { userColumns } from './Column';
import { PermissionTypes, RoutesMapping } from '@app/constants/enums/permission';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { UserModel } from '@app/domain/UserModel';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

export const UsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [roleList, setRoleList] = useState<Role[]>([]);
  const location = useLocation();
  const [permission, setPermission] = useState(0);
  const user = useAppSelector((state) => state.user.user);
  const userPermission = useAppSelector((state) => state.user.user?.role?.permissions);
  const superUser = process.env.REACT_APP_SUPERADMIN;

  const [initialPagination, setInitialPagination] = useState<QueryTable>({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState<{ data: UserTableRow[]; pagination: QueryTable; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [filteredTableData, setFilteredTableData] = useState<{
    data: UserTableRow[];
    pagination: QueryTable;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [editingKey, setEditingKey] = useState('');
  const [query, setQuery] = useState('');
  const [form] = Form.useForm();
  const { loader, handleLoaderOpen, handleLoaderClose } = useLoader();

  const isEditing = (record: UserTableRow) => record.id === editingKey;

  const edit = (record: Partial<UserTableRow>) => {
    form.setFieldsValue({
      ...record,
      birthdate: record.birthdate ? Dates.getDate(record.birthdate) : Dates.getToday(),
      role: record?.role?.role,
    });
    setEditingKey(record?.id as string);
  };

  const save = async (record: Partial<UserTableRow>) => {
    handleLoaderOpen();
    try {
      const row = (await form.validateFields()) as UserTableRow;
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }
      const updateRecord = newData.find((item) => item.id === record.id);
      const payload = {
        userDetails: {
          name: updateRecord?.name,
          firstname: updateRecord?.firstname,
          lastname: updateRecord?.lastname,
          email: updateRecord?.email,
          gender: updateRecord?.gender,
          birthdate: updateRecord?.birthdate,
          phone: updateRecord?.phone,
          phoneprefix: updateRecord?.phoneprefix,
          address1: updateRecord?.address1,
          address2: updateRecord?.address2,
          zipcode: updateRecord?.zipcode,
          city: updateRecord?.city,
          country: updateRecord?.country,
          role: updateRecord?.role,
        },
        userId: record.id || '',
      };
      dispatch(doUpdateUser(payload))
        .unwrap()
        .then(() => {
          fetchData(initialPagination);
          notificationController.success({
            message: t('alerts.successUpdate'),
          });
          setEditingKey('');
          handleLoaderClose();
        })
        .catch((err) => {
          handleLoaderClose();
          notificationController.error({ message: err.message });
          checkHTTPStatus(Number(err.code), navigate);
        });
    } catch (errInfo) {}
  };

  const deleteUser = async (record: Partial<UserTableRow>) => {
    handleLoaderOpen();
    record &&
      record.id &&
      (await dispatch(doDeleteUser(record.id))
        .unwrap()
        .then(() => {
          fetchData(initialPagination);
          handleLoaderClose();
          notificationController.success({
            message: t('alerts.deleteUserSuccessfully'),
          });
        })
        .catch((err) => {
          handleLoaderClose();
          notificationController.error({ message: err.message });
        }));
  };

  const urlToPromise = async (url: string) => {
    return new Promise(function (resolve) {
      fetch(url).then((r) => resolve(r.blob()));
    });
  };

  const downloadFiles = async (record: Partial<UserModel>) => {
    handleLoaderOpen();

    const fileURLs: {
      name: string;
      url: string;
    }[] = [];
    if (record.idDocuments && record.idDocuments?.length > 0) {
      const company = record?.company ? removeSpaceAndRepalce(record?.company?.name, '-') : 'common';
      for (const document of record?.idDocuments) {
        const documentURL: string | null = await getImageSignedURL(document?.name, `${company}/${document?.entity}`);
        if (documentURL) {
          fileURLs.push({
            name: document?.name,
            url: documentURL,
          });
        }
      }
    }

    if (record.documents && record.documents?.length > 0) {
      const company = record?.company ? removeSpaceAndRepalce(record?.company?.name, '-') : 'common';
      for (const document of record?.documents) {
        const documentURL: string | null = await getImageSignedURL(document?.name, `${company}/${document?.entity}`);
        if (documentURL) {
          fileURLs.push({
            name: document?.name,
            url: documentURL,
          });
        }
      }
    }

    const zip = new JSZip();
    for (const file of fileURLs) {
      await urlToPromise(file.url).then((data: unknown) => {
        zip.file(file.name, data as Blob);
      });
    }
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      FileSaver.saveAs(content, `${record.firstname}-${record.lastname}.zip`);
      handleLoaderClose();
    });
  };

  const cancel = () => {
    setEditingKey('');
  };

  const cancelPermission = () => {
    setEditingKey('');
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const newPagination = {
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
    };
    setInitialPagination(newPagination);
    fetchData(newPagination);
  };

  const columns = [
    ...userColumns?.map((item) => {
      item.title = t(item.title);
      return item;
    }),
    {
      title: t('tables.files'),
      dataIndex: 'files',
      width: '10%',
      editable: true,
      inputType: 'text',
      align: 'center' as const,
      render: (text: string, record: UserModel): ReactElement => {
        return (
          <div>
            <Button
              type="ghost"
              icon={<CloudDownloadOutlined />}
              loading={loader}
              onClick={() => downloadFiles(record)}
            >
              {t('tables.getFiles')}
            </Button>
          </div>
        );
      },
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: UserTableRow) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" loading={loader} onClick={() => save(record)}>
                  {t('common.save')}
                </Button>
                <Popconfirm title={t('tables.cancelInfo')} onConfirm={cancelPermission}>
                  <Button type="ghost">{t('common.cancel')}</Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== ''} onClick={() => edit(record)}>
                  {t('common.edit')}
                </Button>
                {record.id !== superUser && (!user || record.id !== user.id) && (
                  <Popconfirm title={t('common.deleteQuestion')} onConfirm={() => deleteUser(record)}>
                    <Button type="ghost" danger loading={loader}>
                      {t('tables.delete')}
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = () => {
    if (permission === PermissionTypes.READ) {
      const tableActionToRemove = columns.findIndex((column) => column.title === t('tables.actions'));
      if (tableActionToRemove >= 0) {
        columns.splice(tableActionToRemove, 1);
      }
    }

    const mergedCol = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: UserTableRow) => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: capitalize(col.title),
          editing: isEditing(record),
          roleList: roleList,
        }),
      };
    });

    return mergedCol;
  };

  const fetchData = useCallback(
    (pagination: QueryTable) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      const payload = {
        page: pagination.page,
        limit: pagination.limit,
      };
      dispatch(fetchUserListWithPagination(payload))
        .unwrap()
        .then((res) => {
          setTableData((tableData) => ({
            ...tableData,
            data: res.results,
            pagination: {
              page: res.page,
              limit: res.limit,
              total: res.totalResults,
            },
            loading: false,
          }));
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          checkHTTPStatus(Number(err.code), navigate);
        });
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    fetchData(initialPagination);
  }, [fetchData, initialPagination]);

  useEffect(() => {
    if (query) {
      const data = [...tableData.data];
      const filtered = data.filter((item) => {
        return (
          Object.values(item).filter((str) => {
            return str && str.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0;
          }).length > 0
        );
      });
      setFilteredTableData((tableData) => ({
        ...tableData,
        data: filtered,
        pagination: {
          ...tableData.pagination,
          total: filtered.length,
        },
        loading: false,
      }));
    }
  }, [query, tableData.data]);

  const getRoles = useCallback(async () => {
    await dispatch(getRolesList({}))
      .unwrap()
      .then((res) => {
        setRoleList(res);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      });
  }, [dispatch]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    if (userPermission) {
      const checkPermission = getRoutePermissionAccessCode(
        userPermission,
        RoutesMapping,
        location.pathname.split('/')[1],
      );
      setPermission(checkPermission);
    }
  }, [location.pathname, userPermission]);

  return (
    <Form form={form} component={false}>
      <S.TableActionWrapper>
        <S.InputSearch
          placeholder={t('header.search')}
          className="search-input"
          onChange={(event) => setQuery(event.target.value)}
          filter={<Btn size="small" type={'text'} aria-label="Filter" icon={<FilterIcon />} />}
          enterButton={null}
          addonAfter={null}
        />
      </S.TableActionWrapper>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={query ? filteredTableData.data : tableData.data}
        columns={mergedColumns()}
        rowClassName="editable-row"
        pagination={{
          ...(query ? filteredTableData.pagination : tableData.pagination),
          onChange: cancel,
        }}
        onChange={handleTableChange}
        loading={query ? filteredTableData.loading : tableData.loading}
        scroll={{ x: 1300 }}
      />
    </Form>
  );
};
