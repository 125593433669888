import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './errors.styles';
import somethingWrong from 'assets/images/something-wrong.svg';
import ContactIcon from 'assets/images/contact.svg';
import { Image } from 'antd';

const SomethingWrongScreen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart>
          <Image width={375} src={somethingWrong} preview={false} />
        </S.BoxPart>
        <S.BoxPart justifyContent="flex-start" alignItem="flex-start" gap={16}>
          <S.Title>{t('somethingWentWrongError.errorTitle')}</S.Title>
          <S.Description>{t('somethingWentWrongError.errorDescription')}</S.Description>
          <S.SmallDescription>{t('somethingWentWrongError.errorMessage')}</S.SmallDescription>
          <S.BtnBlue type="primary" htmlType="submit">
            <S.BtnBox>
              <img src={ContactIcon} alt="contact" />
              {t('somethingWentWrongError.contactSupport')}
            </S.BtnBox>
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default SomethingWrongScreen;
