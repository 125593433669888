import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ContactInfoScreen from '@app/components/contact/ContactInfoScreen';

const ContactInfoPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <ContactInfoScreen />
    </>
  );
};

export default ContactInfoPage;
