import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '@app/hooks/useDialog';
import { AddProjectModal } from '@app/components/common/Modal/AddProject/AddProjectModal';
import { ReconciliationOutlined } from '@ant-design/icons';
import { useLoader } from '@app/hooks/useLoader';
import { lowerCase } from 'lodash';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { capitalize, checkHTTPStatus } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';
import { doCreateProject, getProjectList } from '@app/store/slices/projectSlice';
import { Project } from '@app/api/project.api';
import * as S from './ProjectMenuOption.styles';
import { fetchUserListWithQuery } from '@app/store/slices/userSlice';
import { UserModel } from '@app/domain/UserModel';
import { RoleTypes } from '@app/constants/enums/roleTypes';

interface ProjectContentProps {
  optionTitle: string;
}

const ProjectMenuOption: React.FC<ProjectContentProps> = ({ optionTitle }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const statusList = useAppSelector((state) => state.user.user?.company?.defaultStatus);
  const { dialogOpen, handleDialogOpen, handleDialogClose } = useDialog();
  const { loader, handleLoaderOpen, handleLoaderClose } = useLoader();
  const [responsibleList, setResponsibleList] = useState<UserModel[]>([]);
  const { t } = useTranslation();

  const onFinish = (values: Project) => {
    handleLoaderOpen();
    if (statusList?.length) {
      const data = {
        title: lowerCase(values.title),
        description: values.description,
        createdBy: user?.id,
        company: user?.company?.id,
        dueDate: values.dueDate,
        responsible: values.responsible,
        status: statusList[0]._id,
      };

      dispatch(doCreateProject(data))
        .unwrap()
        .then(() => {
          dispatch(
            getProjectList({
              company: user?.company?.id,
            }),
          );
          handleLoaderClose();
          handleDialogClose();
          notificationController.success({
            message: t('project.projectSuccessMessage'),
            description: t('project.projectSuccessDescription'),
          });
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          checkHTTPStatus(Number(err.code), navigate);
          handleLoaderClose();
        });
    }
  };

  const getResponsibleList = useCallback(() => {
    const payload = {
      company: user?.company?.id,
    };
    dispatch(fetchUserListWithQuery(payload))
      .unwrap()
      .then((res) => {
        res = res.filter((item) => item?.role?.role !== RoleTypes.BUYER && item?.role?.role !== RoleTypes.SELLER);
        setResponsibleList(res);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      });
  }, [dispatch, setResponsibleList, user?.company?.id]);

  useEffect(() => {
    getResponsibleList();
  }, [getResponsibleList]);

  return (
    <S.ContentWrapper>
      <AddProjectModal
        loading={loader}
        isOpen={dialogOpen}
        onOpenChange={handleDialogClose}
        onFinish={onFinish}
        responsibleList={responsibleList}
      />
      <S.Btn type={'ghost'} className="create-project" onClick={handleDialogOpen}>
        <ReconciliationOutlined /> {`${capitalize(optionTitle)}`}
      </S.Btn>
    </S.ContentWrapper>
  );
};

export default ProjectMenuOption;
