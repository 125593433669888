import React from 'react';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import advertisement from '@app/assets/images/advertisement.svg';
import harel from '@app/assets/images/harel.svg';
import fire from '@app/assets/images/fire-transparent.svg';
import flashFilled from '@app/assets/icons/flash-filled.svg';
import smileFilled from '@app/assets/icons/smile-filled.svg';
import secureFilled from '@app/assets/icons/secure-filled.svg';
import * as S from './advertisement.styles';

const NeedInsurance: React.FC = () => {
  const { t } = useTranslation();

  const offerList = [
    {
      icon: <Image src={flashFilled} preview={false} />,
      title: t('advertisement.offerPointsList.insurancePoint1'),
    },
    {
      icon: <Image src={smileFilled} preview={false} />,
      title: t('advertisement.offerPointsList.insurancePoint2'),
    },
    {
      icon: <Image src={secureFilled} preview={false} />,
      title: t('advertisement.offerPointsList.insurancePoint3'),
    },
  ];

  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.HeaderOption>
          <S.ContainWrapper>
            <Image width={90} src={harel} preview={false} alt="harel" />
          </S.ContainWrapper>
          <S.ContainWrapper>
            <S.Remove />
          </S.ContainWrapper>
        </S.HeaderOption>
        <S.BoxPart className="topImg">
          <Image width={320} src={advertisement} preview={false} alt="security-illustrator" />
        </S.BoxPart>
        <S.BoxPart gap={10} justifyContent="flex-end" alignItem="flex-start">
          <S.Description fontSize={20} fontWeight={'bold'}>
            {t('advertisement.needCarInsurance')}
          </S.Description>
          <S.DescriptionTwo fontSize={16} textAlign="left" isBorderDisable={true} paddingX={0}>
            {t('advertisement.needIsuranceShortDescription')}
          </S.DescriptionTwo>
        </S.BoxPart>
        <S.BoxPart gap={10} marginTop="28" justifyContent="flex-end" alignItem="center">
          <S.OfferListWrapper>
            {offerList.map((item, index) => (
              <S.OfferListItem key={index}>
                {item.icon}
                <S.OfferListItemText>{item.title}</S.OfferListItemText>
              </S.OfferListItem>
            ))}
          </S.OfferListWrapper>
        </S.BoxPart>
        <S.BoxPart justifyContent="flex-end" className="proceed-button">
          <S.BtnBlue type="primary" htmlType="submit">
            <Image src={fire} preview={false} />
            {t('common.getSpecialOfferNow')}
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default NeedInsurance;
