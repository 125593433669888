import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';
import { PermissionData } from '@app/interfaces/interfaces';
import { ClientRequest, QueryRequest, UpdateUserRequest } from '@app/store/slices/userSlice';

export interface User {
  id: number;
  name: string;
  firstname: string;
  lastname: string;
  email: string;
  role: {
    id: string;
    permissions: PermissionData;
    role: string;
    routes: [];
  };
  image: {
    name: string;
    entity: string;
    id: string;
    signedURL?: string;
  };
}

export interface UsersResponse {
  limit: number;
  page: number;
  results: [];
  totalPages: number;
  totalResults: number;
}

export interface UsersRequest {
  name?: string;
  role?: string;
  sortBy?: string;
  projectBy?: string;
  limit?: number;
  page?: number;
  roleId?: string;
}

export interface PermissionResponse {
  id: string;
  permissions: PermissionData;
  role: string;
}

export const createClient = (clientPayload: ClientRequest): Promise<UserModel> => {
  return httpApi
    .post<UserModel>('/users', { ...clientPayload })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const updateClient = (clientPayload: UpdateUserRequest): Promise<undefined> => {
  return httpApi
    .patch<undefined>(`/users/${clientPayload.userId}`, { ...clientPayload.userDetails })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getUserByAuth = (): Promise<UserModel> => {
  return httpApi
    .get<UserModel>(`/users`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getUserListWithPagination = (querPayload: QueryRequest): Promise<UsersResponse> => {
  const { page, limit } = querPayload;

  return httpApi
    .get<UsersResponse>(`/users/queryUsersWithPagination?page=${page}&limit=${limit}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getUserListWithQuery = (querPayload: QueryRequest): Promise<UserModel[]> => {
  return httpApi
    .post<UserModel[]>(`/users/queryUsers`, { ...querPayload })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getUserDetailsById = (requestPayload: { userId: string }): Promise<UserModel> => {
  return httpApi
    .get<UserModel>(`/users/${requestPayload.userId}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const deleteUser = async (userId: string): Promise<UserModel> => {
  return httpApi
    .delete<UserModel>(`users/${userId}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
