interface Country {
  name: string;
  code: string;
  dial_code: string;
}

interface Languages {
  name: string;
  language_code: string;
  antd_helper_file: string;
}

export const countryList: Country[] = [
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
  },
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI',
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR',
  },
  {
    name: 'American Samoa',
    dial_code: '+1684',
    code: 'AS',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW',
  },
  {
    name: 'Åland Islands',
    dial_code: '+358',
    code: 'AX',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ',
  },
  {
    name: 'Saint Barthélemy',
    dial_code: '+590',
    code: 'BL',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM',
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO',
  },
  {
    code: 'BQ',
    dial_code: '+599',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
  },
  {
    code: 'BV',
    dial_code: '+47',
    name: 'Bouvet Island',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC',
  },
  {
    name: 'Congo, Democratic Republic of the',
    dial_code: '+243',
    code: 'CD',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF',
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
  },
  {
    name: "Côte d'Ivoire",
    dial_code: '+225',
    code: 'CI',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU',
  },
  {
    name: 'Cabo Verde',
    dial_code: '+238',
    code: 'CV',
  },
  {
    code: 'CW',
    dial_code: '+599',
    name: 'Curaçao',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY',
  },
  {
    name: 'Czechia',
    dial_code: '+420',
    code: 'CZ',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG',
  },
  {
    code: 'EH',
    dial_code: '+212',
    name: 'Western Sahara',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK',
  },
  {
    name: 'Micronesia, Federated States of',
    dial_code: '+691',
    code: 'FM',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    dial_code: '+44',
    code: 'GB',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'GY',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
  },
  {
    code: 'HM',
    dial_code: '+672',
    name: 'Heard Island and McDonald Islands',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
  },
  {
    name: 'Iran, Islamic Republic of',
    dial_code: '+98',
    code: 'IR',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN',
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: '+850',
    code: 'KP',
  },
  {
    name: 'Korea, Republic of',
    dial_code: '+82',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'KZ',
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: '+856',
    code: 'LA',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV',
  },
  {
    name: 'Libya',
    dial_code: '+218',
    code: 'LY',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC',
  },
  {
    name: 'Moldova, Republic of',
    dial_code: '+373',
    code: 'MD',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
  },
  {
    name: 'Saint Martin, (French part)',
    dial_code: '+590',
    code: 'MF',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
  },
  {
    name: 'North Macedonia',
    dial_code: '+389',
    code: 'MK',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML',
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM',
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN',
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
  },
  {
    name: 'Palestine, State of',
    dial_code: '+970',
    code: 'PS',
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT',
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW',
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA',
  },
  {
    name: 'Réunion',
    dial_code: '+262',
    code: 'RE',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS',
  },
  {
    name: 'Russian Federation',
    dial_code: '+7',
    code: 'RU',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    dial_code: '+290',
    code: 'SH',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS',
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
  },
  {
    code: 'SX',
    dial_code: '+1721',
    name: 'Sint Maarten, (Dutch part)',
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY',
  },
  {
    name: 'Eswatini',
    dial_code: '+268',
    code: 'SZ',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD',
  },
  {
    code: 'TF',
    dial_code: '+262',
    name: 'French Southern Territories',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO',
  },
  {
    name: 'Türkiye',
    dial_code: '+90',
    code: 'TR',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
  },
  {
    name: 'Taiwan, Province of China',
    dial_code: '+886',
    code: 'TW',
  },
  {
    name: 'Tanzania, United Republic of',
    dial_code: '+255',
    code: 'TZ',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG',
  },
  {
    code: 'UM',
    dial_code: '+1',
    name: 'United States Minor Outlying Islands',
  },
  {
    name: 'United States of America',
    dial_code: '+1',
    code: 'US',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
  },
  {
    name: 'Holy See',
    dial_code: '+379',
    code: 'VA',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC',
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    dial_code: '+58',
    code: 'VE',
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'VI',
  },
  {
    name: 'Viet Nam',
    dial_code: '+84',
    code: 'VN',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
  },
];

export const localDialCodeIsrael = [
  {
    name: 'Israel',
    dial_code: '050',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '051',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '052',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '053',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '054',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '055',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '056',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '057',
    code: 'IL',
  },
  {
    name: 'Israel',
    dial_code: '058',
    code: 'IL',
  },
];

export const languageList: Languages[] = [
  {
    name: 'Arabic',
    language_code: 'AR',
    antd_helper_file: 'ar_EG',
  },
  {
    name: 'Azerbaijani',
    language_code: 'AZ',
    antd_helper_file: 'az_AZ',
  },
  {
    name: 'Bulgarian',
    language_code: 'BG',
    antd_helper_file: 'bg_BG',
  },
  {
    name: 'Bangla (Bangladesh)',
    language_code: 'BN',
    antd_helper_file: 'bn_BD',
  },
  {
    name: 'Belarusian',
    language_code: 'BE',
    antd_helper_file: 'by_BY',
  },
  {
    name: 'Catalan',
    language_code: 'CA',
    antd_helper_file: 'ca_ES',
  },
  {
    name: 'Czech',
    language_code: 'CS',
    antd_helper_file: 'cs_CZ',
  },
  {
    name: 'Danish',
    language_code: 'DA',
    antd_helper_file: 'da_DK',
  },
  {
    name: 'German',
    language_code: 'DE',
    antd_helper_file: 'de_DE',
  },
  {
    name: 'Greek',
    language_code: 'EL',
    antd_helper_file: 'el_GR',
  },
  {
    name: 'English (United Kingdom)',
    language_code: 'EN',
    antd_helper_file: 'en_GB',
  },
  {
    name: 'English',
    language_code: 'EN',
    antd_helper_file: 'en_US',
  },
  {
    name: 'Spanish',
    language_code: 'ES',
    antd_helper_file: 'es_ES',
  },
  {
    name: 'Basque',
    language_code: 'EU',
    antd_helper_file: 'eu_ES',
  },
  {
    name: 'Estonian',
    language_code: 'ET',
    antd_helper_file: 'et_EE',
  },
  {
    name: 'Persian',
    language_code: 'FA',
    antd_helper_file: 'fa_IR',
  },
  {
    name: 'Finnish',
    language_code: 'FI',
    antd_helper_file: 'fi_FI',
  },
  {
    name: 'French (Belgium)',
    language_code: 'FR',
    antd_helper_file: 'fr_BE',
  },
  {
    name: 'French (Canada)',
    language_code: 'FR',
    antd_helper_file: 'fr_CA',
  },
  {
    name: 'French (France)',
    language_code: 'FR',
    antd_helper_file: 'fr_FR',
  },
  {
    name: 'Irish (Ireland)',
    language_code: 'GA',
    antd_helper_file: 'ga_IE',
  },
  {
    name: 'Galician (Spain)',
    language_code: 'GL',
    antd_helper_file: 'gl_ES',
  },
  {
    name: 'Hebrew',
    language_code: 'HE',
    antd_helper_file: 'he_IL',
  },
  {
    name: 'Hindi',
    language_code: 'HI',
    antd_helper_file: 'hi_IN',
  },
  {
    name: 'Croatian',
    language_code: 'HR',
    antd_helper_file: 'hr_HR',
  },
  {
    name: 'Hungarian',
    language_code: 'HU',
    antd_helper_file: 'hu_HU',
  },
  {
    name: 'Hungarian',
    language_code: 'HU',
    antd_helper_file: 'hu_HU',
  },
  {
    name: 'Armenian',
    language_code: 'HY',
    antd_helper_file: 'hy_AM',
  },
  {
    name: 'Indonesian',
    language_code: 'ID',
    antd_helper_file: 'id_ID',
  },
  {
    name: 'Italian',
    language_code: 'IT',
    antd_helper_file: 'it_IT',
  },
  {
    name: 'Icelandic',
    language_code: 'IS',
    antd_helper_file: 'is_IS',
  },
  {
    name: 'Japanese',
    language_code: 'JA',
    antd_helper_file: 'ja_JP',
  },
  {
    name: 'Georgian',
    language_code: 'KA',
    antd_helper_file: 'ka_GE',
  },
  {
    name: 'Kurdish (Kurmanji)',
    language_code: 'KU',
    antd_helper_file: 'kmr_IQ',
  },
  {
    name: 'Kannada',
    language_code: 'KN',
    antd_helper_file: 'kn_IN',
  },
  {
    name: 'Kazakh',
    language_code: 'KK',
    antd_helper_file: 'kk_KZ',
  },
  {
    name: 'Khmer',
    language_code: 'KM',
    antd_helper_file: 'km_KH',
  },
  {
    name: 'Korean',
    language_code: 'KO',
    antd_helper_file: 'ko_KR',
  },
  {
    name: 'Lithuanian',
    language_code: 'LT',
    antd_helper_file: 'lt_LT',
  },
  {
    name: 'Latvian',
    language_code: 'LV',
    antd_helper_file: 'lv_LV',
  },
  {
    name: 'Macedonian',
    language_code: 'MK',
    antd_helper_file: 'mk_MK',
  },
  {
    name: 'Malayalam (India)',
    language_code: 'ML',
    antd_helper_file: 'ml_IN',
  },
  {
    name: 'Mongolian',
    language_code: 'MN',
    antd_helper_file: 'mn_MN',
  },
  {
    name: 'Malay (Malaysia)',
    language_code: 'MS',
    antd_helper_file: 'ms_MY',
  },
  {
    name: 'Norwegian',
    language_code: 'NB',
    antd_helper_file: 'nb_NO',
  },
  {
    name: 'Nepal',
    language_code: 'NE',
    antd_helper_file: 'ne_NP',
  },
  {
    name: 'Dutch (Belgium)',
    language_code: 'NL',
    antd_helper_file: 'nl_BE',
  },
  {
    name: 'Dutch',
    language_code: 'NL',
    antd_helper_file: 'nl_NL',
  },
  {
    name: 'Polish',
    language_code: 'PL',
    antd_helper_file: 'pl_PL',
  },
  {
    name: 'Portuguese (Brazil)',
    language_code: 'PT',
    antd_helper_file: 'pt_BR',
  },
  {
    name: 'Portuguese',
    language_code: 'PT',
    antd_helper_file: 'pt_PT',
  },
  {
    name: 'Romanian',
    language_code: 'RO',
    antd_helper_file: 'ro_RO',
  },
  {
    name: 'Russian',
    language_code: 'RU',
    antd_helper_file: 'ru_RU',
  },
  {
    name: 'Sinhalese / Sinhala',
    language_code: 'SI',
    antd_helper_file: 'si_LK',
  },
  {
    name: 'Slovak',
    language_code: 'SK',
    antd_helper_file: 'sk_SK',
  },
  {
    name: 'Serbian',
    language_code: 'SR',
    antd_helper_file: 'sr_RS',
  },
  {
    name: 'Slovenian',
    language_code: 'SL',
    antd_helper_file: 'sl_SI',
  },
  {
    name: 'Swedish',
    language_code: 'SV',
    antd_helper_file: 'sv_SE',
  },
  {
    name: 'Tamil',
    language_code: 'TA',
    antd_helper_file: 'ta_IN',
  },
  {
    name: 'Thai',
    language_code: 'TH',
    antd_helper_file: 'th_TH',
  },
  {
    name: 'Turkish',
    language_code: 'TR',
    antd_helper_file: 'tr_TR',
  },
  {
    name: 'Turkmen',
    language_code: 'TK',
    antd_helper_file: 'tk_TK',
  },
  {
    name: 'Urdu (Pakistan)',
    language_code: 'UR',
    antd_helper_file: 'ur_PK',
  },
  {
    name: 'Ukrainian',
    language_code: 'UK',
    antd_helper_file: 'uk_UA',
  },
  {
    name: 'Vietnamese',
    language_code: 'VI',
    antd_helper_file: 'vi_VN',
  },
  {
    name: 'Chinese',
    language_code: 'ZH',
    antd_helper_file: 'zh_CN',
  },
  {
    name: 'Burmese',
    language_code: 'MY',
    antd_helper_file: 'my_MM',
  },
];

export const bankList = [
  {
    name: 'Bank Hapoalim',
    code: '12',
  },
  {
    name: 'Bank Leumi',
    code: '10',
  },
  {
    name: 'Bank Discount',
    code: '11',
  },
];
