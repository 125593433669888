import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import NeedInsurance from '@app/components/advertisement/needInsurance';

const NeedInsurancePage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <NeedInsurance />
    </>
  );
};

export default NeedInsurancePage;
