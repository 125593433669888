import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstname?: string;
  lastname?: string;
  name?: string;
  email?: string;
  password?: string;
  phone?: string;
  role?: string | null;
  company?: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface ResetPasswordRequest {
  password: string;
  token: string;
}
export interface SecurityCodePayload {
  otp: string;
  email?: string;
  phone?: number | string;
  phoneprefix?: number | string;
  requestOTPFrom: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email?: string;
  password?: string;
}

export interface UserResponse {
  tokens: {
    access: {
      expires: Date;
      token: string;
    };
  };
  user: UserModel;
}

export interface RequestOTPRequest {
  email?: string;
  phone?: string;
  phoneprefix?: string;
  type: string;
}

export interface RequestOTPResponse {
  otp?: number;
}

export interface GenerateNewTokenRequest {
  userId: string;
}

export const login = (loginPayload: LoginRequest): Promise<UserResponse> =>
  httpApi
    .post<UserResponse>('auth/login', { ...loginPayload })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });

export const signUp = (signUpData: SignUpRequest): Promise<UserResponse> =>
  httpApi
    .post<UserResponse>('auth/register', { ...signUpData })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi
    .post<undefined>(`auth/reset-password?token=${resetPasswordPayload.token}`, {
      password: resetPasswordPayload.password,
    })
    .then(({ data }) => data);

export const forgotPassword = (forgotPasswordPayload: ForgotPasswordRequest): Promise<undefined> =>
  httpApi
    .post<undefined>('auth/forgot-password', { ...forgotPasswordPayload })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<UserResponse> =>
  httpApi
    .post<UserResponse>('auth/verify-otp', {
      otp: securityCodePayload.otp,
      phone: securityCodePayload.phone,
      phoneprefix: securityCodePayload.phoneprefix,
    })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const requestOTP = (requestOTPPayload: RequestOTPRequest): Promise<RequestOTPResponse> => {
  return httpApi
    .post<RequestOTPResponse>('auth/request-otp', { ...requestOTPPayload })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const generateNewToken = (payload: GenerateNewTokenRequest): Promise<UserResponse> => {
  const { userId } = payload;
  return httpApi
    .get<UserResponse>(`auth/generate-new-token/${userId}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
