import styled from 'styled-components';
import { Image } from 'antd';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const WebcamWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 2rem 1rem;

  img.ant-image-preview-img {
    display: inline-block !important;
  }
`;

export const CameraActionButtons = styled.div`
  display: flex;
  justify-content: center;

  Button {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;s
  }
`;

export const PreviewImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
`;
