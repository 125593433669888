import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import * as S from './LoginWithOTPForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { checkOnlyNumber } from '@app/utils/utils';
import { SendOtpHandler } from '@app/controllers/sendOtpController';
import { LoginTypes } from '@app/constants/enums/loginType';
import LanguageDropdown from '../../common/LanguageDropdown/LanguageDropdown';
import { defaultCountryDialCode } from '@app/constants/variables';
import { Option } from '@app/components/common/selects/Select/Select';
import { localDialCodeIsrael } from '@app/constants/countryList';

export const initValues: LoginWithOTPFormData = {
  phoneprefix: defaultCountryDialCode,
  phone: '',
  email: '',
};
interface LoginWithOTPFormData {
  phoneprefix?: string;
  phone?: string;
  email?: string;
}

interface UserDataInterFace {
  renderForm?: boolean;
  loginData: LoginWithOTPFormData;
}

export const LoginWithOTPForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [loginLoader, setLoginLoader] = useState(false);

  const setLoadingEnable = () => {
    return setLoginLoader(true);
  };

  const setLoadingDisable = () => {
    return setLoginLoader(false);
  };

  const [userData, setUserData] = useState<UserDataInterFace>({
    renderForm: false,
    loginData: initValues,
  });

  useEffect(() => {
    setUserData((prevState) => ({ ...prevState, ...initValues, renderForm: true }));
  }, []);

  useEffect(() => {
    if (state?.phone || state?.email) {
      setUserData((prevState) => ({
        ...prevState,
        loginData: { ...prevState.loginData, phone: state?.phone, email: state?.email },
      }));
    }
  }, [state]);

  const handleSubmit = (values: LoginWithOTPFormData) => {
    SendOtpHandler(values, dispatch, navigate, t, setLoadingEnable, setLoadingDisable, 'login');
  };

  if (!userData.renderForm) return <></>;

  return (
    <Auth.FormWrapper>
      <Auth.Overlay></Auth.Overlay>
      <Auth.OverlayData>
        <S.FlexBox>
          <S.BaseFormBox
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={userData.loginData}
            style={{
              height: '100%',
            }}
          >
            <Auth.BoxPart gap={0}>
              <Auth.FixBoxSize>
                <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
                {process.env.REACT_APP_LOGIN_WITH === LoginTypes.EMAIL ? (
                  <S.LoginDescription>{t('login.loginWitOTPViaEmailInfo')}</S.LoginDescription>
                ) : (
                  <S.LoginDescription>{t('login.loginWitOTPViaPhoneInfo')}</S.LoginDescription>
                )}
              </Auth.FixBoxSize>
            </Auth.BoxPart>
            <Auth.BoxPart gap={0}>
              <Auth.FixBoxSize>
                {process.env.REACT_APP_LOGIN_WITH === LoginTypes.EMAIL ? (
                  <Auth.FormItem
                    name="email"
                    label={t('common.email')}
                    rules={[
                      { required: true, message: t('common.requiredField') },
                      {
                        type: 'email',
                        message: t('common.notValidEmail'),
                      },
                    ]}
                  >
                    <Auth.FormInput placeholder={t('common.email')} />
                  </Auth.FormItem>
                ) : (
                  <S.PhoneItem
                    name="phone"
                    label={t('common.phone')}
                    rules={[
                      { required: true, message: t('common.requiredField') },
                      { pattern: new RegExp(checkOnlyNumber), message: t('common.shouldBeANumber') },
                      { len: 7, message: t('common.phoneNumberLengthError', { length: 7 }) },
                    ]}
                  >
                    <S.FormInput
                      addonBefore={
                        <BaseForm.Item name="phoneprefix" noStyle>
                          <S.Select defaultValue={defaultCountryDialCode}>
                            {localDialCodeIsrael?.map((item, index) => {
                              return (
                                <Option key={`${item.name}-${index}`} value={item.dial_code}>
                                  <S.CountriesSpace align="center">{`${item.dial_code}`}</S.CountriesSpace>
                                </Option>
                              );
                            })}
                          </S.Select>
                        </BaseForm.Item>
                      }
                      placeholder={t('common.phone')}
                    />
                  </S.PhoneItem>
                )}
              </Auth.FixBoxSize>
            </Auth.BoxPart>
            <Auth.BoxPart gap={0}>
              <Auth.FixBoxSize>
                <BaseForm.Item noStyle>
                  <Auth.SubmitButton type="primary" htmlType="submit" loading={loginLoader}>
                    {t('common.requestOTP')}
                  </Auth.SubmitButton>
                </BaseForm.Item>
                <Auth.FooterWrapper>
                  <Auth.Text>
                    {t('login.noAccount')}{' '}
                    <Link to="/auth/sign-up">
                      <Auth.LinkText>{t('login.createOneHere')}</Auth.LinkText>
                    </Link>
                  </Auth.Text>
                </Auth.FooterWrapper>
              </Auth.FixBoxSize>
            </Auth.BoxPart>
          </S.BaseFormBox>
          <LanguageDropdown />
        </S.FlexBox>
      </Auth.OverlayData>
    </Auth.FormWrapper>
  );
};
