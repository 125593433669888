import CryptoJS from 'crypto-js';
import { deleteRememberMeToken, setRememberMeToken } from '@app/services/localStorage.service';
import { ANY_OBJECT, DecryptUserData, EncryptionData } from '@app/interfaces/interfaces';

// Encryption of Login Data
export const cryptoEncrpytHandler = (data: EncryptionData): string => {
  const cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    `${process.env.REACT_APP_REMEMBERME_SECRET_KEY}`,
  ).toString();

  const { rememberMe } = data;

  if (rememberMe === true) {
    setRememberMeToken(cipherText);
  } else {
    deleteRememberMeToken('rememberMe');
  }

  return cipherText;
};

// Decryption of Login Data
export const cryptoDecryptHandler = (str: string): DecryptUserData => {
  const bytes = CryptoJS.AES.decrypt(str, `${process.env.REACT_APP_REMEMBERME_SECRET_KEY}`);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

// Encryption of any data
export const cryptoEncrypt = (data: ANY_OBJECT): string => {
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_SECRET_KEY}`).toString();

  return cipherText;
};

// Decryption of any data
export const cryptoDecrypt = (str: string): ANY_OBJECT => {
  const bytes = CryptoJS.AES.decrypt(str, `${process.env.REACT_APP_SECRET_KEY}`);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
