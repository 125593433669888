import styled from 'styled-components';
import { Radio } from 'antd';

export const SettingsOverlayMenu = styled.div`
  width: 13rem;

  .ant-select-selector {
    background-color: transparent !important;
    color: var(--primary-color) !important;
  }

  .ant-select-arrow,
  .ant-select,
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--primary-color) !important;
  }
`;

export const RadioBtn = styled(Radio)`
  font-size: 0.875rem;
`;

export const PwaInstallWrapper = styled.div`
  padding: 0 1rem 0.75rem;
`;
