import { getAllService } from '@app/api/service.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceList: [],
};

export const doGetAllService = createAsyncThunk('status/getAllStatus', async () =>
  getAllService().then((res) => {
    return res.results;
  }),
);

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
});

export default serviceSlice.reducer;
