import styled from 'styled-components';
import { Button } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperTop = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  padding: 16px;
  background: #4b5563;
  position: absolute;
  top: 0;
  z-index: 999;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const BoxContainer = styled.div`
  width: 100%;
  max-width: 720px;
  background: white;
  display: flex;
  padding: 12px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  gap: 16px;
`;

export const Description = styled.div`
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;

  span {
    text-decoration: underline;
  }
`;

export const BtnBlue = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px 20px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  max-width: 360px;

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;
