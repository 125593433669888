import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import ReactCountryFlag from 'react-country-flag';
import { getData } from 'country-list';
import * as I from '../../profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem.styles';
import * as P from '../../profile/profileCard/profileFormNav/nav/PersonalInfo/BirthdayItem/BirthdayItem.styles';
import { Input, InputNumber, Form } from 'antd';
import { genders } from '@app/constants/genders';
import { ANY_OBJECT } from '@app/interfaces/interfaces';
import { Role } from '@app/api/role.api';
import { capitalize } from '@app/utils/utils';
import { Switch } from '@app/components/common/Switch/Switch';
import { Status } from '@app/components/apps/kanban/interfaces';
import { Badge } from '@app/components/common/Badge/Badge';
import { UserModel } from '@app/domain/UserModel';
import * as S from './CellInputNode.styles';
import StubAvatar from '@app/assets/images/stub-avatar.webp';
import { ExtraService } from '@app/components/payment/PaymentFees';

interface CellInputProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string;
  inputType: string;
  record: ANY_OBJECT;
  required: boolean;
  title: string;
  roleList: Role[];
  statusList: Status[];
  responsibleList: UserModel[];
  buyerList: UserModel[];
  sellerList: UserModel[];
  serviceList: ExtraService[];
}

const CellInputNode: React.FC<CellInputProps> = ({
  inputType,
  dataIndex,
  title,
  required,
  roleList,
  record,
  statusList,
  responsibleList,
  buyerList,
  sellerList,
  serviceList,
}) => {
  const { t } = useTranslation();
  const countries = getData();

  const selectResponsibleOptions = useMemo(
    () =>
      responsibleList?.map((participant) => (
        <Option key={participant.id} value={participant.id} name={participant.firstname}>
          <S.ParticipantRow>
            <S.ParticipantAvatar src={participant?.image ? participant.image.signedURL : StubAvatar} />
            <S.ParticipantName>{`${capitalize(participant.firstname)} ${capitalize(
              participant.lastname,
            )}`}</S.ParticipantName>
          </S.ParticipantRow>
        </Option>
      )),
    [responsibleList],
  );

  const selectBuyerOptions = useMemo(
    () =>
      buyerList?.map((participant) => (
        <Option key={participant.id} value={participant.id} name={participant.firstname}>
          <S.ParticipantRow>
            <S.ParticipantAvatar src={participant?.image ? participant.image.signedURL : StubAvatar} />
            <S.ParticipantName>{`${capitalize(participant.firstname)} ${capitalize(
              participant.lastname,
            )}`}</S.ParticipantName>
          </S.ParticipantRow>
        </Option>
      )),
    [buyerList],
  );

  const selectSellerOptions = useMemo(
    () =>
      sellerList?.map((participant) => (
        <Option key={participant.id} value={participant.id} name={participant.firstname}>
          <S.ParticipantRow>
            <S.ParticipantAvatar src={participant?.image ? participant.image.signedURL : StubAvatar} />
            <S.ParticipantName>{`${capitalize(participant.firstname)} ${capitalize(
              participant.lastname,
            )}`}</S.ParticipantName>
          </S.ParticipantRow>
        </Option>
      )),
    [sellerList],
  );

  const selectOptions = useMemo(
    () =>
      countries.map((country) => (
        <Option key={country.name} value={country.name}>
          <I.CountriesSpace align="center">
            <ReactCountryFlag countryCode={country.code} svg alt="country flag" />
            {capitalize(country.name)}
          </I.CountriesSpace>
        </Option>
      )),
    [countries],
  );

  const roleOptions = useMemo(
    () =>
      roleList &&
      roleList.map((roleData: Role) => (
        <Option key={roleData.id} value={roleData.role}>
          {capitalize(roleData.role)}
        </Option>
      )),
    [roleList],
  );

  const InputNode = (inputType: string) => {
    switch (inputType) {
      case 'text':
        return <Input />;
      case 'number':
        return <InputNumber />;
      case 'genderSelect':
        return <Select placeholder={t('forms.stepFormLabels.gender')} options={genders}></Select>;
      case 'countrySelect':
        return (
          <Select
            showSearch
            filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
          >
            {selectOptions}
          </Select>
        );
      case 'date':
        return <P.BirthdayPicker format="YYYY-MM-DD" />;
      case 'roleSelect':
        return <Select placeholder={t('forms.stepFormLabels.role')}>{roleOptions}</Select>;
      case 'checkbox':
        return <Switch defaultChecked={record[dataIndex]} />;
      case 'statusSelect':
        return (
          <Select placeholder={t('forms.stepFormLabels.status')}>
            {statusList.map((status: Status) => (
              <Option key={status._id} value={status._id}>
                <Badge color={status?.color} text={capitalize(status?.title)} />
              </Option>
            ))}
          </Select>
        );
      case 'responsibleSelect':
        return (
          <Select placeholder={t('forms.stepFormLabels.users')} showSearch>
            {selectResponsibleOptions}
          </Select>
        );
      case 'buyerSelect':
        return (
          <Select placeholder={t('forms.placehoders.selectBuyer')} showSearch>
            {selectBuyerOptions}
          </Select>
        );
      case 'sellerSelect':
        return (
          <Select placeholder={t('forms.placehoders.selectSeller')} showSearch>
            {selectSellerOptions}
          </Select>
        );
      case 'inititateSwitch':
        const signedServices = serviceList ? serviceList.filter((el) => el.sign).map((el) => el.id) : [];
        const seller = record?.seller && record?.seller?.length > 0 ? record?.seller[0] : null;
        const buyer = record?.buyer && record?.buyer?.length > 0 ? record?.buyer[0] : null;
        const sellerSigned =
          seller?.services && seller?.services?.length > 0
            ? seller?.services.filter((el: string) => signedServices.includes(el)).length > 0
            : false;
        const buyerSigned =
          buyer?.services && buyer?.services?.length > 0
            ? buyer?.services.filter((el: string) => signedServices.includes(el)).length > 0
            : false;
        return (
          <Switch
            defaultChecked={record[dataIndex]}
            disabled={
              record[dataIndex] ||
              (!record.isBothSignedContract &&
                sellerSigned &&
                buyerSigned &&
                buyer &&
                buyer?.services &&
                buyer?.services?.length > 0 &&
                seller &&
                seller?.services &&
                seller?.services?.length > 0)
            }
          />
        );
      default:
        return <Input />;
    }
  };

  return (
    <>
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required,
            message: `Please Input ${title}!`,
          },
        ]}
      >
        {InputNode(inputType)}
      </Form.Item>
    </>
  );
};

export default CellInputNode;
