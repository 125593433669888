import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo.png';
import { useAppSelector } from '@app/hooks/reduxHooks';
import editIcon from '@app/assets/icons/edit.svg';
import { Image, Avatar } from 'antd';
import { useParams } from 'react-router-dom';
import { PermissionData } from '@app/interfaces/interfaces';
import { PermissionTypes } from '@app/constants/enums/permission';
import { useTranslation } from 'react-i18next';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
  permissions: PermissionData | undefined;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider, permissions }) => {
  const { t } = useTranslation();
  const { tabletOnly } = useResponsive();
  const params = useParams();
  const user = useAppSelector((state) => state.user.user);
  const companyName = user?.company?.name || 'Dynomind.tech';
  const { companyId } = params as { companyId: string };

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to={user?.company?.id ? `${'companies'}/${user?.company?.id}` : '/'}>
        <Avatar
          shape="circle"
          src={user?.company?.image && user?.company?.image?.signedURL ? user?.company?.image?.signedURL : logo}
          alt="Company"
          size={48}
        />
        {user?.company?.id && !companyId && permissions?.companies !== PermissionTypes.NOTHING && (
          <Image src={editIcon} alt="Edit" preview={false} />
        )}
        <S.BrandSpan about={`${companyName}`}>{`${companyName} - ${t('common.superAdmin')}`}</S.BrandSpan>
      </S.SiderLogoLink>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
