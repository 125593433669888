import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpScreen.styles';
import LanguageDropdown from '../../common/LanguageDropdown/LanguageDropdown';
import { Image } from 'antd';
import logo from 'assets/titleCarlogo.svg';
import smileVectorIcon from 'assets/icons/smile-vector-icon.svg';
import sellCoinVectorIcon from 'assets/icons/sell-coin-vector-icon.svg';
import { removeFromLocalStorage } from '@app/services/localStorage.service';
import { LocalStorageKeys } from '@app/constants/enums/localStorageKeys';

export const SignUpScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToAuthBuyer = () => {
    removeFromLocalStorage(LocalStorageKeys.USERSTATE);
    navigate('/sign-up/authorization/buyer');
  };

  const navigateToAuthSeller = () => {
    removeFromLocalStorage(LocalStorageKeys.USERSTATE);
    navigate('/sign-up/authorization/seller');
  };

  return (
    <Auth.FormWrapper center={true}>
      <Auth.Overlay></Auth.Overlay>
      <Auth.OverlayData>
        <S.BoxMainWrapper>
          <S.FlexBox>
            <S.BoxPart gap={0}>
              <S.ImageWrapper>
                <Image src={logo} alt="App logo" preview={false} />
              </S.ImageWrapper>
            </S.BoxPart>
            <S.BoxPart gap={0}>
              <S.SubTitle>{t('signup.subtitle')}</S.SubTitle>
            </S.BoxPart>
            <S.BoxPart gap={0}>
              <S.BtnBuyBlue size="middle" onClick={navigateToAuthBuyer}>
                <S.BtnBox>
                  <S.FlexBtn>
                    <Image width={18} src={smileVectorIcon} alt="smile-vector-icon" preview={false} />
                    {t('signup.buyCarButton')}
                  </S.FlexBtn>
                </S.BtnBox>
              </S.BtnBuyBlue>
              <S.BtnSellWhite size="middle" onClick={navigateToAuthSeller}>
                <S.BtnBox>
                  <S.FlexBtn>
                    <Image width={18} src={sellCoinVectorIcon} alt="sell-vector-icon" preview={false} />
                    {t('signup.sellCarButton')}
                  </S.FlexBtn>
                </S.BtnBox>
              </S.BtnSellWhite>
              <Auth.FooterWrapper>
                <Auth.Text>
                  {t('signup.alreadyHaveAccount')}{' '}
                  <Link to="/auth/login">
                    <Auth.LinkText>{t('common.here')}</Auth.LinkText>
                  </Link>
                </Auth.Text>
              </Auth.FooterWrapper>
              <LanguageDropdown />
            </S.BoxPart>
          </S.FlexBox>
        </S.BoxMainWrapper>
      </Auth.OverlayData>
    </Auth.FormWrapper>
  );
};
