export const PermissionTypes = {
  NOTHING: 0,
  READ: 1,
  READWRITE: 2,
};

export const PermissionComponents = {
  NOTIFICATION: 'notifications',
  USER: 'users',
  SETTING: 'settings',
  TRANSLATION: 'translations',
  CREATEUSER: 'creatusers',
  CREATEPROJECT: 'createProjects',
};

export const RoutesMapping = {
  users: 'users',
  translations: 'translations',
  notifications: 'notifications',
  settings: 'security-settings',
  projects: 'projects',
  roles: 'roles',
  createproject: 'project-create',
  projectdetails: 'project-details',
  profile: 'profile',
  switchusers: 'switch-users',
  companies: 'companies',
  listcompanies: 'list-companies',
  listprojects: 'list-projects',
  kanban: 'kanban',
  listInvities: 'list-invities',
  invities: 'invities',
};
