import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './errors.styles';
import error404 from 'assets/images/404.svg';
import { Image } from 'antd';

const NotFound404Screen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart>
          <Image width={375} src={error404} preview={false} />
        </S.BoxPart>
        <S.BoxPart justifyContent="flex-start" alignItem="flex-start" gap={16}>
          <S.Title>{t('error404.404')}</S.Title>
          <S.Description>{t('error404.somethingMissingTitle')}</S.Description>
          <S.SmallDescription>{t('error404.somethingMissingDescription')}</S.SmallDescription>
          <S.BtnBlue type="primary" htmlType="submit">
            <S.BtnBox>{t('common.backToHome')}</S.BtnBox>
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default NotFound404Screen;
