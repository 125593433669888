import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const ParticipantRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ParticipantAvatar = styled.img`
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
`;

export const ParticipantName = styled.div`
  font-size: ${FONT_SIZE.xs};
  color: var(--text-main-color);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;
