import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import trasnsferredReceiptSuccess from 'assets/images/transferred-receipt-success.svg';
import downloadPDFFile from 'assets/images/download-pdf-file-icon.svg';
import circleWithRightArrow from 'assets/images/circle-with-right-arrow.svg';

import * as S from './MoneyTransferred.styles';

interface MoneyTransferredProps {
  onClick?: () => void;
}

const MoneyTransferredRecipe: React.FC<MoneyTransferredProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart className="topImg">
          <Image width={320} src={trasnsferredReceiptSuccess} preview={false} alt="security-illustrator" />
        </S.BoxPart>
        <S.BoxPart gap={10} justifyContent="flex-end" alignItem="center">
          <S.Description fontSize={24} textAlign="center" fontWeight={'bold'}>
            {t('moneyTransferred.moneyTransfered')}
          </S.Description>
          <S.BtnPDFDownload type="primary" htmlType="submit">
            <S.DownloadPDF src={downloadPDFFile} />
            {t('contract.downloadSignedDocPDF')}
          </S.BtnPDFDownload>
          <S.DescriptionTwo fontSize={16} textAlign="center" isBorderDisable={true} paddingX={0}>
            {t('moneyTransferred.someAdditionalText')}
          </S.DescriptionTwo>
        </S.BoxPart>
        <S.BoxPart justifyContent="flex-end" className="proceed-button">
          <S.BtnBlue type="primary" htmlType="submit" onClick={onClick}>
            <Image src={circleWithRightArrow} preview={false} />
            {t('common.sendFeedback')}
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default MoneyTransferredRecipe;
