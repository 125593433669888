import styled from 'styled-components';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { media } from '@app/styles/themes/constants';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
}

export const Title = styled(FormTitle)`
  margin-bottom: 1.875rem;
`;

export const SubTitle = styled.p`
  color: var(--text-secondary-color);
  font-weight: 600;
  text-align: center;
  margin: 0;
  font-size: 24px;
  line-height: 36px;
  padding: 48px 40px 72px 40px;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 0;
  width: 100%;

  .ant-image {
    display: block;
  }

  @media only screen and ${media.xs} {
    img {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export const BtnSellWhite = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid white;
  background: white;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 6px 0;

  :active,
  :focus,
  :hover {
    background: white !important;
    border: 2px solid white !important;
    color: var(--primary-color) !important;
  }
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 14px;
`;

export const FlexBtn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  @media only screen and ${media.sm} {
    gap: 20px;
    img {
      width: 20px;
    }
  }
`;

export const BtnBuyBlue = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 0px;
  background: var(--primary-color);
  color: white;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  margin: 6px 0;

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid white !important;
    color: white !important;
  }

  @media only screen and ${media.sm} {
    font-size: 16px;
  }
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};
  align-items: center;

  @media only screen and ${media.xs} {
    gap: ${(props) => {
      const gap = props.gap?.toString();
      return `${gap ? gap : 10}px`;
    }};
    color: red;
  }
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
export const BoxMainWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 360px;
`;
