import React from 'react';
import * as S from './contact.styles';
import needHelp from 'assets/images/need-help.svg';
import { Checkbox, Image, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const NeedHelpScreen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart justifyContent="flex-start" alignItem="center" gap={20}>
          <Image width={300} src={needHelp} preview={false} />
          <S.BaseFormBox name="Form">
            <S.BoxPart justifyContent="flex-start">
              <S.Title fontSize="30px" fontWeight="800">
                {t('contact.needHelp')}
              </S.Title>
              <S.FormCol>
                <S.FormConatiner>
                  <S.FormItem
                    name="email"
                    label={t('contact.needHelpEmailLabel')}
                    rules={[
                      {
                        required: true,
                        message: t('forms.stepFormLabels.loginError'),
                      },
                      {
                        type: 'email',
                        message: t('common.notValidEmail'),
                      },
                    ]}
                  >
                    <Input placeholder={t('inputs.enterYourEmail')} />
                  </S.FormItem>
                  <S.FormItem
                    name="topic"
                    label={t('contact.needHelpTopicLabel')}
                    rules={[{ required: true, message: t('forms.stepFormLabels.firstNameError') }]}
                  >
                    <Input placeholder={t('contact.needHelpTopicLabel')} />
                  </S.FormItem>
                  <S.FormItem
                    name="subject"
                    label={t('contact.needHelpSubjectLabel')}
                    rules={[
                      {
                        required: true,
                        message: t('forms.stepFormLabels.loginError'),
                      },
                    ]}
                  >
                    <Input placeholder={t('contact.needHelpSubjectPlaceholder')} />
                  </S.FormItem>
                  <S.FormItem
                    name="message"
                    label={t('contact.needHelpMessageLabel')}
                    rules={[{ required: true, message: 'Enter message' }]}
                  >
                    <S.TextAreaBox placeholder={t('contact.needHelpMessagePlaceholder')} />
                  </S.FormItem>
                  <S.FormItem name="policy" valuePropName="checked">
                    <Checkbox>
                      <S.CheckBoxText>
                        {t('contact.needHelpCheckboxText')}
                        <span>{t('contact.flobitsTermsOfServiceText')}</span> {t('contact.andText')}
                        <span>{t('contact.privacyStatement')}</span>
                      </S.CheckBoxText>
                    </Checkbox>
                  </S.FormItem>
                </S.FormConatiner>
              </S.FormCol>
              <S.BtnBlue type="primary" htmlType="submit" width="100%">
                {t('contact.sendMessage')}
              </S.BtnBlue>
            </S.BoxPart>
          </S.BaseFormBox>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default NeedHelpScreen;
