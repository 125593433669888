import { LeftOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  .ant-form {
    color: var(--text-primary-color);
  }
`;

export const VerifyDescription = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.lg};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xl};
  }
`;

export const LinkText = styled.div`
  text-align: left;
  margin: 0;
  padding: 12px 20px;
  background: #f9fafb;
  border-radius: 8px;
  color: var(--text-light-primary-color);
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
  cursor: pointer;
`;

export const OrText = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  color: var(--text-primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xxs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;

  .security-text {
    color: var(--text-light-primary-color);
    font-size: 16px;
    line-height: 24px;
  }
`;

export const OtpText = styled.div`
  margin: 0 0 25px;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 360px;
  margin: auto;
`;

export const FormTitle = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-primary-color);
  margin: 1.5rem 0 0.6rem 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  margin-bottom: 1.5rem;
`;

export const BtnBlue = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;

export const FlexBtnCenter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const BtnText = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const HeaderOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const ResentOTP = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
  color: var(--primary-color);
`;

export const OTPInput = styled.div`
  margin: 24px 0;
`;

export const Strong = styled.span`
  font-weight: ${FONT_WEIGHT.semibold};
`;
