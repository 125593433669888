import React from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import TranslationPage from '@app/pages/TranslationPage';
import RolesPage from '@app/pages/RolesPage';
import ListUsersPage from '@app/pages/ListUsersPage';
import CompanyLayout from '@app/components/company/CompanyLayout';
import ProjectLayout from '@app/components/project/ProjectLayout';
import InvitationLayout from '@app/components/invitation/InvitationLayout';
import SignUpAddDetailsPage from '@app/pages/SignUpAddDetailsPage';
import SentInvitationSuccessPage from '@app/pages/SentInvitationSuccessPage';
import PayAttentionPage from '@app/pages/PayAttentionPage';
import Authorization from '@app/pages/Authorization';
import SomethingWrongPage from '@app/pages/SomethingWrongPage';
import PageNotFoundPage from '@app/pages/PageNotFoundPage';
import InternalErrorPage from '@app/pages/InternalErrorPage';
import NotFound404Page from '@app/pages/NotFound404Page';
import ContactInfoPage from '@app/pages/ContactInfoPage';
import NeedHelpPage from '@app/pages/NeedHelpPage';
import CookiePolicyPage from '@app/pages/CookiePolicyPage';
import MoneyTransferredFlowPage from '@app/pages/MoneyTransferredFlowPage';
import NeedInsurancePage from '@app/pages/NeedInsurancePage';
import BuyNewCarPage from '@app/pages/BuyNewCarPage';
import ShortTermInvestmentPage from '@app/pages/ShortTermInvestment';
import FeedBackPage from '@app/pages/FeedbackPage';
import RequireLogin from './RequireLogin';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const CreateUserFormPage = React.lazy(() => import('@app/pages/CreateUserFormPage'));
const AccessDeniedPage = React.lazy(() => import('@app/pages/AccessDeniedPage'));
const KanbanPage = React.lazy(() => import('@app/pages/KanbanPage'));
const ProjectDetailsPage = React.lazy(() => import('@app/pages/ProjectDetailsPage'));
const TaskOrProcessDetailsPage = React.lazy(() => import('@app/pages/TaskOrProcessDetailsPage'));
const ListProjectsPage = React.lazy(() => import('@app/pages/ListProjectPage'));
const CompanyInfoPage = React.lazy(() => import('@app/pages/CompanyInfoPage'));
const ListCompaniesPage = React.lazy(() => import('@app/pages/ListCompaniesPage'));
const InvitationDetailsPage = React.lazy(() => import('@app/pages/InvitationDetailsPage'));
const ListInvitationsPage = React.lazy(() => import('@app/pages/ListInvitationsPage'));
const DocumentInfoPage = React.lazy(() => import('@app/pages/DocumentInfoPage'));
const ContractPage = React.lazy(() => import('@app/pages/ContractPage'));
const ServiceFeesPaymentPage = React.lazy(() => import('@app/pages/ServiceFeesPaymentPage'));
const SystemTransferFlowPage = React.lazy(() => import('@app/pages/SystemTransferFlowPage'));
const UserInfoPage = React.lazy(() => import('@app/pages/UserInfoPage'));
const KeysAndCarTransferFlowPage = React.lazy(() => import('@app/pages/KeysAndCarTransferFlowPage'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const CreateUserForm = withLoading(CreateUserFormPage);
const Translation = withLoading(TranslationPage);
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
const AccessDenied = withLoading(AccessDeniedPage);
const ListUsers = withLoading(ListUsersPage);
const Kanban = withLoading(KanbanPage);
const ProjectDetails = withLoading(ProjectDetailsPage);
const TaskOrProcessDetails = withLoading(TaskOrProcessDetailsPage);
const ListProjects = withLoading(ListProjectsPage);
const CompanyInfo = withLoading(CompanyInfoPage);
const ListCompanies = withLoading(ListCompaniesPage);
const InvitationDetails = withLoading(InvitationDetailsPage);
const ListInvities = withLoading(ListInvitationsPage);
const Contract = withLoading(ContractPage);
const ServiceFeesPayment = withLoading(ServiceFeesPaymentPage);
const SystemTransferFlow = withLoading(SystemTransferFlowPage);
const UserInfo = withLoading(UserInfoPage);
const KeysAndCarTransferFlow = withLoading(KeysAndCarTransferFlowPage);
const MoneyTransferredFlow = withLoading(MoneyTransferredFlowPage);
const NeedInsurance = withLoading(NeedInsurancePage);
const BuyNewCar = withLoading(BuyNewCarPage);
const ShortTermInvestment = withLoading(ShortTermInvestmentPage);
const GiveFeedbacks = withLoading(FeedBackPage);
// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const DocumentInfo = withLoading(DocumentInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const Router = () => {
  const routes = [
    {
      path: DASHBOARD_PATH,
      element: (
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      ),
      children: [
        { path: DASHBOARD_PATH, element: <ListProjects /> },
        {
          path: 'projects',
          element: <ProjectLayout />,
          children: [
            {
              path: 'list-projects',
              element: <ListProjects />,
            },
            {
              path: ':projectId',
              element: <ProjectDetails />,
            },
            { path: ':projectId/:taskOrProcessId', element: <TaskOrProcessDetails /> },
            {
              path: ':projectId/:taskOrProcessId/:childTasOrProcessId',
              element: <TaskOrProcessDetails />,
            },
          ],
        },
        { path: 'kanban/:projectId', element: <Kanban /> },
        {
          path: 'companies',
          element: <CompanyLayout />,
          children: [
            {
              path: ':companyId',
              element: <CompanyInfo />,
            },
            {
              path: 'list-companies',
              element: <ListCompanies />,
            },
          ],
        },
        {
          path: 'users',
          children: [
            {
              path: ':userId',
              element: <UserInfo />,
            },
            {
              path: 'create-user',
              element: <CreateUserForm />,
            },
            {
              path: 'list-users',
              element: <ListUsers />,
            },
          ],
        },
        {
          path: 'translations',
          element: <Translation />,
        },
        {
          path: 'roles',
          element: <RolesPage />,
        },
        {
          path: 'server-error',
          element: <ServerError />,
        },
        {
          path: '404',
          element: <Error404 />,
        },
        {
          path: 'profile',
          element: <ProfileLayout />,
          children: [
            {
              path: 'personal-info',
              element: <PersonalInfo />,
            },
            {
              path: 'document-info',
              element: <DocumentInfo />,
            },
            {
              path: 'security-settings',
              element: <SecuritySettings />,
            },
            {
              path: 'notifications',
              element: <Notifications />,
            },
          ],
        },
        {
          path: 'invities',
          element: <InvitationLayout />,
          children: [
            {
              path: 'list-invities',
              element: <ListInvities />,
            },
          ],
        },
      ],
    },
    {
      path: '/auth',
      element: <AuthLayoutFallback />,
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: 'sign-up',
          element: <SignUpPage />,
        },
        {
          path: 'lock',
          element: (
            <RequireLogin>
              <LockPage />
            </RequireLogin>
          ),
        },
        {
          path: 'forgot-password',
          element: <ForgotPasswordPage />,
        },
        {
          path: 'new-password',
          element: <NewPasswordPage />,
        },
      ],
    },
    {
      path: '/contract',
      children: [
        {
          path: ':type/:projectId',
          element: (
            <RequireLogin>
              <Contract />
            </RequireLogin>
          ),
        },
      ],
    },
    {
      path: 'sign-up',
      children: [
        {
          path: '',
          element: (
            <RequireLogin>
              <SignUpAddDetailsPage />
            </RequireLogin>
          ),
        },
        {
          path: 'authorization/:type',
          element: <Authorization />,
        },
        {
          path: 'security-code',
          element: <SecurityCodePage />,
        },
      ],
    },
    {
      path: '/sent-invitation-success',
      element: (
        <RequireLogin>
          <SentInvitationSuccessPage />
        </RequireLogin>
      ),
    },
    {
      path: '/pay-attention',
      element: (
        <RequireLogin>
          <PayAttentionPage />
        </RequireLogin>
      ),
    },
    {
      path: '/logout',
      element: <LogoutFallback />,
    },
    {
      path: '/accessDenied',
      element: <AccessDenied />,
    },
    {
      path: '/cookie-policy',
      element: <CookiePolicyPage />,
    },
    {
      path: '/invitation/:invitationId/:userType',
      element: <InvitationDetails />,
    },
    {
      path: '/payment',
      children: [
        {
          path: 'service-fees-payment/:type/:userId/:projectId',
          element: (
            <RequireLogin>
              <ServiceFeesPayment />
            </RequireLogin>
          ),
        },
      ],
    },
    {
      path: '/system-transfer',
      children: [
        {
          path: ':type/:projectId',
          element: (
            <RequireLogin>
              <SystemTransferFlow />
            </RequireLogin>
          ),
        },
      ],
    },
    {
      path: '/car-keys-transfer',
      children: [
        {
          path: ':type/:projectId',
          element: <KeysAndCarTransferFlow />,
        },
      ],
    },
    {
      path: '/money-transferred',
      children: [
        {
          path: ':type/:projectId',
          element: <MoneyTransferredFlow />,
        },
      ],
    },
    {
      path: '/advertisement',
      children: [
        {
          path: 'need-insurance',
          element: <NeedInsurance />,
        },
        {
          path: 'time-to-buy-new-car',
          element: <BuyNewCar />,
        },
        {
          path: 'short-term-investment',
          element: <ShortTermInvestment />,
        },
      ],
    },
    {
      path: '/feedback',
      element: <GiveFeedbacks />,
    },
    {
      path: '/error',
      children: [
        {
          path: 'something-wrong',
          element: <SomethingWrongPage />,
        },
        {
          path: 'page-not-found',
          element: <PageNotFoundPage />,
        },
        {
          path: 'internal-error',
          element: <InternalErrorPage />,
        },
        {
          path: '404',
          element: <NotFound404Page />,
        },
      ],
    },
    {
      path: '/contact',
      children: [
        {
          path: '',
          element: <ContactInfoPage />,
        },
        {
          path: 'need-help',
          element: <NeedHelpPage />,
        },
      ],
    },
  ];

  const element = useRoutes(routes);

  return element;
};

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};
