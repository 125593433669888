import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import SentInvitationSuccessScreen from '@app/components/success/SentInvitationSuccess';

const SentInvitationSuccessPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <SentInvitationSuccessScreen />
    </>
  );
};

export default SentInvitationSuccessPage;
