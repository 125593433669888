import React, { useState } from 'react';
import { Rate } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';

const StarRating: React.FC = () => {
  const [value, setValue] = useState<number>(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchValue = calculateTouchValue(e);
    setValue(touchValue);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    const touchValue = calculateTouchValue(e);
    setValue(touchValue);
  };

  const calculateTouchValue = (e: React.TouchEvent<HTMLDivElement>) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const touchX = e.touches[0].clientX - boundingRect.left;
    const starWidth = boundingRect.width / 5;
    const newValue = Math.ceil(touchX / starWidth);
    return Math.min(5, Math.max(0, newValue));
  };

  const handleTouchEnd = () => {
    // Handle touch end if needed
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <Rate
        allowHalf
        value={value}
        character={(hoverValue) => (
          <span style={{ fontSize: '28px' }}>{hoverValue <= value ? <StarFilled /> : <StarOutlined />}</span>
        )}
      />
    </div>
  );
};

export default StarRating;
