import { httpApi } from '@app/api/http.api';
import { FilesModel, UserModel } from '@app/domain/UserModel';
import { Company } from '@app/interfaces/interfaces';
import { AddStatToPRojectRequest, CreateProjectRequest, UpdateProjectRequest } from '@app/store/slices/projectSlice';

export interface Tasks {
  type: string;
  id: string;
  title: string;
  description: string;
  tags?: string[];
  assignedTo?: UserModel;
  collaborators?: string[];
  createdBy?: string;
  status: string;
  childTask?: Tasks[];
  childProcess?: Process[];
  createdAt?: string;
}

export interface Process {
  type: string;
  id: string;
  title: string;
  description: string;
  tags?: string[];
  assignedTo?: UserModel;
  collaborators?: string[];
  createdBy?: string;
  status: string;
  childTask?: Tasks[];
  childProcess?: Process[];
  createdAt?: string;
}

export interface Project {
  id: string;
  title: string;
  description: string;
  createdBy: UserModel;
  processes: Process[];
  tasks: Tasks[];
  image?: {
    name: string;
    entity: string;
    id: string;
    signedURL?: string;
  };
  dueDate: string;
  company: Company;
  createdAt?: string;
  responsible: UserModel;
  seller: UserModel[];
  buyer: UserModel[];
  status: string;
  attachments: FilesModel[];
  isSellerPaid: boolean;
  isBuyerPaid: boolean;
  isBothSignedContract: boolean;
  initiateTransfer: boolean;
  isRecipeUploaded: boolean;
  carPrice?: string;
  carId?: string;
  invitation?: string;
  recipe?: FilesModel;
  buyerSignedContractAt?: Date;
  sellerSignedContractAt?: Date;
  sellerBankAccountDetails?: {
    accountHolderName?: string;
    accountNumber?: string;
    bankName?: string;
    departmentNumber?: string;
  } | null;
  sellerAddressDetails?: {
    city?: string;
    name?: string;
    streetAddress?: string;
    zipCode?: string;
  } | null;
}

export interface ProjectQueryResponse {
  limit: number;
  page: number;
  results: [Project];
  totalPages: number;
  totalResults: number;
}

export interface QueryProjectRequest {
  page?: number;
  limit?: number;
  search?: string;
  role?: string;
  company?: string;
}

export interface InviteToProject {
  project: string;
  email: string;
  inviteType: string;
  phone: string;
  phoneprefix: string;
  invitedBy: string;
  type: string;
  firstName: string;
  lastName?: string;
  responsible: string;
}

export interface ProjectCompletedNotificaiton {
  projectId: string;
}

export const createProject = async (projectPayload: CreateProjectRequest): Promise<Project> => {
  return httpApi
    .post<Project>(`projects/createProject`, projectPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const updateProject = async (projectPayload: UpdateProjectRequest): Promise<Project> => {
  const { projectId, projectDetails } = projectPayload;
  return httpApi
    .patch<Project>(`projects/${projectId}`, projectDetails)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getProjectsWithPagination = (querPayload: QueryProjectRequest): Promise<ProjectQueryResponse> => {
  const { page, limit } = querPayload;

  return httpApi
    .get<ProjectQueryResponse>(`/projects/queryProjectWithPagination?page=${page}&limit=${limit}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getProjectListWithQuery = (querPayload: QueryProjectRequest): Promise<Project[]> => {
  const { company } = querPayload;
  const query = {
    company: company ? `company=${company}` : '',
  };
  return httpApi
    .get<Project[]>(`/projects/queryProjects?${query.company}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getProjectDetails = async (projectId: string): Promise<Project> => {
  return httpApi
    .get<Project>(`projects/${projectId}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const addStatusToProject = async (addStatusPayload: AddStatToPRojectRequest): Promise<Project> => {
  const { projectId, statusId } = addStatusPayload;
  return httpApi
    .patch<Project>(`projects/addStatus/${projectId}/${statusId}`, {})
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const sendInvitation = async (invitationPayload: InviteToProject): Promise<Project> => {
  return httpApi
    .post<Project>(`invities/sendInvitation`, invitationPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const sendNotification = async (notificationPayload: ProjectCompletedNotificaiton): Promise<Project> => {
  return httpApi
    .post<Project>(`notifications/sendProjectCompletionNotification`, notificationPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const deleteProject = async (projectId: string): Promise<Project> => {
  return httpApi
    .delete<Project>(`projects/${projectId}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const fetchProjectFiles = async (projectId: string): Promise<File[]> => {
  return httpApi.get<File[]>(`projects/${projectId}/files`).then(({ data }) => data);
};
