export enum GenderEnum {
  MALE,
  FEMALE,
}

export const Gender = {
  MALE: 'male',
  FEMALE: 'female',
};

export enum UserType {
  BUYER = 'buyer',
  SELLER = 'seller',
}
