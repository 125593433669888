import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { Select as CommonSelect } from '@app/components/common/selects/Select/Select';
import styled from 'styled-components';
import { Space } from 'antd';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
}

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: auto;
  .ant-form {
    color: var(--text-secondary-color);
  }
`;

export const FlexBox = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 360px;
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  max-width: 672px;
  margin: 0 auto;
  flex: 1;
  display: flex;

  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};

  .ant-image {
    display: block;
  }

  &.boxPartMiddle {
    padding: 0 16px;
  }
`;

export const Description = styled.span`
  color: black;
  font-size: 24px;
  font-weight: bold;
  align-self: flex-start;
  padding-top: 48px;
  display: block;
`;

export const BtnBlue = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  margin-top: 40px;
  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;

export const FlexBtnCenter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const BtnText = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const FormConatiner = styled.div<BoxPartInterFace>`
  margin-top: 1.5rem;

  & .ant-form-item-label {
    padding: 0;
  }
`;

export const FormItem = styled(BaseForm.Item)`
  color: black;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: black !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: 500;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  & .ant-form-item-optional {
    display: none !important;
  }

  & .ant-input {
    width: 100%;
    background: var(--lightGreyShadow) !important;
    color: black;
    padding: 8px 12px;
    outline: none;
    font-size: 14px;
  }

  & .ant-input::placeholder {
    color: #00000050;
  }
`;

export const PhoneItem = styled(BaseForm.Item)`
  .ant-input-group-addon {
    padding: 0;
    width: 6rem !important;

    .ant-select-rtl {
      .ant-select-selection-item {
        direction: ltr;
      }
    }
  }

  .ant-input-group-addon > div {
    width: 100% !important;
  }

  .ant-input-group-addon > div > div {
    padding: 0px !important;
  }

  .ant-input-group-addon > div > span.ant-select-arrow {
    right: 12px;
    bottom: 16px;
    color: black;
  }

  .ant-select-rtl > .ant-select-selector > .ant-select-selection-item {
    padding-right: 30px;
  }

  .ant-select {
    color: black !important;
    border: none !important;
    outline: none !important;
  }

  .ant-input {
    width: 100%;
    background: var(--text-secondary-color) !important;
    color: black;
    padding: 8px 12px;
    outline: none;
    font-size: 14px;
  }

  .ant-input::placeholder {
    color: #00000050;
  }

  label {
    color: black !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: 500;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-input-group-addon .ant-select-focused .ant-select-selector,
  .ant-input-group-addon .ant-select-open .ant-select-selector {
    color: black !important;
  }

  .ant-select-selection-item {
    padding-right: 14px !important;
    line-height: 52px !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const Select = styled(CommonSelect)`
  width: 5.9375rem;
`;

export const CountriesSpace = styled(Space)`
  gap: 2px !important;
  img {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50%;
  }

  & > .ant-space-item:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--black);
    font-size: ${FONT_SIZE.md};
    line-height: 1.25rem;
    font-weight: 500;
    padding-top: 2px;
  }
`;
