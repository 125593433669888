import React from 'react';
import { DropdownCollapse } from '@app/components/header/Header.styles';
import * as S from './SettingsOverlay.styles';
import LanguageDropdown from '@app/components/common/LanguageDropdown/LanguageDropdown';

export const SettingsOverlay: React.FC = ({ ...props }) => {
  return (
    <S.SettingsOverlayMenu {...props}>
      <DropdownCollapse bordered={false} expandIconPosition="end" ghost defaultActiveKey="themePicker">
        <LanguageDropdown />
      </DropdownCollapse>
    </S.SettingsOverlayMenu>
  );
};
