import { FONT_SIZE } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Label = styled(Typography.Text)`
  color: var(--text-nft-light-color);
  font-size: ${FONT_SIZE.xs};
`;

export const ParticipantRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ParticipantAvatar = styled.img`
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
`;

export const ParticipantName = styled.div`
  font-size: ${FONT_SIZE.xs};
  color: var(--text-main-color);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;
