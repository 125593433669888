import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { contentDirection, getRoutePermissionAccessCode } from '@app/utils/utils';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { PermissionTypes, RoutesMapping } from '@app/constants/enums/permission';
import { RoleTypes } from '@app/constants/enums/roleTypes';
import { ExtraService } from '@app/components/payment/PaymentFees';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const translation = useAppSelector((state) => state.translation);
  const location = useLocation();
  const toggleSider = () => setSiderCollapsed(!siderCollapsed);
  const userPermission = useAppSelector((state) => state.user.user?.role?.permissions);
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (user) {
        if (user.isAdmin) {
          setIsTwoColumnsLayout([DASHBOARD_PATH].includes(location.pathname) && isDesktop);
          if (userPermission) {
            const checkPermission = getRoutePermissionAccessCode(
              userPermission,
              RoutesMapping,
              location.pathname.split('/')[1],
            );

            if (
              !(
                checkPermission === PermissionTypes.READ ||
                checkPermission === PermissionTypes.READWRITE ||
                location.pathname === DASHBOARD_PATH
              )
            ) {
              navigate('/accessDenied');
            }
          }
        } else {
          const isSeller = user.type === RoleTypes.SELLER;
          const buyerNeedSign =
            user.lastProject &&
            user.lastProject.buyer &&
            user.lastProject.buyer.length > 0 &&
            user.lastProject.buyer[0].services &&
            user.lastProject.buyer[0].services.length > 0
              ? (user.lastProject.buyer[0]?.services as ExtraService[])?.filter((s) => s.sign).length > 0
              : false;
          const sellerNeedSign =
            user.lastProject &&
            user.lastProject.seller &&
            user.lastProject.seller.length > 0 &&
            user.lastProject.seller[0].services &&
            user.lastProject.seller[0].services.length > 0
              ? (user.lastProject.seller[0]?.services as ExtraService[])?.filter((s) => s.sign).length > 0
              : false;
          if (
            !user.email ||
            !user.idDocuments ||
            user.idDocuments.length === 0 ||
            !user.lastProject ||
            (user.lastProject && !user.lastProject.invitation)
          ) {
            navigate('/sign-up');
          } else if (
            user.lastProject &&
            (!user.lastProject.buyer ||
              (user.lastProject.buyer.length === 0 && isSeller) ||
              !user.lastProject.seller ||
              (user.lastProject.seller.length === 0 && !isSeller))
          ) {
            navigate('/sent-invitation-success');
          } else if (
            user.lastProject &&
            (((!user.lastProject.isBuyerPaid || !user.lastProject.isSellerPaid) && isSeller) ||
              ((!user.lastProject.isSellerPaid || !user.lastProject.isBuyerPaid) && !isSeller) ||
              !buyerNeedSign ||
              !sellerNeedSign)
          ) {
            navigate(`/payment/service-fees-payment/${user.type}/${user.id}/${user.lastProject.id}`);
          } else if (
            user.lastProject &&
            user.lastProject.isBuyerPaid &&
            user.lastProject.isSellerPaid &&
            buyerNeedSign &&
            sellerNeedSign &&
            !user.lastProject.initiateTransfer
          ) {
            navigate(`/contract/${user.type}/${user.lastProject.id}`);
          } else if (user.lastProject && user.lastProject.initiateTransfer) {
            navigate(`/system-transfer/${user.type}/${user.lastProject.id}`);
          } else {
            navigate('/accessDenied');
          }
        }
      }
    })();
  }, [location.pathname, isDesktop, navigate, userPermission, user]);

  return (
    <>
      {user && user.isAdmin && (
        <S.LayoutMaster className={contentDirection(translation.isRtl)}>
          <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
          <S.LayoutMain>
            <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
              <Header
                toggleSider={toggleSider}
                isSiderOpened={!siderCollapsed}
                isTwoColumnsLayout={isTwoColumnsLayout}
              />
            </MainHeader>
            <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
              <div>
                <Outlet />
              </div>
            </MainContent>
          </S.LayoutMain>
        </S.LayoutMaster>
      )}
    </>
  );
};

export default MainLayout;
