import styled from 'styled-components';
import { Button, Col } from 'antd';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { TextArea } from '../common/inputs/Input/Input';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
  alignItem?: string;
  marginTop?: string;
}

interface TitleInterFace {
  fontSize?: string;
  fontWeight?: string;
}

interface BtnBlueInterFace {
  width?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
  padding: 16px;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 64px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  max-width: 375px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};
  align-items: ${(props) => (props.alignItem ? props.alignItem : 'center')};
`;

export const IconBox = styled.div`
  padding: 12px;
  background: #f3f4f6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BaseFormBox = styled(BaseForm)`
  width: 100%;
`;

export const FormCol = styled(Col)`
  width: 100%;
`;

export const FormItem = styled(BaseForm.Item)`
  color: black;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: black !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: bold;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  & .ant-form-item-optional {
    display: none !important;
  }

  & .ant-input {
    width: 100%;
    background: var(--text-secondary-color) !important;
    color: black;
    padding: 8px 8px;
    outline: none;
  }

  & .ant-input::placeholder {
    color: #00000050;
  }
`;

export const FormConatiner = styled.div<BoxPartInterFace>`
  margin-top: 1.75rem;

  & .ant-form-item-label {
    padding: 0;
  }
`;

export const Title = styled.div<TitleInterFace>`
  color: black;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
`;

export const Description = styled.div`
  color: #6b7280;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const SmallDescription = styled.div`
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
`;

export const TextAreaBox = styled(TextArea)`
  width: 100% !important;
  height: 180px !important;
  resize: none;
`;

export const CheckBoxText = styled.div`
  color: #6b7280;
  font-size: 12px;
  font-weight: 500;

  span {
    color: black;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const BtnBlue = styled(Button)<BtnBlueInterFace>`
  border-radius: 50px;
  padding: 10px 20px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  max-width: 360px;
  width: ${(props) => (props.width ? props.width : 'auto')};

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;
