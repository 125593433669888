import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './errors.styles';
import pageNotFound from 'assets/images/page-not-found.svg';
import { Image } from 'antd';

const PageNotFoundScreen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart>
          <Image width={375} src={pageNotFound} preview={false} />
        </S.BoxPart>
        <S.BoxPart justifyContent="flex-start" alignItem="flex-start" gap={16}>
          <S.Title>{t('pageNotFoundError.pageNotFoundTitle')}</S.Title>
          <S.Description>{t('pageNotFoundError.pageNotFoundDescription')}</S.Description>
          <S.SmallDescription>{t('pageNotFoundError.errorMessage')}</S.SmallDescription>
          <S.BtnBlue type="primary" htmlType="submit">
            <S.BtnBox>{t('common.backToHome')}</S.BtnBox>
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default PageNotFoundScreen;
