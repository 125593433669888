import {
  createProcessOrTask,
  updateProcessOrTask,
  deleteProcessOrTask,
  addChildTaskOrProcess,
} from '@app/api/processTasks.api';
import { Process, Tasks } from '@app/api/project.api';
import { UserModel } from '@app/domain/UserModel';
import { ANY_OBJECT } from '@app/interfaces/interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface CreateProcessTasksRequest {
  title: string;
  description: string;
  project: string;
  assignedTo: UserModel | string | null;
  collaborators: string[] | null;
  createdBy?: string;
  status: string | null;
  type: string;
}

export interface UpdateProcessTasksRequest {
  id?: string;
  type: string;
  details: Process | Tasks | ANY_OBJECT;
}

export interface DeleteProcessTasksRequest {
  id: string | number;
  type?: string;
  projectId: string;
}

export interface AddChildPocessOrTaskRequest {
  details: Process | Tasks | ANY_OBJECT;
  parentType: string;
  taskOrProcessId: string;
}

const initialState = {
  processList: [],
};

export const doCreateProcessTask = createAsyncThunk(
  'processTask/createProcessOrTask',
  async (processPayload: CreateProcessTasksRequest) => createProcessOrTask(processPayload),
);

export const doUpdateProcessTask = createAsyncThunk(
  'processTask/updateProcessOrTask',
  async (processPayload: UpdateProcessTasksRequest) => updateProcessOrTask(processPayload),
);

export const doDeleteProcessTask = createAsyncThunk(
  'processTask/deleteProcessOrTask',
  async (processPayload: DeleteProcessTasksRequest) => deleteProcessOrTask(processPayload),
);

export const doAddChildProcessOrtask = createAsyncThunk(
  'processTask/addChildProcessOrtask',
  async (processPayload: AddChildPocessOrTaskRequest) => addChildTaskOrProcess(processPayload),
);

export const processSlice = createSlice({
  name: 'processTasks',
  initialState,
  reducers: {},
});

export default processSlice.reducer;
