import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Image } from 'antd';
import * as S from './CompanyInfo.styles';
import { capitalize, checkHTTPStatus } from 'utils/utils';
import editIcon from '@app/assets/icons/edit.svg';
import saveIcon from '@app/assets/icons/save.svg';
import closeIcon from '@app/assets/icons/close.svg';
import { Upload } from '@app/components/common/Upload/Upload';
import type { UploadProps } from 'antd';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { Entity } from '@app/constants/enums/entites';
import defaultlogo from 'assets/logo.png';
import { Company, Fileprops } from '@app/interfaces/interfaces';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useLoader } from '@app/hooks/useLoader';
import { doFetchCompanyDetails, doUpdateCompany, doUploadCompanyPicture } from '@app/store/slices/companySlice';
import { fetchUser } from '@app/store/slices/userSlice';
import { PermissionTypes } from '@app/constants/enums/permission';

interface CompanyInfoProps {
  companyData: Company | undefined;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = ({ companyData }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyDetails = useAppSelector((state) => state.company.company);
  const user = useAppSelector((state) => state.user.user);
  const userPermission = useAppSelector((state) => state.user.user?.role?.permissions);
  const [uploadImage, setUploadImage] = useState<string>('');
  const [companyImageFile, setCompanyImageFile] = useState({});
  const [companyImageAction, setCompanyImageAction] = useState(true);
  const { loader, handleLoaderOpen, handleLoaderClose } = useLoader();

  const isReadWrite = userPermission?.companies === PermissionTypes.READWRITE;

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    method: 'POST',
    accept: 'image/*',
    beforeUpload: (file) => {
      const isImage = file.type === 'image/*';
      return isImage;
    },
    onChange: (info) => {
      fileReaderHandler(info.file);
      setCompanyImageFile(info.file);
    },
  };

  const fileReaderHandler = (file: Fileprops) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      setUploadImage(event.target?.result as string);
    };
    reader.readAsDataURL(file as Blob);
  };

  const CompanyImageFunctionality = () => {
    if (uploadImage === '') {
      return companyDetails?.image && companyDetails?.image?.signedURL ? companyDetails?.image?.signedURL : defaultlogo;
    } else {
      return uploadImage;
    }
  };

  const closeCompanyImageHandler = () => {
    setUploadImage('');
    setCompanyImageAction(false);
    setCompanyImageFile({});
  };

  const saveCompanyImageHandler = () => {
    if (companyData && user) {
      handleLoaderOpen();
      dispatch(
        doUploadCompanyPicture({
          file: companyImageFile,
          entityId: user?.id,
          entity: Entity.COMPANIES,
        }),
      )
        .unwrap()
        .then(async (res) => {
          if (res) {
            const payload = {
              companyDetails: {
                image: res.id,
              },
              companyId: companyData.id || '',
            };
            await dispatch(doUpdateCompany(payload));
            await dispatch(fetchUser());
            handleLoaderClose();
            notificationController.success({
              message: t('alerts.successUpdateCompanyMessage'),
              description: t('alerts.successUpdateCompanyDescription'),
            });
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          checkHTTPStatus(Number(err.code), navigate);
        });
      setCompanyImageAction(false);
    }
  };

  const getCompanyDetails = useCallback(async () => {
    companyData?.id && (await dispatch(doFetchCompanyDetails(companyData?.id)));
  }, [companyData, dispatch]);

  useEffect(() => {
    uploadImage === '' ? setCompanyImageAction(false) : setCompanyImageAction(true);
  }, [uploadImage]);

  useEffect(() => {
    getCompanyDetails();
  }, [getCompanyDetails]);

  return companyDetails ? (
    <S.Wrapper>
      <Spinner spinning={loader}>
        <S.ImgWrapper>
          <Avatar shape="circle" src={CompanyImageFunctionality()} alt="Company" />
          {isReadWrite && (
            <Upload className="editIcon" {...props}>
              <Image src={editIcon} alt="Edit" preview={false} />
            </Upload>
          )}
        </S.ImgWrapper>
        {companyImageAction && (
          <S.ActionWrapper>
            <Image src={closeIcon} alt="Close" preview={false} onClick={closeCompanyImageHandler} />
            <Image src={saveIcon} alt="Save" preview={false} onClick={saveCompanyImageHandler} />
          </S.ActionWrapper>
        )}
        <S.Content>
          <S.Title>{`${capitalize(companyDetails?.name)}`}</S.Title>
          <S.Subtitle>{capitalize(companyDetails?.address || '')}</S.Subtitle>
        </S.Content>
      </Spinner>
    </S.Wrapper>
  ) : null;
};
