import React, { useState } from 'react';
import * as S from './Policy.styles';
import { useTranslation } from 'react-i18next';

const CookiePolicyScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isBoxOpen, setOpenBox] = useState(false);
  return (
    <S.Wrapper>
      <S.BtnBlue onClick={() => setOpenBox(true)}>{t('cookies.allowCookies')}</S.BtnBlue>
      {isBoxOpen ? (
        <S.WrapperTop>
          <S.FlexBox>
            <S.BoxContainer>
              <S.Description>
                {t('cookies.description')}
                <span>{t('cookies.cookiePolicy')}</span>
              </S.Description>
              <S.BtnBlue onClick={() => setOpenBox(false)}>{t('cookies.blockAllCookies')}</S.BtnBlue>
              <S.BtnBlue onClick={() => setOpenBox(false)}>{t('cookies.acceptAllCookies')}</S.BtnBlue>
            </S.BoxContainer>
          </S.FlexBox>
        </S.WrapperTop>
      ) : null}
    </S.Wrapper>
  );
};

export default CookiePolicyScreen;
