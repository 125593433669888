import { httpApi } from '@app/api/http.api';

export interface Service {
  id: string;
  title: string;
  description: string;
  price: number;
  translationKey: string;
  isAvailableFor: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface ServiceQueryResponse {
  limit: number;
  page: number;
  results: Service[];
  totalPages: number;
  totalResults: number;
}

export const getAllService = async (): Promise<ServiceQueryResponse> => {
  return httpApi
    .get<ServiceQueryResponse>(`service/getServices`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
