import Webcam from 'react-webcam';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { SyncOutlined, ReloadOutlined, CameraOutlined, PlusOutlined } from '@ant-design/icons';
import * as S from './Capture.styles';
import { notificationController } from '@app/controllers/notificationController';

interface WebCamProps {
  addCaptureImage: (image: File, base64: string) => void;
}

export const CustomWebcam: React.FC<WebCamProps> = ({ addCaptureImage }) => {
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState(null || '');
  const [facingMode, setFacingMode] = useState('user');
  const [supportsSwitchCamera, setSupportsSwitchCamera] = useState(false);

  const dataURLtoBlob = (dataURL: string) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot() || null;
    if (imageSrc) setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const retake = () => {
    setImgSrc('');
  };

  const handleCameraSwitch = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
  };

  const checkCameraSupport = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setSupportsSwitchCamera(videoDevices.length > 1);
    } catch (error) {
      notificationController.error({ message: error });
    }
  };

  const addCaptureImagehandler = (image: string) => {
    // Convert base64 image to a File object
    const imageBlob = dataURLtoBlob(image);
    const timestamp = new Date().getTime();
    const file = new File([imageBlob], `${timestamp}_captured_image.png`, { type: 'image/png' });
    addCaptureImage(file, image);
    retake();
  };

  useEffect(() => {
    checkCameraSupport();
  }, []);

  return (
    <div className="container">
      <S.WebcamWrapper className="webcamWrapper">
        {imgSrc ? (
          <S.PreviewImage src={imgSrc} preview={false} />
        ) : (
          <Webcam
            height={'100%'}
            width={'100%'}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={0.8}
            audio={false}
          />
        )}
      </S.WebcamWrapper>
      <S.ButtonWrapper className="buttonWrapper">
        <S.CameraActionButtons>
          {imgSrc ? (
            <div className="camButtons photo-done">
              <Button size="small" className="retakeButton" icon={<ReloadOutlined />} onClick={retake}>
                {t('common.retake')}
              </Button>
              <Button
                size="small"
                className="addUploadButton"
                icon={<PlusOutlined />}
                onClick={() => addCaptureImagehandler(imgSrc)}
              >
                {t('common.addToUpload')}
              </Button>
            </div>
          ) : (
            <div className="camButtons">
              <Button size="small" className="captureButton" icon={<CameraOutlined />} onClick={capture}>
                {t('common.capture')}
              </Button>
              {supportsSwitchCamera && (
                <Button size="small" className="switchCamButton" icon={<SyncOutlined />} onClick={handleCameraSwitch}>
                  {t('common.switchCamera')}
                </Button>
              )}
            </div>
          )}
        </S.CameraActionButtons>
      </S.ButtonWrapper>
    </div>
  );
};
