import {
  accpectInvitation,
  rejectInvitation,
  getInvitationDetails,
  QueryInvitationRequest,
  getInvitationListWithQuery,
} from '@app/api/invitation.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const doAcceptInvitation = createAsyncThunk('invitation/accept', async (token: string) => {
  return await accpectInvitation(token);
});

export const doRejectInvitation = createAsyncThunk('invitation/reject', async (token: string) => {
  return await rejectInvitation(token);
});

export const doGetInvitationById = createAsyncThunk('invitation/invitationDetails', async (id: string) => {
  return await getInvitationDetails(id);
});

export const doGetInvitationsWithPagination = createAsyncThunk(
  'invitation/invitationList',
  async (projectPayload: QueryInvitationRequest) => {
    const res = await getInvitationListWithQuery(projectPayload);
    return res;
  },
);

export const statusSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {},
});

export default statusSlice.reducer;
