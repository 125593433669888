import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import FeedBack from '@app/components/feedback/feedback';

const FeedBackPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <FeedBack />
    </>
  );
};

export default FeedBackPage;
