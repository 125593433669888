import { Dispatch, createAction, createSlice, PrepareAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UserModel, FilesModel } from '@app/domain/UserModel';
import type { UploadProps } from 'antd';
import {
  createClient,
  updateClient,
  getUserByAuth,
  getUserListWithPagination,
  getUserListWithQuery,
  getUserDetailsById,
  deleteUser,
} from '@app/api/users.api';
import { getAWSSignedURL } from '@app/api/general.api';
import { removeSpaceAndRepalce } from '@app/utils/utils';
import { doLogout } from './authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Entity } from '@app/constants/enums/entites';
import { GenderEnum } from '@app/constants/enums/gender';
import { Role } from '@app/api/role.api';
import { ANY_OBJECT, Company, FileUploadRequest } from '@app/interfaces/interfaces';
import { uploadFile, uploadFiles } from '@app/api/file.api';
import { readToken } from '@app/services/localStorage.service';

export interface UserState {
  user: UserModel | null;
}

export interface ClientRequest {
  name?: string;
  email?: string | null;
  password?: string;
  role?: Role | string;
  company?: Company | string;
  image?: UploadProps | string;
  firstname?: string;
  lastname?: string;
  gender?: GenderEnum | string;
  phone?: string | number;
  phoneprefix?: string | number;
  zipcode?: string | number;
  birthdate?: string;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  documents?: UploadProps[];
  idDocuments?: UploadProps[] | string[];
  govermentId?: string;
  services?: string[];
}

export interface UpdateUserRequest {
  userDetails: ClientRequest;
  userId: string;
}

export interface QueryRequest {
  page?: number;
  limit?: number;
  search?: string;
  role?: string;
  company?: string;
  notInclude?: ANY_OBJECT;
}

const initialState: UserState = { user: null };

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  return {
    payload: newUser,
  };
});

export const doCreateClient = createAsyncThunk('user/createClient', async (clientPayload: ClientRequest) => {
  const res = createClient(clientPayload);
  return res;
});

export const doUpdateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (clientPayload: UpdateUserRequest, { dispatch }) =>
    updateClient(clientPayload).then((res) => {
      if (res) {
        setUserDetails(res, dispatch);
      }
    }),
);

export const doUpdateUser = createAsyncThunk('user/updateUser', async (clientPayload: UpdateUserRequest) =>
  updateClient(clientPayload),
);

export const fetchUser = createAsyncThunk('auth/getUserDetails', async (unknown, { dispatch }) =>
  getUserByAuth()
    .then(async (res) => {
      setUserDetails(res, dispatch);
      return res;
    })
    .catch((err) => {
      notificationController.error({ message: err.message });
      dispatch(doLogout());
    }),
);

export const doFetchUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (requestPayload: { userId: string }) => {
    const res = getUserDetailsById(requestPayload);
    return res;
  },
);

export const doDeleteUser = createAsyncThunk('user/deleteUser', async (userId: string) => {
  const res = await deleteUser(userId);
  return res;
});

export const doUploadProfilePicture = createAsyncThunk(
  'user/updateUserProfile',
  async (filePayload: FileUploadRequest) => uploadFile(filePayload),
);

export const doUploadDocuments = createAsyncThunk('user/updateUserDocuments', async (filePayload: FileUploadRequest) =>
  uploadFiles(filePayload),
);

export const fetchUserListWithPagination = createAsyncThunk(
  'user/getUserlistWithPagination',
  async (queryPayload: QueryRequest) => getUserListWithPagination(queryPayload),
);

export const fetchUserListWithQuery = createAsyncThunk('user/getUserlist', async (queryPayload: QueryRequest) =>
  getUserListWithQuery(queryPayload),
);

export const getImageSignedURL = async (filename: string, entity: string): Promise<string> => {
  const res = await getAWSSignedURL({ filename, entity });
  return res.toString();
};

export const setUserDetails = async (user: UserModel, dispatch: Dispatch): Promise<UserModel> => {
  const token = readToken();
  const company = user?.company && user?.company?.name ? removeSpaceAndRepalce(user?.company?.name, '-') : 'common';
  const profileImageURL: string | null =
    company &&
    user?.image?.name &&
    token &&
    (await getImageSignedURL(user?.image?.name, `${company}/${user?.image?.entity}`));
  const companyImageURL: string | undefined =
    company &&
    user?.company?.image?.name &&
    token &&
    (await getImageSignedURL(user?.company?.image?.name, `${company}/${user?.company?.image?.entity}`));
  const documentsURL: string[] = [];
  if (user?.documents?.length && token) {
    for (const document of user?.documents) {
      const documentURL: string | null = await getImageSignedURL(document?.name, `${company}/${document?.entity}`);
      if (documentURL) {
        documentsURL.push(documentURL);
      }
    }
  }
  const idDocumentsURL: string[] = [];
  if (user?.idDocuments?.length && token) {
    for (const document of user?.idDocuments) {
      const documentURL: string | null = await getImageSignedURL(document?.name, `${company}/${document?.entity}`);
      if (documentURL) {
        idDocumentsURL.push(documentURL);
      }
    }
  }

  if (profileImageURL) {
    user.image.signedURL = profileImageURL.toString();
  }

  if (companyImageURL && user.company?.image) {
    user.company.image.signedURL = companyImageURL.toString();
  }

  if (user?.documents?.length) {
    user.documents = user.documents.map((document: FilesModel, index: number) => {
      return {
        ...document,
        signedURL: documentsURL[index],
      };
    });
  }

  if (user?.idDocuments?.length) {
    user.idDocuments = user.idDocuments.map((document: FilesModel, index: number) => {
      return {
        ...document,
        signedURL: idDocumentsURL[index],
      };
    });
  }

  dispatch(setUser(user));
  return user;
};

export const userSlice = createSlice({
  name: Entity.USER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
