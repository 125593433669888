import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SignUpScreen } from '@app/components/auth/SignUpScreen/SignUpScreen';

const SignUpPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <SignUpScreen />
    </>
  );
};

export default SignUpPage;
