import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import Authorization from '@app/components/signUp/Authorization/Authorization';

const AuthorizatonPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <Authorization />
    </>
  );
};

export default AuthorizatonPage;
