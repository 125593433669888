import React from 'react';
import { ANY_OBJECT } from '@app/interfaces/interfaces';
import CellInputNode from '../CellInputNode/CellInputNode';
import { Role } from '@app/api/role.api';
import { Status } from '@app/components/apps/kanban/interfaces';
import { UserModel } from '@app/domain/UserModel';
import { ExtraService } from '@app/components/payment/PaymentFees';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  record: ANY_OBJECT;
  index: number;
  children: React.ReactNode;
  required: boolean;
  roleList: Role[];
  statusList: Status[];
  responsibleList: UserModel[];
  buyerList: UserModel[];
  sellerList: UserModel[];
  serviceList: ExtraService[];
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  required,
  record,
  roleList,
  statusList,
  responsibleList,
  buyerList,
  sellerList,
  serviceList,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <CellInputNode
          inputType={inputType}
          dataIndex={dataIndex}
          record={record}
          title={title}
          required={required}
          roleList={roleList}
          statusList={statusList}
          responsibleList={responsibleList}
          buyerList={buyerList}
          sellerList={sellerList}
          serviceList={serviceList}
        />
      ) : (
        children
      )}
    </td>
  );
};
