import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ShortTermInvestment from '@app/components/advertisement/shortTermInvestment';

const ShortTermInvestmentPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <ShortTermInvestment />
    </>
  );
};

export default ShortTermInvestmentPage;
