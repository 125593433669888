import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '../SignUpForm.styles';
import { Input, Space, Button, Image, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UserFormData } from '../SignUpAddDetailsPageForm';
import multiUserVector from '@app/assets/icons/multi-user-vector.svg';
import deleteRedVector from '@app/assets/icons/delete-red-vector.svg';
import { capitalize } from '@app/utils/utils';
import { RoleTypes } from '@app/constants/enums/roleTypes';
import addUserEnable from '@app/assets/icons/add-user-enable-vector.svg';
import { Fileprops } from '@app/interfaces/interfaces';

interface FormValues {
  [key: string]: string | UploadFile | null;
}

export interface Step2Props {
  enableCamera: (imageType: string) => void;
  formValues: FormValues;
  moreUser: UserFormData[];
  removeUser: (e: React.SyntheticEvent, user: UserFormData) => void;
  type: string;
  addMoreUser: () => void;
  addCaptureImage: (image: File, base64: string) => void;
  setImageSide: (imageSide: string) => void;
}

export const Step2: React.FC<Step2Props> = ({
  addCaptureImage,
  enableCamera,
  formValues,
  moreUser,
  removeUser,
  type,
  addMoreUser,
  setImageSide,
}) => {
  const { t } = useTranslation();

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    method: 'POST',
    accept: 'image/*',
    beforeUpload: (file) => {
      const isImage = file.type === 'image/*';
      return isImage;
    },
    onChange: (info) => {
      fileReaderHandler(info.file);
    },
  };

  const fileReaderHandler = (file: Fileprops) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      addCaptureImage(file as File, event.target?.result as string);
    };
    reader.readAsDataURL(file as Blob);
  };

  return (
    <>
      <S.FormCol>
        <S.HeaderWarpper>
          <div>
            <S.FormTitleBlack>{t('signup.tabs.driverLicense')}</S.FormTitleBlack>
            <S.FormDescription>{t('signup.stepForm.extraInfo')}</S.FormDescription>
          </div>
          <div>
            <S.AddMoreWrapper>
              <S.AddMoreUser enable={true} onClick={() => addMoreUser()}>
                <Image src={addUserEnable} alt="add more seller" />{' '}
                {type == RoleTypes.SELLER ? t('common.addSecondSeller') : t('common.addSecondBuyer')}
              </S.AddMoreUser>
            </S.AddMoreWrapper>
          </div>
        </S.HeaderWarpper>
        <S.FormConatiner>
          <S.TakePhotoWrapper>
            <S.FormItem
              name="frontImage"
              label={t('common.frontside')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Button type="primary" onClick={() => enableCamera('front')}>
                  {t('common.takephoto')}
                </Button>
                <Input placeholder={t('common.frontside')} disabled value={formValues.frontImage as string} />
              </Space.Compact>
            </S.FormItem>
            <S.NoteWrapper justifyContent={'space-between'}>
              <S.Note>{t('common.extrainfo')}</S.Note>
              <Upload {...props}>
                <S.UploadFile onClick={() => setImageSide('front')}>{t('common.uploadFile')}</S.UploadFile>
              </Upload>
            </S.NoteWrapper>
          </S.TakePhotoWrapper>
          <S.TakePhotoWrapper>
            <S.FormItem
              name="backImage"
              label={t('common.backside')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Button type="primary" onClick={() => enableCamera('back')}>
                  {t('common.takephoto')}
                </Button>
                <Input placeholder={t('common.backside')} disabled value={formValues.backImage as string} />
              </Space.Compact>
            </S.FormItem>
            <S.NoteWrapper justifyContent={'space-between'}>
              <S.Note>{t('common.extrainfo')}</S.Note>
              <Upload {...props}>
                <S.UploadFile onClick={() => setImageSide('back')}>{t('common.uploadFile')}</S.UploadFile>
              </Upload>
            </S.NoteWrapper>
          </S.TakePhotoWrapper>
          {moreUser?.length > 0 &&
            moreUser.map((user, index) => (
              <S.MoreSellerWarpper key={index}>
                <S.ActionWrapper>
                  <S.SellerIcon src={multiUserVector} width={30} height={30} alt="add more user" />
                  <S.SellerDetails>
                    <S.SellerName>{`${capitalize(user.firstName)} ${user.lastName}`}</S.SellerName>
                    <S.SellerPhone>
                      {user.phoneprefix}
                      {user.phone}
                    </S.SellerPhone>
                  </S.SellerDetails>
                </S.ActionWrapper>
                <S.ActionWrapper>
                  <S.DeleteButton onClick={(e) => removeUser(e, user)}>
                    <S.DeleteIcon src={deleteRedVector} alt="delete more seller" />
                    {t('common.delete')}
                  </S.DeleteButton>
                </S.ActionWrapper>
              </S.MoreSellerWarpper>
            ))}
        </S.FormConatiner>
      </S.FormCol>
    </>
  );
};
