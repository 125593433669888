import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './SignUpForm.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useNavigate } from 'react-router-dom';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { AddMoreUser } from './AddMoreUser';
import { defaultCountryDialCode } from '@app/constants/variables';
import { mergeBy, randomString } from '@app/utils/utils';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { LoginTypes } from '@app/constants/enums/loginType';
import { Step4 } from './Steps/Step4';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { CustomWebcam } from '../common/Capture/Capture';
import { RoleTypes } from '@app/constants/enums/roleTypes';
import moment from 'moment';
import { doCreateClient, doUpdateUser, doUploadDocuments } from '@app/store/slices/userSlice';
import {
  UpdateProjectRequest,
  doCreateProject,
  doSendInvitation,
  doUpdateProjectDetails,
  getProjectById,
} from '@app/store/slices/projectSlice';
import { Entity } from '@app/constants/enums/entites';
import { clone } from 'lodash';
import { Image } from 'antd';
import rightArrow from '@app/assets/icons/right-arrow-vector.svg';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import { RequestOTPFrom } from '@app/constants/enums/requestOTPFrom';
import { Project } from '@app/api/project.api';
import { Link } from 'react-router-dom';

interface SignUpPayload {
  firstname: string;
  lastname: string;
  phone: string;
  phoneprefix: string;
  role: string;
  company: string;
}

export interface UserFormData {
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  phoneprefix: string;
  moreUserBackImage: string;
  moreUserFrontImage: string;
  moreUserBackImageFile: UploadFile;
  moreUserFrontImageFile: UploadFile;
}

export interface FormValues {
  [key: string]: string | null | UploadFile;
}

export interface Fields {
  name: string;
  value: string | null | UploadFile;
}

interface UpdateUserPayload {
  userDetails: {
    firstname: string;
    lastname: string;
    phone: string | number;
    phoneprefix: string | number;
    email: string;
  };
  userId: string;
}

interface projectPayload {
  project: string;
  firstName: string;
  email: string;
  inviteType: string;
  phone: string;
  phoneprefix: string;
  invitedBy: string;
  responsible: string;
  type: string;
}

const formFields = [
  { name: 'firstname', value: '' },
  { name: 'lastname', value: '' },
  { name: 'email', value: '' },
  { name: 'phone', value: '' },
  { name: 'phoneprefix', value: defaultCountryDialCode },
  { name: 'carName', value: '' },
  { name: 'carDocImage', value: '' },
  { name: 'carDocImageFile', value: '' },
  { name: 'inviteName', value: '' },
  { name: 'frontImage', value: '' },
  { name: 'backImage', value: '' },
  { name: 'frontImageFile', value: '' },
  { name: 'backImageFile', value: '' },
  { name: 'invitePhone', value: '' },
  { name: 'inviteEmail', value: '' },
  { name: 'invitePhoneprefix', value: defaultCountryDialCode },
  { name: 'inviteFirstName', value: '' },
  { name: 'inviteLastName', value: '' },
  { name: 'carId', value: '' },
  { name: 'carManufacture', value: '' },
  { name: 'carPrice', value: '' },
];

export const formMoreUserFileds = [
  { name: 'firstName', value: '' },
  { name: 'lastName', value: '' },
  { name: 'email', value: '' },
  { name: 'phone', value: '' },
  { name: 'phoneprefix', value: defaultCountryDialCode },
  { name: 'moreUserFrontImage', value: '' },
  { name: 'moreUserBackImage', value: '' },
  { name: 'moreUserFrontImageFile', value: '' },
  { name: 'moreUserBackImageFile', value: '' },
];

const SignUpAddDetailsPageForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const [fields, setFields] = useState<Fields[]>(formFields);
  const [fieldsMoreUser, setMoreUserFields] = useState<Fields[]>(formMoreUserFileds);
  const [current, setCurrent] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [enableCapture, setEnableCapture] = useState(false);
  const [imageSide, setImageSide] = useState('');
  const [enableAddMoreUser, setAddMoreUser] = useState(false);
  const [moreUser, setMoreUser] = useState<UserFormData[]>([]);
  const [userCreated, setUserCreated] = useState<string[]>([]);
  const [carIdVerified, setCarIdVerified] = useState(false);
  const [type, setType] = useState('');
  const [requestOTPFrom, setRequestOTPFrom] = useState('');

  const projectData = useAppSelector((state) => state?.project.projectDetails);
  const user = useAppSelector((state) => state.user.user);
  const statusList = useAppSelector((state) => state.user.user?.company?.defaultStatus);
  const isLoadingProjectDetails = useAppSelector((state) => state.project.loading);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const defaultCompany = process.env.REACT_APP_DEFAULT_COMPANY;
  const defaultRoleId = process.env.REACT_APP_DEFAULT_ROLE;
  const inviteType = process.env.REACT_APP_LOGIN_WITH ?? LoginTypes.EMAIL;
  const responsible = process.env.REACT_APP_SUPERADMIN;

  const formValues: FormValues = fields
    .filter((item: Fields) => item.name !== 'prefix')
    .reduce((acc: FormValues, item: Fields) => {
      acc[item.name] = item.value;
      return acc;
    }, {} as FormValues);

  const moreUserFormValues: FormValues = fieldsMoreUser
    .filter((item: Fields) => item.name !== 'prefix')
    .reduce((acc: FormValues, item: Fields) => {
      acc[item.name] = item.value;
      return acc;
    }, {} as FormValues);

  const steps = useMemo(() => {
    if ((type == RoleTypes.SELLER || type == RoleTypes.BUYER) && requestOTPFrom !== RequestOTPFrom.INVITATION) {
      return [
        {
          title: t('signup.termsAndConditions.name'),
        },
        {
          title: t('signup.tabs.createAccount'),
        },
        {
          title: t('signup.tabs.driverLicense'),
        },
        {
          title: t('signup.tabs.carInfo'),
        },
        {
          title: type == RoleTypes.SELLER ? t('signup.tabs.buyerInfo') : t('signup.tabs.sellerInfo'),
        },
      ];
    }

    return [
      {
        title: t('signup.termsAndConditions.name'),
      },
      {
        title: type == RoleTypes.BUYER ? t('signup.tabs.buyerInfo') : t('signup.tabs.sellerInfo'),
      },
      {
        title: t('signup.tabs.driverLicense'),
      },
    ];
  }, [requestOTPFrom, t, type]);

  const updateProjectDetails = useCallback(
    (projectDetails: UpdateProjectRequest) => {
      dispatch(doUpdateProjectDetails(projectDetails));
    },
    [dispatch],
  );

  const navigateToSuccessPage = async (moreUserIds: string[]) => {
    const projectId = user?.lastProject ? user?.lastProject.id : projectData ? projectData?.id : null;
    if (moreUserIds.length > 0 && projectId) {
      await dispatch(getProjectById(projectId))
        .unwrap()
        .then((res: Project) => {
          if (res) {
            if (type === RoleTypes.SELLER) {
              if (res?.seller.length > 0) {
                const userIds = res?.seller.map((a) => a.id);
                const updateProject = {
                  projectDetails: {
                    seller: [...userIds, ...moreUserIds],
                  },
                  projectId: res.id,
                  companyId: res?.company?.id,
                };
                updateProjectDetails(updateProject);
              }
            } else {
              if (res?.buyer.length > 0) {
                const userIds = res?.buyer.map((a) => a.id);
                const updateProject = {
                  projectDetails: {
                    buyer: [...userIds, ...moreUserIds],
                  },
                  projectId: res.id,
                  companyId: res?.company?.id,
                };
                updateProjectDetails(updateProject);
              }
            }
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
        });
    }
    navigate('/sent-invitation-success');
  };

  const enableCamera = (imageType: string) => {
    setEnableCapture(true);
    setImageSide(imageType);
  };

  const next = () => {
    // eslint-disable-next-line no-console
    console.log('yess in');
    form.validateFields().then(() => {
      // eslint-disable-next-line no-console
      console.log('current', current);
      switch (current) {
        case 1:
          handleUpdateUser();
          break;
        case 2:
          handleUpdateDocuments();
          break;
        case 3:
          handleCreateProject();
          break;
        default:
          setCurrent(current + 1);
      }
    });
  };

  const filesSetters = useCallback(
    (newFile: UploadFile, image: File) => {
      const getFieldNames = (imageSide: string) => {
        switch (imageSide) {
          case 'front':
            return ['frontImage', 'frontImageFile'];
          case 'back':
            return ['backImage', 'backImageFile'];
          case 'cardoc':
            return ['carDocImage', 'carDocImageFile'];
          case 'moreUserFront':
            return ['moreUserFrontImage', 'moreUserFrontImageFile'];
          case 'moreUserBack':
            return ['moreUserBackImage', 'moreUserBackImageFile'];
          default:
            return [undefined, undefined];
        }
      };

      const [fieldName, fieldFile] = getFieldNames(imageSide);

      if (!enableAddMoreUser) {
        const uniqueData = fields.map((item, index) => ({
          name: item.name,
          value:
            item.name === fieldName
              ? `${index}${imageSide}_${image.name}`
              : item.name === fieldFile
              ? newFile
              : item.value,
        }));
        setFields(uniqueData);
      } else {
        const uniqueData = fieldsMoreUser.map((item, index) => ({
          name: item.name,
          value:
            item.name === fieldName
              ? `${index}${imageSide}_${image.name}`
              : item.name === fieldFile
              ? newFile
              : item.value,
        }));
        setMoreUserFields(uniqueData);
      }
      setEnableCapture(false);
    },
    [enableAddMoreUser, fields, fieldsMoreUser, imageSide],
  );

  const addCaptureImage = useMemo(
    () => (image: File, base64: string) => {
      const newFile = {
        uid: randomString(8),
        name: image.name,
        lastModified: image.lastModified,
        lastModifiedDate: new Date(),
        originFileObj: image,
        percent: 0,
        size: image.size,
        type: image.type,
        thumbUrl: base64,
      } as UploadFile;
      filesSetters(newFile, image);
    },
    [filesSetters],
  );

  const handleUpdateUser = () => {
    setLoading(true);
    if (
      !formValues?.firstname ||
      !formValues?.lastname ||
      !formValues?.phone ||
      !formValues?.phoneprefix ||
      !formValues.govermentId
    ) {
      notificationController.error({ message: t('common.pleaseFillAllTheFields') });
      setLoading(false);
      return;
    }

    if (defaultRoleId && defaultCompany && user) {
      const payload = {
        userDetails: {
          firstname: formValues?.firstname,
          lastname: formValues?.lastname,
          phone: formValues?.phone,
          phoneprefix: formValues?.phoneprefix,
          email: formValues?.email ? formValues?.email : undefined,
          govermentId: formValues.govermentId,
        },
        userId: user?.id,
      };

      dispatch(doUpdateUser(payload as UpdateUserPayload))
        .unwrap()
        .then(() => {
          setCurrent(current + 1);
          setLoading(false);
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const handleUpdateDocuments = () => {
    if (!formValues.frontImageFile || !formValues.backImageFile) {
      notificationController.error({ message: t('common.pleaseUploadFrontBackImage') });
      return;
    }
    setLoading(true);
    if (user) {
      dispatch(
        doUploadDocuments({
          entityId: user?.id,
          entity: Entity.USERDOCUMENT,
          files: [formValues.frontImageFile, formValues.backImageFile] as UploadProps[],
        }),
      )
        .unwrap()
        .then(async (res) => {
          const newdocIds = res.map((item) => item.id);
          const payload = {
            userDetails: {
              idDocuments: [...newdocIds],
            },
            userId: user.id || '',
          };
          await dispatch(doUpdateUser(payload));
          if (moreUser.length) {
            const moreUserIds = [] as string[];
            for (let i = 0; i < moreUser.length; i++) {
              dispatch(
                doUploadDocuments({
                  entityId: user?.id,
                  entity: Entity.USERDOCUMENT,
                  files: [moreUser[i].moreUserFrontImageFile, moreUser[i].moreUserBackImageFile] as UploadProps[],
                }),
              )
                .unwrap()
                .then(async (res) => {
                  const newdocIds = res.map((item) => item.id);
                  const user = moreUser[i];
                  const payload = {
                    firstname: user?.firstName,
                    lastname: user?.lastName,
                    phone: user?.phone,
                    phoneprefix: user?.phoneprefix,
                    role: defaultRoleId,
                    company: defaultCompany,
                    type: type === RoleTypes.BUYER ? RoleTypes.BUYER : RoleTypes.SELLER,
                    idDocuments: [...newdocIds],
                  };

                  await dispatch(doCreateClient(payload as SignUpPayload))
                    .unwrap()
                    .then(async (res) => {
                      moreUserIds.push(res.id);
                      if (i === moreUser.length - 1) {
                        setUserCreated(moreUserIds);
                        if (requestOTPFrom !== RequestOTPFrom.INVITATION) {
                          setCurrent(current + 1);
                          setLoading(false);
                        } else {
                          navigateToSuccessPage(moreUserIds);
                        }
                      }
                    });
                });
            }
          } else {
            if (requestOTPFrom !== RequestOTPFrom.INVITATION) {
              setCurrent(current + 1);
              setLoading(false);
            } else {
              navigateToSuccessPage([]);
            }
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const handleCreateProject = () => {
    setLoading(true);
    if (!formValues?.carManufacture || !formValues?.carDocImageFile || !formValues?.carId || !formValues?.carPrice) {
      notificationController.error({ message: t('common.pleaseFillAllTheFields') });
      setLoading(false);
      return;
    }

    if (user) {
      dispatch(
        doUploadDocuments({
          entityId: user?.id,
          entity: Entity.PROJECT,
          files: [formValues?.carDocImageFile] as UploadProps[],
        }),
      )
        .unwrap()
        .then(async (res) => {
          const newdocIds = res.map((item) => item.id);
          const projectName = `${formValues.carManufacture}-${randomString(8)}-${user.firstname}`;
          if (statusList?.length) {
            const data = {
              title: `${projectName}`,
              dueDate: moment().add(10, 'day').format(),
              createdBy: user?.id,
              company: user?.company?.id,
              responsible: responsible,
              status: statusList[0]._id,
              attachments: [...newdocIds],
              carId: formValues.carId as string,
              carPrice: formValues.carPrice as string,
              seller: type === RoleTypes.SELLER ? [user.id, ...userCreated] : [],
              buyer: type === RoleTypes.BUYER ? [user.id, ...userCreated] : [],
            };

            dispatch(doCreateProject(data))
              .unwrap()
              .then(() => {
                setCurrent(current + 1);
                setLoading(false);
              })
              .catch((err) => {
                notificationController.error({ message: err.message });
                setLoading(false);
              });
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const handleSendInvitation = async () => {
    setLoading(true);
    if (
      !formValues?.inviteFirstName ||
      !formValues?.inviteLastName ||
      !formValues?.invitePhone ||
      !formValues?.invitePhoneprefix
    ) {
      notificationController.error({ message: t('common.pleaseFillAllTheFields') });
      setLoading(false);
      return;
    }
    if ((user?.lastProject || projectData) && user && responsible) {
      const payload = {
        project: user?.lastProject ? user?.lastProject.id : projectData?.id,
        firstName: formValues.inviteFirstName,
        lastName: formValues.inviteLastName,
        email: formValues.inviteEmail ? formValues.inviteEmail : undefined,
        inviteType: inviteType,
        phone: formValues.invitePhone,
        phoneprefix: formValues.invitePhoneprefix,
        invitedBy: user?.id,
        responsible: responsible,
        type: type === RoleTypes.BUYER ? RoleTypes.SELLER : RoleTypes.BUYER,
      };
      await dispatch(doSendInvitation(payload as projectPayload))
        .unwrap()
        .then(() => {
          setLoading(false);
          setEnableCapture(false);
          setCurrent(0);
          navigateToSuccessPage([]);
          form.resetFields();
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const addMoreUser = (userDetails: UserFormData) => {
    setMoreUser([...moreUser, userDetails]);
    setAddMoreUser(false);
  };

  const removeUser = useCallback(
    (e, user: UserFormData) => {
      e.preventDefault();
      const updatedUser = moreUser.filter((item) => item !== user);
      setMoreUser(updatedUser);
    },
    [moreUser],
  );

  const validateCarId = (carId: string) => {
    return typeof carId === 'string' && carId.length === 7;
  };

  const verifyCarId = useCallback(() => {
    if (formValues.carId && validateCarId(formValues.carId as string)) {
      setCarIdVerified(true);
    }
  }, [formValues.carId]);

  const formFieldsUi = useMemo(() => {
    if ((type == RoleTypes.SELLER || type == RoleTypes.BUYER) && requestOTPFrom !== RequestOTPFrom.INVITATION) {
      return [
        <TermsAndConditions key="0" onClick={() => setCurrent(current + 1)} />,
        <Step1 key="1" type={type} />,
        <Step2
          key="2"
          enableCamera={enableCamera}
          formValues={formValues}
          moreUser={moreUser}
          removeUser={removeUser}
          type={type}
          addMoreUser={() => setAddMoreUser(true)}
          addCaptureImage={addCaptureImage}
          setImageSide={setImageSide}
        />,
        <Step3
          key="3"
          isLoading={isLoadingProjectDetails}
          enableCamera={enableCamera}
          formValues={formValues}
          addCaptureImage={addCaptureImage}
          setImageSide={setImageSide}
          verifyCarId={verifyCarId}
          carIdVerified={carIdVerified}
          validateCarId={validateCarId}
        />,
        <Step4 key="4" type={type} />,
      ];
    }

    return [
      <TermsAndConditions key="0" onClick={() => setCurrent(current + 1)} />,
      <Step1 key="1" type={type as string} />,
      <Step2
        key="2"
        enableCamera={enableCamera}
        formValues={formValues}
        moreUser={moreUser}
        removeUser={removeUser}
        type={type}
        addMoreUser={() => setAddMoreUser(true)}
        addCaptureImage={addCaptureImage}
        setImageSide={setImageSide}
      />,
      <Step4 key="3" type={type as string} />,
    ];
  }, [
    addCaptureImage,
    carIdVerified,
    current,
    formValues,
    isLoadingProjectDetails,
    moreUser,
    removeUser,
    requestOTPFrom,
    type,
    verifyCarId,
  ]);

  const updateFormValues = useCallback(
    (user) => {
      const updatedFields = clone(fields).map((item) => {
        const found = user?.hasOwnProperty(item.name);
        if (found) {
          return { ...item, value: user[item.name] };
        }
        return item;
      });
      setFields(updatedFields);
    },
    // TODO: fix this later
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  useEffect(() => {
    if (scrollRef.current) {
      const optionWidth = (scrollRef.current.children[0] as HTMLElement)?.offsetWidth;
      scrollRef.current.scrollLeft = optionWidth * current - scrollRef.current.offsetWidth / 2 + optionWidth / 2;
    }
  }, [current]);

  useEffect(() => {
    if (user) {
      updateFormValues(user);
    }
  }, [user, updateFormValues]);

  useEffect(() => {
    if (user) {
      setType(user?.type as string);

      if (user.lastProject && user.lastProject.invitation) {
        const seller = user.lastProject.seller || [];
        const buyer = user.lastProject.buyer || [];
        const sellerInfo = seller.length > 0 ? seller[0] : null;
        const buyerInfo = buyer.length > 0 ? buyer[0] : null;
        if (
          (seller.length > 0 && buyer.length === 0) ||
          (buyer.length > 0 && seller.length === 0) ||
          (sellerInfo && buyerInfo && sellerInfo.email && !buyerInfo.email) ||
          (sellerInfo && buyerInfo && !sellerInfo.email && buyerInfo.email) ||
          (sellerInfo &&
            buyerInfo &&
            sellerInfo.idDocuments &&
            buyerInfo.idDocuments &&
            sellerInfo.idDocuments.length > 0 &&
            buyerInfo.idDocuments.length === 0) ||
          (sellerInfo &&
            buyerInfo &&
            sellerInfo.idDocuments &&
            buyerInfo.idDocuments &&
            sellerInfo.idDocuments.length === 0 &&
            buyerInfo.idDocuments.length > 0)
        ) {
          setRequestOTPFrom(RequestOTPFrom.INVITATION);
        } else if (seller.length > 0 && buyer.length > 0) {
          setRequestOTPFrom(RequestOTPFrom.LOGIN);
        } else {
          setRequestOTPFrom(RequestOTPFrom.SIGNUP);
        }
      } else {
        setRequestOTPFrom(RequestOTPFrom.SIGNUP);
      }
      if (!user.email || !user.govermentId) {
        setCurrent(0);
      } else if (!user.idDocuments || user.idDocuments.length === 0) {
        setCurrent(2);
      } else if (!user.lastProject) {
        setCurrent(3);
      } else if (
        (type === RoleTypes.SELLER &&
          user.lastProject &&
          user.lastProject.buyer &&
          user.lastProject.buyer.length === 0) ||
        (type === RoleTypes.BUYER &&
          user.lastProject &&
          user.lastProject.seller &&
          user.lastProject.seller.length === 0)
      ) {
        setCurrent(4);
      }
    } else {
      setRequestOTPFrom(RequestOTPFrom.SIGNUP);
    }
  }, [type, user]);

  return (
    <>
      {!enableCapture && !enableAddMoreUser && (
        <S.StepMainWrapper ref={scrollRef}>
          {steps.map((item, index) => {
            const active = current === index;
            const finished = current > index;
            const last = steps.length - 1 === index;
            return (
              <S.StepBox key={index}>
                <S.Step
                  title={item.title}
                  key={index}
                  className={finished ? 'finished' : active ? 'active' : 'pending'}
                >
                  <S.StepTitle className="step-title">{item.title}</S.StepTitle>
                </S.Step>
                {!last && (
                  <S.StepDivide>
                    <Image src={rightArrow} alt="right arrow" width={10} height={10} />
                  </S.StepDivide>
                )}
              </S.StepBox>
            );
          })}
        </S.StepMainWrapper>
      )}

      {!enableCapture ? (
        <S.FormWrapper>
          <S.FormBox>
            {!enableAddMoreUser ? (
              <BaseForm
                name="stepForm"
                form={form}
                fields={fields}
                onFieldsChange={(_, allFields) => {
                  const currentFields = allFields.map((item) => ({
                    name: Array.isArray(item.name) ? item.name[0] : '',
                    value: item.value,
                  }));
                  const uniqueData = mergeBy(fields, currentFields, 'name');
                  setFields(uniqueData);
                }}
              >
                <S.FormRow align="middle">{formFieldsUi[current]}</S.FormRow>
              </BaseForm>
            ) : (
              <AddMoreUser
                addMoreUser={addMoreUser}
                enableCamera={enableCamera}
                closeAddMore={() => setAddMoreUser(false)}
                setMoreUserFields={setMoreUserFields}
                fieldsMoreUser={fieldsMoreUser}
                moreUserFormValues={moreUserFormValues}
                addCaptureImage={addCaptureImage}
                setImageSide={setImageSide}
              />
            )}
            {current !== 0 && (
              <S.BoxPart justifyContent="flex-end" gap={5}>
                {current < steps.length - 1 && !enableAddMoreUser && (
                  <S.BtnBlue onClick={() => next()} loading={isLoading}>
                    <S.FlexBtnCenter>
                      <S.BtnText>
                        {t('forms.stepFormLabels.next')} : {steps[current + 1].title}
                      </S.BtnText>
                    </S.FlexBtnCenter>
                  </S.BtnBlue>
                )}
                {current === steps.length - 1 && !enableAddMoreUser && (
                  <S.BtnBlue
                    type="primary"
                    htmlType="submit"
                    onClick={
                      requestOTPFrom !== RequestOTPFrom.INVITATION ? handleSendInvitation : handleUpdateDocuments
                    }
                    loading={isLoading}
                  >
                    {requestOTPFrom !== RequestOTPFrom.INVITATION
                      ? type == RoleTypes.BUYER
                        ? t('signup.tabs.inviteSeller')
                        : t('signup.tabs.sellMyCar')
                      : t('signup.uploadDrivingLicense')}
                  </S.BtnBlue>
                )}
                {!enableAddMoreUser && (
                  <S.BtnBlue type="primary">
                    <Link to="/logout">{t('header.logout')}</Link>
                  </S.BtnBlue>
                )}
              </S.BoxPart>
            )}
          </S.FormBox>
        </S.FormWrapper>
      ) : (
        <S.CaptureImageWrapper>
          <CustomWebcam addCaptureImage={addCaptureImage} />
        </S.CaptureImageWrapper>
      )}
    </>
  );
};

export default SignUpAddDetailsPageForm;
