import styled from 'styled-components';
import { Checkbox, Button, Select } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';
import { InputPassword as CommonInputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import loginBackground from '@app/assets/images/screen-bg.svg';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';

interface FormWrapperInterFace {
  center?: boolean;
}

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
}

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  .ant-form {
    color: var(--text-secondary-color);
  }
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${loginBackground});
  background-size: cover;
  position: relative;
  background-position-y: 35%;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
`;

export const FormWrapper = styled.div<FormWrapperInterFace>`
  padding: 10px 20px;
  width: 100vw;
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.center ? 'center' : 'normal')};
  align-items: ${(props) => (props.center ? 'center' : 'normal')};
  max-height: 100vh;
  height: 100%;

  @media only screen and ${media.md} {
    max-height: 100vh;
    text-align: center;
  }
`;

export const FormTitle = styled.div`
  color: var(--text-secondary-color);

  @media only screen and ${media.xs} {
    margin-bottom: 0.625rem;
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 0.875rem;
    font-size: ${FONT_SIZE.xxxxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 0.9375rem;
    font-size: ${FONT_SIZE.xxxxxl};
    font-weight: ${FONT_WEIGHT.extraBold};
    line-height: 2.125rem;
  }
`;

export const FormCheckbox = styled(Checkbox)`
  display: flex;
  padding-left: 0.125rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;

  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: white !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: bold;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  & .ant-form-item-optional {
    display: none !important;
  }

  & .ant-input {
    width: 100%;
    background: white !important;
    color: black;
    padding: 8px 8px;
    outline: none;
  }

  & .ant-input::placeholder {
    color: #00000050;
  }
`;

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`;

export const FormNumberInput = styled(InputNumber)`
  color: var(--text-main-color);
  background: transparent;
  width: 100%;

  & input.ant-input {
    background: transparent;
  }
`;

export const FormInputPassword = styled(CommonInputPassword)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Text = styled.span`
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--text-secondary-color);
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
`;

export const SocialButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and ${media.xs} {
    margin-right: 0.625rem;
  }

  @media only screen and ${media.md} {
    margin-right: 0.8125rem;
  }
`;

export const SelectInput = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(5, 69, 196, 0.79) 36%, #052158 95.5%);
  z-index: 0;
  cursor: pointer;
`;

export const OverlayData = styled.div`
  z-index: 99;
  height: 100%;
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};
  align-items: center;
`;

export const FixBoxSize = styled.div`
  max-width: 360px;
  width: 100%;
  padding: 0rem 0.125rem;
`;
