import * as S from './PayAttention.styles';
import { Image } from 'antd';
import payAttention from 'assets/images/pay-attention.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import globeIcon from 'assets/icons/globe-icon.svg';

const PayAttention: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onContinue = () => {
    navigate(`/sign-up`);
  };

  return (
    <S.Wrapper disableBgImage={true}>
      <S.MainBoxWrapper>
        <S.FlexBox>
          <S.HeaderOption>
            <S.SupportWrapper
              onClick={(e) => {
                window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}}`;
                e.preventDefault();
              }}
            >
              <S.GlobeIcon src={globeIcon} />
              {t('common.support')}
            </S.SupportWrapper>
          </S.HeaderOption>
          <S.BoxPart>
            <Image width={320} src={payAttention} preview={false} />
          </S.BoxPart>
          <S.BoxPart justifyContent="center">
            <S.Description>{t('common.payAttentionTitle')}</S.Description>
            <S.DescriptionTwo paddingX={20}>{t('common.payAttentionDesc')}</S.DescriptionTwo>
          </S.BoxPart>
        </S.FlexBox>
      </S.MainBoxWrapper>
      <S.BtnBlue type="primary" htmlType="submit" onClick={onContinue}>
        {t('common.continue')}
      </S.BtnBlue>
    </S.Wrapper>
  );
};

export default PayAttention;
