import { doRequestOTP } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { checkHTTPStatus } from '@app/utils/utils';
import { NavigateFunction } from 'react-router-dom';
import { AppDispatch } from '@app/store/store';
import { LoginTypes } from '@app/constants/enums/loginType';
import { cryptoEncrpytHandler, cryptoEncrypt } from './rememberMeController';
import { setDataObjectWithKey } from '@app/services/localStorage.service';
import { LocalStorageKeys } from '@app/constants/enums/localStorageKeys';

const authType = process.env.REACT_APP_OTP_BY;

interface OtpValues {
  userType?: string;
  email?: string;
  phone?: string;
  phoneprefix?: string;
  rememberMe?: boolean;
  firstname?: string;
  lastname?: string;
  projectId?: string;
}

export const SendOtpHandler = (
  values: OtpValues,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  t: (val: string) => void,
  setLoadingEnable: () => void,
  setLoadingDisable: () => void,
  requestOTPFrom: string,
): void => {
  const payload = {
    type: authType === LoginTypes.EMAIL ? LoginTypes.EMAIL : LoginTypes.PHONE,
    email: values.email,
    phone: values.phone,
    phoneprefix: values.phoneprefix,
  };
  setLoadingEnable();

  dispatch(doRequestOTP(payload))
    .unwrap()
    .then(() => {
      notificationController.info({ message: t('common.successRequestOTP') });
      if (authType === LoginTypes.EMAIL) {
        setLoadingDisable();
        navigate('/sign-up/security-code', { state: { email: payload.email, requestOTPFrom, type: values.userType } });
        cryptoEncrpytHandler({ email: values.email, rememberMe: values.rememberMe });
      } else {
        setLoadingDisable();
        cryptoEncrpytHandler({
          type: values.userType,
          firstname: values.firstname,
          lastname: values.lastname,
          phoneprefix: values.phoneprefix,
          phone: values.phone,
          rememberMe: values.rememberMe,
          requestOTPFrom: requestOTPFrom,
          projectId: values.projectId,
        });
        const tempUserStatesDetails = cryptoEncrypt({
          type: values.userType,
          firstname: values.firstname,
          lastname: values.lastname,
          phoneprefix: values.phoneprefix,
          phone: values.phone,
          rememberMe: values.rememberMe,
          requestOTPFrom: requestOTPFrom,
          projectId: values.projectId,
        });
        setDataObjectWithKey(LocalStorageKeys.USERSTATE, tempUserStatesDetails);
        navigate('/sign-up/security-code');
      }
    })
    .catch((err: { message: string; code: number }) => {
      notificationController.error({ message: err.message });
      checkHTTPStatus(Number(err.code), navigate);
      setLoadingDisable();
    });
};
