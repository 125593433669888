import { UserModel } from '@app/domain/UserModel';
import { httpApi } from './http.api';

export interface Invite {
  id: string;
  firstName: string;
  lastName: string;
  project: {
    id: string;
    title: string;
    carId: string;
    carPrice: string;
    seller: UserModel[];
    buyer: UserModel[];
  };
  email: string;
  inviteType: string;
  phone: string;
  phoneprefix: string;
  status: number;
  invitedBy: {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    phoneprefix: string;
  };
  type: string;
  responsible: {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    phoneprefix: string;
  };
  createdAt?: string;
  bodyContent?: string;
}

export interface QueryInvitationRequest {
  name?: string;
  role?: string;
  sortBy?: string;
  projectBy?: string;
  limit?: number;
  page?: number;
}

export interface InvitationQueryResponse {
  limit: number;
  page: number;
  results: [Invite];
  totalPages: number;
  totalResults: number;
}

export interface AcceptRejectInvitation {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  type: string;
}

export const accpectInvitation = async (token: string): Promise<AcceptRejectInvitation> => {
  return httpApi
    .get<AcceptRejectInvitation>(`invities/acceptInvitation/${token}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const rejectInvitation = async (token: string): Promise<AcceptRejectInvitation> => {
  return httpApi
    .get<AcceptRejectInvitation>(`invities/rejectInvitation/${token}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getInvitationDetails = async (id: string): Promise<Invite> => {
  return httpApi
    .get<Invite>(`invities/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getInvitationListWithQuery = (querPayload: QueryInvitationRequest): Promise<InvitationQueryResponse> => {
  const { page, limit } = querPayload;

  return httpApi
    .get<InvitationQueryResponse>(`/invities/getInvitationWithPagination?page=${page}&limit=${limit}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
