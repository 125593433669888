import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;

export const Role = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: var(--text-primary);

  @media only screen and ${media.md} {
    font-size: 0.5rem;
    line-height: 1rem;
  }

  @media only screen and ${media.lg} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
