import { httpApi } from '@app/api/http.api';
import { FileUploadRequest, UpdateImgResponse } from '@app/interfaces/interfaces';
import { jsonToFormData } from '@app/utils/utils';

export const uploadFile = (filePayload: FileUploadRequest): Promise<UpdateImgResponse> => {
  const formData = jsonToFormData(filePayload);
  return httpApi
    .post<UpdateImgResponse>(`/files/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const uploadFiles = (filePayload: FileUploadRequest): Promise<UpdateImgResponse[]> => {
  const formData = jsonToFormData(filePayload);
  return httpApi
    .post<UpdateImgResponse[]>(`/files/uploadMultiple`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
