import { httpApi } from '@app/api/http.api';
import { AddStatusToCompanyRequest, UpdateCompanyRequest } from '@app/store/slices/companySlice';

export interface Company {
  id?: string;
  name: string;
  city: string;
  state: string;
  country: string;
  address: string;
}

export interface CompaniesResponse {
  limit: number;
  page: number;
  results: [Company];
  totalPages: number;
  totalResults: number;
}

export interface QueryCompanyRequest {
  name?: string;
  role?: string;
  sortBy?: string;
  projectBy?: string;
  limit?: number;
  page?: number;
}

export const getCompanyDetails = async (companyId: string): Promise<Company> => {
  return httpApi
    .get<Company>(`companies/${companyId}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getCompaniesWithPagination = (querPayload: QueryCompanyRequest): Promise<CompaniesResponse> => {
  const { page, limit } = querPayload;

  return httpApi
    .get<CompaniesResponse>(`/companies/getCompaniesWithPagination?page=${page}&limit=${limit}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const getCompanyListWithQuery = async (querPayload: QueryCompanyRequest): Promise<Company[]> => {
  const { name } = querPayload;
  const query = {
    name: name ? `name=${name}` : '',
  };
  return httpApi
    .get<Company[]>(`companies/queryCompanies?${query.name}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const createCompany = (companyData: Company): Promise<undefined> => {
  return httpApi
    .post<undefined>('companies/createCompany', { ...companyData })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const updateCompany = (companyData: UpdateCompanyRequest): Promise<undefined> => {
  const { companyId, companyDetails } = companyData;
  return httpApi
    .patch<undefined>(`companies/${companyId}`, { ...companyDetails })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const addStatusToCompany = (payload: AddStatusToCompanyRequest): Promise<undefined> => {
  const { companyId, statusDetails } = payload;
  return httpApi
    .patch<undefined>(`companies/addStatus/${companyId}`, { ...statusDetails })
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
