import { httpApi } from '@app/api/http.api';
import { Participant, Tag } from '@app/components/apps/kanban/interfaces';
import { CardTypes } from '@app/constants/enums/kanbanCards';
import { UserModel } from '@app/domain/UserModel';
import {
  AddChildPocessOrTaskRequest,
  CreateProcessTasksRequest,
  DeleteProcessTasksRequest,
  UpdateProcessTasksRequest,
} from '@app/store/slices/processTaskSlice';

export interface CreateProcessResponse {
  id: string;
  title: string;
  description: string;
  project: string;
  tags: Tag[];
  tasks: string[] | null;
  assignedTo: UserModel;
  collaborators: Participant[];
  createdBy: string;
  status: string | null;
  type: string;
}

export const createProcessOrTask = async (
  processPayload: CreateProcessTasksRequest,
): Promise<CreateProcessResponse> => {
  const { type } = processPayload;
  const dataPayload = {
    title: processPayload.title,
    description: processPayload.description,
    project: processPayload.project,
    assignedTo: processPayload.assignedTo ? processPayload.assignedTo : null,
    collaborators: processPayload.collaborators,
    createdBy: processPayload.createdBy,
    status: processPayload.status,
  };
  const URL = type === CardTypes.TASK ? `tasks/createTask` : `process/createProcess`;
  return httpApi
    .post(`${URL}`, dataPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const updateProcessOrTask = async (
  processPayload: UpdateProcessTasksRequest,
): Promise<CreateProcessResponse> => {
  const { type, id, details } = processPayload;
  const dataPayload = { ...details };
  const URL = type === CardTypes.TASK ? `tasks/${id}` : `process/${id}`;
  return httpApi
    .patch(`${URL}`, dataPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const deleteProcessOrTask = async (payload: DeleteProcessTasksRequest): Promise<CreateProcessResponse> => {
  const { type, id, projectId } = payload;
  const URL = type === CardTypes.TASK ? `tasks/${id}/${projectId}` : `process/${id}/${projectId}`;
  return httpApi
    .delete(`${URL}`)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};

export const addChildTaskOrProcess = async (
  processPayload: AddChildPocessOrTaskRequest,
): Promise<CreateProcessResponse> => {
  const { parentType, details, taskOrProcessId } = processPayload;
  const dataPayload = { ...details };
  const URL =
    parentType === CardTypes.TASK ? `tasks/addChild/${taskOrProcessId}` : `process/addChild/${taskOrProcessId}`;
  return httpApi
    .patch(`${URL}`, dataPayload)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error.options);
    });
};
