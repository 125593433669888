import { getAllStatus, createStatus } from '@app/api/status.api';
import { PrepareAction, createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';

export interface CreateStatusRequest {
  title: string;
  description: string;
  priority?: number;
  relavantToTask?: boolean;
  relavantToProject?: boolean;
  relavantToProcess?: boolean;
  color?: string;
}

const initialState = {
  statusList: [],
};

export const setStatusList = createAction<PrepareAction<[]>>('status/setStatusList', (statusList) => {
  return {
    payload: statusList,
  };
});

export const doCreateStatus = createAsyncThunk('status/createStatus', async (statusPayload: CreateStatusRequest) =>
  createStatus(statusPayload),
);

export const doGetAllStatus = createAsyncThunk('status/getAllStatus', async (unknown, { dispatch }) =>
  getAllStatus().then((res) => {
    dispatch(setStatusList(res.results));
  }),
);

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setStatusList, (state, action) => {
      state.statusList = action.payload;
    });
  },
});

export default statusSlice.reducer;
