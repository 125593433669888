import React from 'react';
import { Input } from 'antd';
import * as S from '../SignUpForm.styles';
import { useTranslation } from 'react-i18next';
import { checkOnlyNumber } from '@app/utils/utils';
import { Option } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { defaultCountryDialCode } from '@app/constants/variables';
import { localDialCodeIsrael } from '@app/constants/countryList';
import { RoleTypes } from '@app/constants/enums/roleTypes';

export interface Step4Props {
  type: string;
}

export const Step4: React.FC<Step4Props> = ({ type }) => {
  const { t } = useTranslation();
  return (
    <S.FormCol>
      <S.FormTitleBlack>
        {type == RoleTypes.SELLER ? t('signup.stepForm.buyerInformation') : t('signup.stepForm.sellerInformation')}
      </S.FormTitleBlack>
      <S.FormDescription>{t('signup.stepForm.extraInfo')}</S.FormDescription>
      <S.FormConatiner>
        <S.FormItem
          name="inviteFirstName"
          label={
            type == RoleTypes.SELLER ? t('signup.stepForm.carBuyerFirstName') : t('signup.stepForm.carSellerFirstName')
          }
          rules={[{ required: true, message: t('forms.stepFormLabels.firstNameError') }]}
        >
          <Input placeholder={t('common.firstName')} />
        </S.FormItem>
        <S.FormItem
          name="inviteLastName"
          label={
            type == RoleTypes.SELLER ? t('signup.stepForm.carBuyerLastName') : t('signup.stepForm.carSellerLastName')
          }
          rules={[{ required: true, message: t('forms.stepFormLabels.lastNameError') }]}
        >
          <Input placeholder={t('common.lastName')} />
        </S.FormItem>
        <S.PhoneItem
          name="invitePhone"
          label={t('common.phone')}
          rules={[
            { required: true, message: t('forms.stepFormLabels.phoneError') },
            { pattern: new RegExp(checkOnlyNumber), message: t('common.shouldBeANumber') },
            { len: 7, message: t('common.phoneNumberLengthError', { length: 7 }) },
          ]}
        >
          <Input
            addonBefore={
              <BaseForm.Item name="invitePhoneprefix" noStyle>
                <S.Select defaultValue={defaultCountryDialCode}>
                  {localDialCodeIsrael?.map((item, index) => {
                    return (
                      <Option key={`${item.name}-${index}`} value={item.dial_code}>
                        <S.CountriesSpace align="center">{`${item.dial_code}`}</S.CountriesSpace>
                      </Option>
                    );
                  })}
                </S.Select>
              </BaseForm.Item>
            }
          />
        </S.PhoneItem>
        <S.FormItem
          name="inviteEmail"
          label={t('common.email')}
          rules={[
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Input placeholder={t('inputs.enterEmail')} />
        </S.FormItem>
      </S.FormConatiner>
    </S.FormCol>
  );
};
