import styled from 'styled-components';
import { Button } from 'antd';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
  alignItem?: string;
  marginTop?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  height: 100vh;
  padding: 16px;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  flex: 1;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};
  align-items: ${(props) => (props.alignItem ? props.alignItem : 'center')};
`;

export const BtnBlue = styled(Button)`
  border-radius: 50px;
  padding: 10px 20px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  max-width: 360px;

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;

export const Title = styled.div`
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 375px) {
    font-size: 20px;
  }
`;

export const Description = styled.div`
  color: black;
  font-size: 30px;
  font-weight: 800;

  @media (max-width: 375px) {
    font-size: 24px;
  }
`;

export const SmallDescription = styled.div`
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
`;

export const BtnBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
