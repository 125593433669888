import React from 'react';
import { Col, Row } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { RoutesMapping } from '@app/constants/enums/permission';

const InvitationLayout: React.FC = () => {
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const isInvitationList = location.pathname.split('/')[2] === RoutesMapping.listInvities;

  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/invities');

  return (
    <>
      <PageTitle></PageTitle>
      <Row gutter={[30, 30]}>
        {isMenuShown && (
          <Col xs={24} md={24} xl={isInvitationList ? 30 : 16}>
            <Outlet />
          </Col>
        )}
      </Row>
    </>
  );
};

export default InvitationLayout;
