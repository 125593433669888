import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import MoneyTransferred from '@app/components/moneyTransferred/MoneyTransferred';

const MoneyTransferredFlowPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <MoneyTransferred />
    </>
  );
};

export default MoneyTransferredFlowPage;
