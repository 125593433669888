import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoleTypes } from '@app/constants/enums/roleTypes';
import { capitalize } from '@app/utils/utils';
import { RequestOTPFrom } from '@app/constants/enums/requestOTPFrom';
import mailSentSuccess from 'assets/images/mail-sent-success.svg';
import submitSuccess from 'assets/images/submit-success.svg';
import globeIcon from 'assets/icons/globe-icon.svg';
import * as S from './Success.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Link } from 'react-router-dom';
import { fetchUser } from '@app/store/slices/userSlice';

const FundTransferSuccessScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [proceedToPayment, setProceedToPayment] = useState(false);
  const [requestOTPFrom, setRequestOTPFrom] = useState('');
  const [type, setType] = useState('');
  const [displayUser, setDisplayUser] = useState<{
    firstName?: string;
    lastName?: string;
    phoneprefix?: string;
    phone?: string;
  } | null>(null);

  const goToPaymentProcess = () => {
    if (user && user.lastProject && user.lastProject.id) {
      navigate(`/payment/service-fees-payment/${type}/${user.id}/${user.lastProject.id}`);
    }
  };

  useEffect(() => {
    if (user) {
      if (!user?.lastProject) dispatch(fetchUser());
      setType(user?.type as string);
      if (user?.lastProject) {
        const seller = user.lastProject.seller || [];
        const buyer = user.lastProject.buyer || [];
        if (seller.length > 0 && buyer.length > 0) {
          setProceedToPayment(true);
          setRequestOTPFrom(RequestOTPFrom.INVITATION);
        } else if (seller.length > 0 || buyer.length === 0 || buyer.length > 0 || seller.length === 0) {
          setRequestOTPFrom(RequestOTPFrom.LOGIN);
          setProceedToPayment(false);
        }
      } else {
        setProceedToPayment(false);
      }
      setDisplayUser({
        firstName: user?.lastProject?.invitation?.firstName,
        lastName: user?.lastProject?.invitation?.lastName,
        phoneprefix: user?.lastProject?.invitation?.phoneprefix,
        phone: user?.lastProject?.invitation?.phone,
      });
    }
  }, [dispatch, user]);

  return (
    <S.Wrapper disableBgImage={true}>
      <S.FlexBox>
        <S.HeaderOption>
          <S.SupportWrapper
            onClick={(e) => {
              window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}}`;
              e.preventDefault();
            }}
          >
            <S.GlobeIcon src={globeIcon} />
            {t('common.support')}
          </S.SupportWrapper>
        </S.HeaderOption>
        <S.BoxPart>
          <Image
            width={320}
            src={requestOTPFrom !== RequestOTPFrom.INVITATION ? mailSentSuccess : submitSuccess}
            preview={false}
          />
        </S.BoxPart>
        {requestOTPFrom !== RequestOTPFrom.INVITATION ? (
          <S.BoxPart justifyContent="center">
            <S.Description>{t('common.submitSuccess')}</S.Description>
            <S.DescriptionTwo paddingX={20}>
              {t('common.smsSentDesc', {
                name: displayUser?.firstName ? capitalize(`${displayUser?.firstName} ${displayUser?.lastName}`) : '',
                type: type === RoleTypes.SELLER ? t('project.buyer') : t('project.seller'),
                phone: `${displayUser?.phoneprefix} ${displayUser?.phone}`,
              })}
            </S.DescriptionTwo>
            <S.DescriptionTwo>
              {t('common.waitingText', {
                name: displayUser?.firstName ? capitalize(`${displayUser?.firstName} ${displayUser?.lastName}`) : '',
                type: type === RoleTypes.SELLER ? t('project.buyer') : t('project.seller'),
              })}
            </S.DescriptionTwo>
          </S.BoxPart>
        ) : (
          <S.BoxPart justifyContent="center">
            <S.Description>{t('common.submitSuccess')}</S.Description>
            <S.DescriptionTwo paddingX={20}>{t('common.submitSucccessDesc')}</S.DescriptionTwo>
          </S.BoxPart>
        )}
        {proceedToPayment && (
          <S.BtnBlue type="primary" onClick={goToPaymentProcess}>
            {t('payment.continuePayment')}
          </S.BtnBlue>
        )}
        {user && (
          <S.BtnBlue type="primary">
            <Link to="/logout">{t('header.logout')}</Link>
          </S.BtnBlue>
        )}
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default FundTransferSuccessScreen;
