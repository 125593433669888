import { CloseOutlined } from '@ant-design/icons';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { Button } from 'antd';
import styled from 'styled-components';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
  alignItem?: string;
  marginTop?: string;
}

interface DescriptionInterFace {
  paddingX?: number;
  paddingT?: number;
  paddingY?: number;
  fontSize?: number;
  textAlign?: string;
  color?: string;
  fontWeight?: string;
  isBorderDisable?: boolean;
  marginB?: number;
  marginT?: number;
}

export const StepMainWrapper = styled.div`
  width: 100%;
  position: fixed;
  height: 2.5rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  background: #f3f4f6;
  z-index: 999;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StepBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .finished .step-title,
  .active .step-title {
    color: var(--primary-color);
  }

  & .finished .step-title,
  .active .step-title,
  .active .step-number {
    color: var(--primary-color);
    font-weight: bold;
  }
`;

export const Step = styled.div`
  flex: 1;
  white-space: nowrap;
  padding: 0 20px;
`;

export const StepDivide = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  color: var(--lightgrey);
`;

export const StepTitle = styled.span`
  font-weight: 500;
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 40px 20px 20px;
  flex-direction: column;
  overflow-y: auto;
`;

export const FormBox = styled.div`
  width: 100%;
  height: 100%;

  & .ant-form {
    width: 100%;
  }

  .account-details {
    margin-top: 16px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 0;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .paymenTop {
    margin-top: 15px;
  }
`;

export const HeaderOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 15px 0;
  align-items: center;
`;

export const GlobeIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  src: url(${(props) => props.src});
`;

export const SupportWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  color: var(--primary-color);
  cursor: pointer;
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '0')};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};
  align-items: ${(props) => (props.alignItem ? props.alignItem : 'center')};

  &.topImg {
    padding-bottom: 15px;
  }
  &.proceed-button {
    margin-top: auto;
    margin-bottom: 20px;
  }
`;

export const Label = styled.label`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: left;
`;

export const Description = styled.div<DescriptionInterFace>`
  color: black;
  font-size: ${(props) => `${props.fontSize}px`};
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  font-weight: ${(props) => `${props.fontWeight}`};
  padding-top: ${(props) => `${props.paddingT ? props.paddingT : '0'}px`};
  margin-bottom: ${(props) => `${props.marginB ? props.marginB : '0'}px`};
  margin-top: ${(props) => `${props.marginT ? props.marginT : '0'}px`};
`;

export const DescriptionTwo = styled.div<DescriptionInterFace>`
  color: ${(props) => (props.color ? props.color : '#6b7280')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => `${props.fontSize}px`};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  padding: ${(props) => `${props.paddingY ? props.paddingY : '0'}px ${props.paddingX ? props.paddingX : '0'}px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  border-bottom: ${(props) => (props.isBorderDisable ? 'none' : '1px solid #E5E7EB')};

  span {
    font-weight: 400;
  }

  .values {
    color: black;
    font-weight: bold;
    font-size: 12px;
  }

  .title {
    width: 100%;
  }
`;

export const BtnBlue = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  max-width: 360px;
  margin: 1.5rem 0 0;
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    padding-left: 10px;
  }

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;

export const OfferListWrapper = styled.div`
  width: 100%;
`;

export const Notes = styled.h2`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 20px;
  color: var(--text-light-primary-color);
  &.check {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`;

export const BtnPDFDownload = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: none;
  background: var(--text-secondary-color);
  color: var(--primary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  max-width: 360px;
  margin: 1.5rem 0 0;
  box-shadow: unset;
  display: flex;
  align-items: center;

  :active,
  :focus,
  :hover {
    background: var(--text-secondary-color) !important;
    border: none !important;
    color: var(--primary-color) !important;
  }
`;

export const DownloadPDF = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  src: url(${(props) => props.src});
  margin-right: 10px;
`;

export const ContainWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Remove = styled(CloseOutlined)`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.xxxl};
  cursor: pointer;
`;

export const OfferListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
`;

export const OfferListItemText = styled.div`
  padding-left: 10px;
`;

export const FormItem = styled(BaseForm.Item)`
  margin-top: 1.75rem;
  color: black;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: black !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: bold;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  & .ant-form-item-optional {
    display: none !important;
  }

  & .ant-input {
    width: 100%;
    background: var(--text-secondary-color) !important;
    color: black;
    padding: 8px 8px;
    outline: none;
    height: 120px;
  }

  & .ant-input::placeholder {
    color: #00000050;
  }
`;
