import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Row, Col, Space } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Select as CommonSelect } from '@app/components/common/selects/Select/Select';
import { LeftOutlined } from '@ant-design/icons';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
}

interface StepNumberInterFace {
  finished?: boolean;
}

interface AddMoreInterface {
  enable?: boolean;
}

interface NoteWrapperInterface {
  justifyContent?: string;
}

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
  flex-direction: column;
`;

export const FormBox = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  & .ant-form {
    width: 100%;
  }
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  gap: ${(props) => {
    const gap = props.gap?.toString();
    return `${gap ? gap : 20}px`;
  }};
  align-items: center;
  position: fixed;
  bottom: 2rem;
  margin: auto;
`;

export const FormConatiner = styled.div<BoxPartInterFace>`
  margin-top: 1.5rem;

  & .ant-form-item-label {
    padding: 0;
  }
`;

export const FormRow = styled(Row)`
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
`;

export const FormCol = styled(Col)`
  width: 100%;
  margin-top: 10px;
`;

export const FormItem = styled(BaseForm.Item)`
  color: black;
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: black !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: bold;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  & .ant-form-item-optional {
    display: none !important;
  }

  & .ant-input {
    width: 100%;
    background: var(--lightGreyShadow) !important;
    color: black;
    padding: 8px 8px;
    outline: none;
  }

  & .ant-input::placeholder {
    color: #00000050;
  }
`;

export const FormTitle = styled.span`
  color: var(--text-secondary-color);
  font-size: 3rem;
`;

export const FormTitleBlack = styled.div`
  color: var(--black);
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.bold};
  margin-top: 1.8rem;
`;

export const FormDescription = styled.div`
  color: var(--subtext-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
`;

export const Description = styled.span`
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const DescriptionTwo = styled.span`
  color: rgb(185, 178, 178);
  font-size: 16px;
  text-align: center;

  .span {
    font-weight: bold;
  }
`;

export const BtnWhite = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  background: var(--text-secondary-color);
  color: black;
  outline: none;
  font-size: 1rem;
  border: 0px solid var(--text-secondary-color);
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0 2px 0px;
  max-width: 360px;

  :active,
  :focus,
  :hover {
    background: var(--text-secondary-color) !important;
    color: black !important;
    border: 0px solid white !important;
    box-shadow: 0 0 2px 0px !important;
  }
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexBtn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const FlexBtnCenter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const BtnText = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const BtnBlue = styled(Button)`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid var(--text-secondary-color);
  background: var(--primary-color);
  color: var(--text-secondary-color);
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  max-width: 360px;

  &.userToDeal {
    margin-top: 1rem;
  }

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid var(--text-secondary-color) !important;
    color: var(--text-secondary-color) !important;
  }
`;

export const FooterText = styled.p`
  color: var(--text-secondary-color);
  font-size: 0.7rem;
`;

export const FooterTextHightlight = styled.span`
  border-bottom: 1px solid var(--text-secondary-color);
  cursor: pointer;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  max-width: 720px;
  overflow: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: var(--text-secondary-color);
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #339cfd;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #878787;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--text-secondary-color);
  }
`;

export const StepContainer = styled.div`
  color: #878787;
  font-size: 0.875rem;
  display: flex;
  width: 100%;
  max-width: 400px;
`;

export const StepBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .finished .step-title,
  .active .step-title {
    color: var(--primary-color);
  }

  & .finished .step-title,
  .active .step-title,
  .active .step-number {
    color: var(--primary-color);
    font-weight: bold;
  }
`;

export const Step = styled.div`
  flex: 1;
  white-space: nowrap;
  padding: 0 20px;
`;

export const StepDivide = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  color: var(--lightgrey);
`;

export const StepTitle = styled.span`
  font-weight: 500;
`;

export const StepNumber = styled.span<StepNumberInterFace>`
  border-radius: 50%;
  padding: 0.01rem 0.35rem;
  margin-right: 4px;
  background: ${(props) => (props.finished ? 'var(--primary-color)' : 'var(--text-secondary-color)')};
  color: ${(props) => (props.finished ? 'var(--text-secondary-color)' : '#878787')};
  border-color: ${(props) => (props.finished ? 'var(--primary-color)' : 'black')};
`;

export const PhoneItem = styled(BaseForm.Item)`
  .ant-input-group-addon {
    padding: 0;
    width: 6rem !important;

    .ant-select-rtl {
      .ant-select-selection-item {
        direction: ltr;
      }
    }
  }

  .ant-input-group-addon > div {
    width: 100% !important;
  }

  .ant-input-group-addon > div > div {
    padding: 0px !important;
  }

  .ant-input-group-addon > div > span.ant-select-arrow {
    right: 12px;
    bottom: 20px;
    color: black;
  }

  .ant-select-rtl > .ant-select-selector > .ant-select-selection-item {
    padding-right: 30px;
  }

  .ant-select {
    color: black !important;
    border: none !important;
    outline: none !important;
  }

  .ant-input {
    width: 100%;
    background: var(--text-secondary-color) !important;
    color: black;
    padding: 8px 8px;
    outline: none;
  }

  .ant-input::placeholder {
    color: #00000050;
  }

  label {
    color: black !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: 500;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-input-group-addon .ant-select-focused .ant-select-selector,
  .ant-input-group-addon .ant-select-open .ant-select-selector {
    color: black !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-input-disabled {
    background: var(--disabled-bg-color) !important;
    color: var(--disabled-color);
  }
`;

export const Select = styled(CommonSelect)`
  width: 5.9375rem;
`;

export const CustomeSelect = styled(CommonSelect)`
  .ant-select-selector {
    height: 42px !important;
  }

  .ant-select-selection-item {
    line-height: 42px !important;
  }
`;

export const TakePhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  :last-child {
    margin-top: 2rem;
  }
`;

export const Label = styled.label`
  color: black;
  font-size: ${FONT_SIZE.xs};
  line-height: 1.25rem;
  font-weight: ${FONT_WEIGHT.bold};
`;

export const Note = styled.p`
  color: var(--subtext-color);
  font-size: ${FONT_SIZE.md};
  line-height: 1.25rem;
  font-weight: 500;
  margin-bottom: 0;
`;

export const UploadFile = styled.p`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
`;

export const NoteWrapper = styled.div<NoteWrapperInterface>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
`;

export const CaptureImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  background: #000;

  .webcamWrapper {
    height: 100vh;
    padding: 0;
    position: relative;
  }

  .captureButton {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #fff;
  }

  .anticon-camera {
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 70px;
    position: absolute;
    border: 3px solid #fff;
    svg {
      display: none;
    }
    + span {
      display: none;
    }
  }

  .buttonWrapper {
    position: absolute;
  }
  .switchCamButton,
  .retakeButton {
    right: 20px;
    left: auto;
    position: fixed;
    bottom: 60px;
    background: transparent;
    border: 0;
    padding: 0;
    svg {
      font-size: 40px;
      color: #fff;
    }
    .anticon-sync,
    .anticon-reload {
      + span {
        display: none;
      }
    }
  }

  .addUploadButton {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    border-radius: 60px;
    font-size: 40px;
    background: transparent;
    border: 0;
  }

  .anticon-plus {
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 70px;
    position: absolute;
    border: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #fff;
    }
    + span {
      display: none;
    }
  }
`;

export const CountriesSpace = styled(Space)`
  img {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50%;
  }

  & > .ant-space-item:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-primary-color);
    font-size: ${FONT_SIZE.md};
    line-height: 1.25rem;
    font-weight: 500;
    padding-top: 2px;
  }
`;

export const AddMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
`;

export const AddMoreUser = styled.button<AddMoreInterface>`
  font-size: ${FONT_SIZE.md};
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background: none;
  color: ${(props) => (props.enable ? 'var(--primary-color)' : 'var(--gray)')};
`;

export const StepMainWrapper = styled.div`
  width: 100%;
  position: fixed;
  height: 2.5rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  background: #f3f4f6;
  z-index: 999;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderOption = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 1.5rem;
  justify-content: space-between;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: var(--primary-color);
  cursor: pointer;
`;

export const GlobeIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  src: url(${(props) => props.src});
`;

export const VerifyButton = styled(Button)`
  border-radius: 50px;
  background: var(--primary-color);
  color: var(--white);
  outline: none;
  font-size: ${FONT_SIZE.md};
  border: 0px solid var(--text-secondary-color);
  cursor: pointer;
  font-weight: ${FONT_WEIGHT.semibold};
  box-shadow: 0 0 2px 0px;
  max-width: 360px;

  .ant-btn:active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    color: var(--white) !important;
    border: 0px solid var(--text-secondary-color) !important;
    box-shadow: 0 0 2px 0px !important;
  }
`;

export const VerifyIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  src: url(${(props) => props.src});
`;

export const CarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: var(--primary-color);

  .ant-input-focused,
  .ant-input:focus {
    outline: none;
    border-color: var(--green) !important;
    box-shadow: 0 0 0 2px var(--ant-primary-color-outline);
    border-right-width: 1px;
    background-color: var(--greenshadow) !important;
  }
`;

export const VerifySuccess = styled.p`
  color: var(--green);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const Strong = styled.span`
  font-weight: ${FONT_WEIGHT.bold};
`;

export const Subtitle = styled.p`
  color: var(--black);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const MoreSellerWarpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const SellerIcon = styled.img`
  width: ${(props) => (props.width ? props.width : '20')}px;
  height: ${(props) => (props.height ? props.height : '20')}px;
  cursor: pointer;
  src: url(${(props) => props.src});
`;

export const DeleteIcon = styled.img`
  width: ${(props) => (props.width ? props.width : '20')}px;
  height: ${(props) => (props.height ? props.height : '20')}px;
  cursor: pointer;
  src: url(${(props) => props.src});
`;

export const SellerDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: var(--primary-color);
  padding-left: 0.8rem;
`;

export const SellerName = styled.p`
  color: var(--black);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-bottom: 0;
  width: 10rem;
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
`;

export const SellerPhone = styled.p`
  color: var(--lightgrey);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-bottom: 0;
  width: 10rem;
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.button`
  color: var(--red);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin-bottom: 0;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HeaderWarpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
