import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifySecurityCode } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './SecurityCodeForm.styles';
import { SendOtpHandler } from '@app/controllers/sendOtpController';
import redirectImage from '@app/assets/images/redirecting.svg';
import { RoleTypes } from '@app/constants/enums/roleTypes';
import { ANY_OBJECT } from '@app/interfaces/interfaces';
import { RequestOTPFrom } from '@app/constants/enums/requestOTPFrom';

interface SecurityCodeFormProps {
  onBack?: () => void;
  state: ANY_OBJECT;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({ onBack, state }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const [securityCode, setSecurityCode] = useState('');
  const OTPLength = Number(process.env.REACT_APP_OTP_CODE_LENGTH);
  const [loginLoader, setLoginLoader] = useState(false);
  const OTP_phone_number = state.phoneprefix ? `${state.phoneprefix}${state.phone}` : `${state.phone}`;

  const setLoadingEnable = () => {
    return setLoginLoader(true);
  };
  const setLoadingDisable = () => {
    return setLoginLoader(false);
  };

  const resendOTP = () => {
    SendOtpHandler(
      {
        firstname: state.firstname as string,
        lastname: state.lastname as string,
        phone: state.phone as string,
        phoneprefix: state.phoneprefix as string,
        userType: state.type,
      },
      dispatch,
      navigate,
      t,
      setLoadingEnable,
      setLoadingDisable,
      state.requestOTPFrom,
    );
  };

  useEffect(() => {
    if (securityCode.length === OTPLength) {
      setLoading(true);
      dispatch(
        doVerifySecurityCode({
          otp: securityCode,
          phone: state?.phone,
          phoneprefix: state.phoneprefix,
          requestOTPFrom: state.requestOTPFrom,
        }),
      )
        .unwrap()
        .then(async () => {
          await new Promise((resolve) =>
            setTimeout(() => {
              resolve(setLoading(false));
            }, 2000),
          );

          if (state?.requestOTPFrom === RequestOTPFrom.SIGNUP || state?.requestOTPFrom === RequestOTPFrom.INVITATION) {
            state.type === RoleTypes.BUYER ? navigate(`/pay-attention`) : navigate(`/sign-up`);
          } else {
            navigate(`/`);
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [OTPLength, dispatch, navigate, securityCode, state?.phone, state.phoneprefix, state.requestOTPFrom, state.type]);

  return (
    <S.Wrapper>
      {!isLoading ? (
        <S.FlexBox>
          <BaseForm layout="vertical" requiredMark="optional" style={{ flex: 1 }}>
            <S.HeaderOption>
              <S.BackWrapper onClick={onBack || navigateBack}>
                <S.BackIcon />
                {t('common.back')}
              </S.BackWrapper>
              <S.ResentOTP onClick={resendOTP}>{t('securityCodeForm.resendOTP')}</S.ResentOTP>
            </S.HeaderOption>
            <S.ContentWrapper>
              <S.FormTitle>{t('securityCodeForm.title')}</S.FormTitle>
              <S.VerifyDescription className="security-text">
                {t('securityCodeForm.verifCodeSent')} <S.Strong>{`${OTP_phone_number}`}</S.Strong>
              </S.VerifyDescription>
              <S.VerifyDescription className="security-text">{t('securityCodeForm.enterTheCode')}</S.VerifyDescription>
              {loginLoader ? (
                <Spin />
              ) : (
                <S.OTPInput>
                  <VerificationCodeInput autoFocus onChange={setSecurityCode} length={OTPLength} />
                </S.OTPInput>
              )}
              <S.LinkText>{t('securityCodeForm.note')}</S.LinkText>
            </S.ContentWrapper>
          </BaseForm>
          <S.BtnBlue>
            <S.FlexBtnCenter>
              <S.BtnText>{t('securityCodeForm.verifyPhoneNumber')}</S.BtnText>
            </S.FlexBtnCenter>
          </S.BtnBlue>
        </S.FlexBox>
      ) : (
        <S.FlexBox>
          <Image width={360} src={redirectImage} preview={false} />
          <Spin size="large" />
          <S.FormTitle>{t('securityCodeForm.success')}</S.FormTitle>
          <S.VerifyDescription>{t('securityCodeForm.redirecting')}</S.VerifyDescription>
        </S.FlexBox>
      )}
    </S.Wrapper>
  );
};
