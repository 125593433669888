import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { FONT_WEIGHT } from '@app/styles/themes/constants';

interface BtnBlueInterFace {
  marginTop?: number;
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  max-width: 360px;
  margin: 40px auto 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 30px;
  font-weight: ${FONT_WEIGHT.extraBold};
  color: var(--text-primary-color);
`;

export const Notes = styled.h2`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 20px;
  color: var(--text-light-primary-color);
  &.privacy-terms-check {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Points = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--text-light-primary-color);
  margin: 0 0 12px;
  span {
    color: #111928;
  }
`;

export const CustomeCheckbox = styled(Checkbox)`
  margin: 0px !important;
`;

export const BtnBlue = styled(Button)<BtnBlueInterFace>`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid white;
  background: var(--primary-color);
  color: white;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  max-width: 360px;
  margin: ${(props) => `${props.marginTop ? `${props.marginTop}px auto` : '0px auto'}`};

  :active,
  :focus,
  :hover {
    /* background: var(--primary-color) !important;
    border: 2px solid white !important;
    color: white !important; */
  }
`;

export const TermsAndCondWrapper = styled.div`
  width: 100%;
`;

export const TermsAndCondContentWrapper = styled.div`
  height: calc(100vh - 15rem);
  z-index: 99;
  overflow: auto;
`;
