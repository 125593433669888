import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import NeedHelpScreen from '@app/components/contact/NeedHelpScreen';

const NeedHelpPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <NeedHelpScreen />
    </>
  );
};

export default NeedHelpPage;
