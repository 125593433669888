import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Space } from 'antd';
import { Select as CommonSelect } from '@app/components/common/selects/Select/Select';
import { Input as CommonInput } from '@app/components/common/inputs/Input/Input';

export const LoginDescription = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-secondary-color);
  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;

export const RememberMeText = styled.span`
  color: var(--text-secondary-color);
  font-size: ${FONT_SIZE.xs};
`;

export const ForgotPasswordText = styled.span`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.xs};
  text-decoration: underline;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 1.875rem;
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  align-items: center;
`;

export const BaseFormBox = styled(BaseForm)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Select = styled(CommonSelect)`
  width: 5.9375rem;
`;

export const CountriesSpace = styled(Space)`
  gap: 2px !important;
  img {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50%;
  }

  & > .ant-space-item:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--black);
    font-size: ${FONT_SIZE.md};
    line-height: 1.25rem;
    font-weight: 500;
    padding-top: 2px;
  }
`;

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }

  .ant-input-group-addon:first-child {
    background: #f2f6fa !important;
  }
`;

export const PhoneItem = styled(BaseForm.Item)`
  .ant-input-group-addon {
    padding: 0;
    width: 6rem !important;

    .ant-select-rtl {
      .ant-select-selection-item {
        direction: ltr;
      }
    }
  }

  .ant-input-group-addon > div {
    width: 100% !important;
  }

  .ant-input-group-addon > div > div {
    padding: 0px !important;
  }

  .ant-input-group-addon > div > span.ant-select-arrow {
    right: 12px;
    bottom: 16px;
    color: black;
  }

  .ant-select-rtl > .ant-select-selector > .ant-select-selection-item {
    padding-right: 30px;
  }

  .ant-select {
    color: black !important;
    border: none !important;
    outline: none !important;
  }

  .ant-input {
    width: 100%;
    background: var(--text-secondary-color) !important;
    color: black;
    padding: 8px 8px;
    outline: none;
  }

  .ant-input::placeholder {
    color: #00000050;
  }

  label {
    color: white !important;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    font-weight: 500;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-input-group-addon .ant-select-focused .ant-select-selector,
  .ant-input-group-addon .ant-select-open .ant-select-selector {
    color: black !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-input-disabled {
    background: var(--disabled-bg-color) !important;
    color: var(--disabled-color);
  }
`;
