import React, { useState } from 'react';
import * as S from './Authorization.styles';
import { Image } from 'antd';
import carRental from 'assets/images/car-rental.svg';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { defaultCountryDialCode } from '@app/constants/variables';
import { useTranslation } from 'react-i18next';
import { mergeBy, checkOnlyNumber } from '@app/utils/utils';
import { localDialCodeIsrael } from '@app/constants/countryList';
import { Option } from '@app/components/common/selects/Select/Select';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { doSignUp } from '@app/store/slices/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { SendOtpHandler } from '@app/controllers/sendOtpController';

interface Fields {
  name: string;
  value: string | number;
}

interface FormValues {
  [key: string]: string | number;
}

interface SignUpPayload {
  firstname: string;
  lastname: string;
  phone: string;
  phoneprefix: string;
  role: string;
  company: string;
}

const formFileds = [
  { name: 'firstname', value: '' },
  { name: 'lastname', value: '' },
  { name: 'phone', value: '' },
  { name: 'phoneprefix', value: defaultCountryDialCode },
];

const Authorization: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [fields, setFields] = useState<Fields[]>(formFileds);
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseForm.useForm();

  const defaultCompany = process.env.REACT_APP_DEFAULT_COMPANY;
  const defaultRoleId = process.env.REACT_APP_DEFAULT_ROLE;

  const setLoadingEnable = () => {
    setLoading(true);
  };

  const setLoadingDisable = () => {
    setLoading(false);
  };

  const formValues: FormValues = fields
    .filter((item: Fields) => item.name !== 'prefix')
    .reduce((acc: FormValues, item: Fields) => {
      acc[item.name] = item.value;
      return acc;
    }, {} as FormValues);

  const registerProfile = () => {
    form.validateFields().then(() => {
      setLoading(true);
      if (!formValues?.firstname || !formValues?.lastname || !formValues?.phone || !formValues?.phoneprefix) {
        notificationController.error({ message: t('common.pleaseFillAllTheFields') });
        setLoading(false);
        return;
      }
    });
    if (defaultRoleId && defaultCompany) {
      const payload = {
        firstname: formValues?.firstname,
        lastname: formValues?.lastname,
        phone: formValues?.phone,
        phoneprefix: formValues?.phoneprefix,
        role: defaultRoleId,
        company: defaultCompany,
        type: params.type,
      };
      dispatch(doSignUp(payload as SignUpPayload))
        .unwrap()
        .then(() => {
          SendOtpHandler(
            {
              firstname: formValues.firstname as string,
              lastname: formValues.lastname as string,
              phone: formValues.phone as string,
              phoneprefix: formValues.phoneprefix as string,
              userType: params.type,
            },
            dispatch,
            navigate,
            t,
            setLoadingEnable,
            setLoadingDisable,
            'signup',
          );
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };
  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart>
          <Image src={carRental} preview={false} />
        </S.BoxPart>
        <S.BoxPart className="boxPartMiddle" gap={0} justifyContent="flex-start">
          <BaseForm
            name="authForm"
            form={form}
            fields={fields}
            onFieldsChange={(_, allFields) => {
              const currentFields = allFields.map((item) => ({
                name: Array.isArray(item.name) ? item.name[0] : '',
                value: item.value,
              }));
              const uniqueData = mergeBy(fields, currentFields, 'name');
              setFields(uniqueData);
            }}
          >
            <S.Description>{t('signup.authorization')}</S.Description>
            <S.FormConatiner>
              <S.FormItem
                name="firstname"
                label={t('common.firstName')}
                rules={[{ required: true, message: t('forms.stepFormLabels.firstNameError') }]}
              >
                <Input placeholder={t('common.firstName')} />
              </S.FormItem>
              <S.FormItem
                name="lastname"
                label={t('common.lastName')}
                rules={[{ required: true, message: t('forms.stepFormLabels.lastNameError') }]}
              >
                <Input placeholder={t('common.lastName')} />
              </S.FormItem>
              <S.PhoneItem
                name="phone"
                label={t('common.phone')}
                rules={[
                  { required: true, message: t('forms.stepFormLabels.phoneError') },
                  { pattern: new RegExp(checkOnlyNumber), message: t('common.shouldBeANumber') },
                  { len: 7, message: t('common.phoneNumberLengthError', { length: 7 }) },
                ]}
              >
                <Input
                  addonBefore={
                    <BaseForm.Item name="phoneprefix" noStyle>
                      <S.Select defaultValue={defaultCountryDialCode}>
                        {localDialCodeIsrael?.map((item, index) => {
                          return (
                            <Option key={`${item.name}-${index}`} value={item.dial_code}>
                              <S.CountriesSpace align="center">{`${item.dial_code}`}</S.CountriesSpace>
                            </Option>
                          );
                        })}
                      </S.Select>
                    </BaseForm.Item>
                  }
                />
              </S.PhoneItem>
            </S.FormConatiner>
          </BaseForm>
        </S.BoxPart>
        <S.BoxPart justifyContent="flex-end">
          <S.BtnBlue onClick={registerProfile} loading={isLoading}>
            <S.FlexBtnCenter>
              <S.BtnText>{t('signup.registerProfile')}</S.BtnText>
            </S.FlexBtnCenter>
          </S.BtnBlue>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default Authorization;
