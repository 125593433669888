import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface PageTitleProps {
  title?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{`${title ? `${t(title)} | ` : ''}${t('meta.titleCar')}`}</title>
    </Helmet>
  );
};
