import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import NotFound404Screen from '@app/components/errors/NotFound404Screen';

const NotFound404Page: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <NotFound404Screen />
    </>
  );
};

export default NotFound404Page;
