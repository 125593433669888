import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../common/Loading';
import { Image } from 'antd';
import rightArrow from '@app/assets/icons/right-arrow-vector.svg';
import * as S from './MoneyTransferred.styles';
import MoneyTransferredRecipe from './MoneyTransferredRecipe';

const MoneyTransferred: React.FC = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState(0);

  const steps = useMemo(() => {
    return [
      {
        title: t('moneyTransferred.waitForMoneyTransfer'),
      },
      {
        title: t('moneyTransferred.done'),
      },
    ];
  }, [t]);

  const handleStatus = useCallback(() => {
    setTimeout(() => {
      setCurrentView(currentView + 1);
    }, 3000);
  }, [currentView]);

  const next = useCallback(() => {
    switch (currentView) {
      case 2:
        handleStatus();
        break;
      default:
        setCurrentView(currentView + 1);
    }
  }, [currentView, handleStatus]);

  const formFieldsUi = useMemo(() => {
    return [<MoneyTransferredRecipe key="0" onClick={next} />];
  }, [next]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <S.StepMainWrapper>
        {steps &&
          steps.map((item, index) => {
            const active = 0 === index;
            const finished = 0 > index;
            const last = steps.length - 1 === index;
            return (
              <S.StepBox key={index}>
                <S.Step title={item.title} className={finished ? 'finished' : active ? 'active' : 'pending'}>
                  <S.StepTitle className="step-title">{item.title}</S.StepTitle>
                </S.Step>
                {!last && (
                  <S.StepDivide>
                    <Image src={rightArrow} alt="right arrow" width={10} height={10} />
                  </S.StepDivide>
                )}
              </S.StepBox>
            );
          })}
      </S.StepMainWrapper>
      <S.FormWrapper>
        <S.FormBox>{formFieldsUi[currentView]}</S.FormBox>
      </S.FormWrapper>
    </>
  );
};
export default MoneyTransferred;
