import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import InternalErrorScreen from '@app/components/errors/InternalErrorScreen';

const InternalErrorPage: React.FC = () => {
  return (
    <>
      <PageTitle></PageTitle>
      <InternalErrorScreen />
    </>
  );
};

export default InternalErrorPage;
