import React from 'react';
import * as S from './contact.styles';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ContactInfoScreen: React.FC = () => {
  const { t } = useTranslation();
  const faEnvelopeIcon = <FontAwesomeIcon icon={faEnvelope} style={{ width: '48px', height: '48px' }} />;
  const faPhoneIcon = <FontAwesomeIcon icon={faPhone} style={{ width: '48px', height: '48px' }} />;
  return (
    <S.Wrapper>
      <S.FlexBox>
        <S.BoxPart>
          <S.IconBox>{faEnvelopeIcon}</S.IconBox>
          <S.Title>{`${t('contact.emailUsTitle')}:`}</S.Title>
          <S.Description>{`${t('contact.emailUsDescription')} `}</S.Description>
          <S.SmallDescription>{t('contact.ourEmail')}</S.SmallDescription>
        </S.BoxPart>
        <S.BoxPart>
          <S.IconBox>{faPhoneIcon}</S.IconBox>
          <S.Title>{`${t('contact.callUsTitle')}:`}</S.Title>
          <S.Description>{t('contact.callUsDescription')}</S.Description>
          <S.SmallDescription>{t('contact.ourPhone')}</S.SmallDescription>
        </S.BoxPart>
      </S.FlexBox>
    </S.Wrapper>
  );
};

export default ContactInfoScreen;
