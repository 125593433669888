import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AddProjectForm.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Button, DatePicker, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { AddProjectDataProps } from '../AddProjectModal';
import styled from 'styled-components';
import { UserModel } from '@app/domain/UserModel';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import StubAvatar from '@app/assets/images/stub-avatar.webp';
import { capitalize } from '@app/utils/utils';

const Picker = styled(DatePicker)`
  width: 100%;
`;

export interface Project {
  title: string;
  description: string;
  createdBy: string;
  company: string;
  dueDate: string;
  responsible: UserModel[];
}

export const AddProjectForm: React.FC<AddProjectDataProps> = ({ loading, responsibleList, onFinish }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  const selectOptions = responsibleList.map((participant) => (
    <Option key={participant.id} value={participant.id} name={participant.firstname}>
      <S.ParticipantRow>
        <S.ParticipantAvatar src={participant?.image ? participant.image.signedURL : StubAvatar} />
        <S.ParticipantName>{`${capitalize(participant.firstname)} ${capitalize(
          participant.lastname,
        )}`}</S.ParticipantName>
      </S.ParticipantRow>
    </Option>
  ));

  return (
    <BaseForm form={form} name="addProject" onFinish={onFinish} requiredMark="optional" validateTrigger="onBlur">
      <S.ContentWrapper>
        <S.BlockWrapper>
          <S.Label> {t('common.project')}</S.Label>
          <Auth.FormItem
            name="title"
            label={t('project.title')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Input placeholder={t('project.title')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="description"
            label={t('project.description')}
            hasFeedback
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <TextArea placeholder={t('project.description')} />
          </Auth.FormItem>
          <BaseForm.Item
            name="dueDate"
            label={t('project.dueDate')}
            rules={[{ required: true, message: t('project.dueDateError') }]}
          >
            <Picker format="YYYY-MM-DD" />
          </BaseForm.Item>
          <BaseForm.Item
            name="responsible"
            label={t('project.addResponsible')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Select
              showSearch
              filterOption={(input, option) => option?.name.toLowerCase().includes(input.toLowerCase())}
              placeholder={t('project.addResponsible')}
            >
              {selectOptions}
            </Select>
          </BaseForm.Item>
        </S.BlockWrapper>
        <BaseForm.Item>
          <Button type="primary" htmlType="submit" block disabled={loading}>
            {t('common.addProject')}
          </Button>
        </BaseForm.Item>
      </S.ContentWrapper>
    </BaseForm>
  );
};
