import React, { useEffect, useState } from 'react';
import { SecurityCodeForm } from '@app/components/signUp/SecurityCodeForm/SecurityCodeForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { cryptoDecrypt } from '@app/controllers/rememberMeController';
import { ANY_OBJECT } from '@app/interfaces/interfaces';
import { getDataObjectWithKey } from '@app/services/localStorage.service';
import { LocalStorageKeys } from '@app/constants/enums/localStorageKeys';

const SecurityCodePage: React.FC = () => {
  const [state, setState] = useState<ANY_OBJECT>({});

  useEffect(() => {
    const userStatesDetailsEncrypted = getDataObjectWithKey(LocalStorageKeys.USERSTATE);
    const userStatesDetails = userStatesDetailsEncrypted && cryptoDecrypt(userStatesDetailsEncrypted);
    if (userStatesDetails) setState(userStatesDetails);
  }, []);

  return (
    <>
      <PageTitle></PageTitle>
      <SecurityCodeForm state={state} />
    </>
  );
};

export default SecurityCodePage;
