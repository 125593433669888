import React from 'react';
import { Input, Space, Button, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './SignUpForm.styles';
import { Option } from '@app/components/common/selects/Select/Select';
import globeIcon from 'assets/icons/globe-icon.svg';
import { checkOnlyNumber, mergeBy } from '@app/utils/utils';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { defaultCountryDialCode } from '@app/constants/variables';
import { localDialCodeIsrael } from '@app/constants/countryList';
import { Fields, FormValues, UserFormData, formMoreUserFileds } from './SignUpAddDetailsPageForm';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useParams } from 'react-router-dom';
import { RoleTypes } from '@app/constants/enums/roleTypes';
import { Fileprops } from '@app/interfaces/interfaces';
import { Link } from 'react-router-dom';

export interface AddMoreUserProps {
  closeAddMore: () => void;
  enableCamera: (imageType: string) => void;
  addMoreUser: (userDetails: UserFormData) => void;
  setMoreUserFields: (fields: Fields[]) => void;
  moreUserFormValues: FormValues;
  fieldsMoreUser: Fields[];
  addCaptureImage: (image: File, base64: string) => void;
  setImageSide: (imageSide: string) => void;
}

export const AddMoreUser: React.FC<AddMoreUserProps> = ({
  closeAddMore,
  enableCamera,
  addMoreUser,
  moreUserFormValues,
  setMoreUserFields,
  fieldsMoreUser,
  addCaptureImage,
  setImageSide,
}) => {
  const { t } = useTranslation();
  const { type } = useParams();
  const [formUser] = BaseForm.useForm();

  const onAddSecondUser = async (values: UserFormData) => {
    const userDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      phoneprefix: values.phoneprefix ? values.phoneprefix : defaultCountryDialCode,
      moreUserFrontImageFile: moreUserFormValues.moreUserFrontImageFile as UploadFile,
      moreUserBackImageFile: moreUserFormValues.moreUserBackImageFile as UploadFile,
    };
    setMoreUserFields(formMoreUserFileds);
    addMoreUser(userDetails as UserFormData);
    formUser.resetFields();
  };

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    method: 'POST',
    accept: 'image/*',
    beforeUpload: (file) => {
      const isImage = file.type === 'image/*';
      return isImage;
    },
    onChange: (info) => {
      fileReaderHandler(info.file);
    },
  };

  const fileReaderHandler = (file: Fileprops) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      addCaptureImage(file as File, event.target?.result as string);
    };
    reader.readAsDataURL(file as Blob);
  };

  return (
    <>
      <BaseForm
        form={formUser}
        name="addMoreUser"
        onFinish={onAddSecondUser}
        fields={fieldsMoreUser}
        onFieldsChange={(_, allFields) => {
          const currentFields = allFields.map((item) => ({
            name: Array.isArray(item.name) ? item.name[0] : '',
            value: item.value,
          }));
          const uniqueData = mergeBy(fieldsMoreUser, currentFields, 'name');
          setMoreUserFields(uniqueData);
        }}
      >
        <S.FormCol>
          <S.HeaderOption>
            <S.BackWrapper onClick={closeAddMore}>
              <S.BackIcon />
              {t('common.back')}
            </S.BackWrapper>
            <S.SupportWrapper
              onClick={(e) => {
                window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}}`;
                e.preventDefault();
              }}
            >
              <S.GlobeIcon src={globeIcon} />
              {t('common.support')}
            </S.SupportWrapper>
          </S.HeaderOption>
          <S.FormTitleBlack>
            {type === RoleTypes.SELLER ? t('signup.tabs.additionalSeller') : t('signup.tabs.additionalBuyer')}
          </S.FormTitleBlack>
          <S.FormDescription>
            {type === RoleTypes.SELLER
              ? t('signup.stepForm.extraInfoAddMoreSeller')
              : t('signup.stepForm.extraInfoAddMoreBuyer')}
          </S.FormDescription>
          <S.FormConatiner>
            <S.FormItem
              name="firstName"
              label={
                type === RoleTypes.SELLER
                  ? t('forms.stepFormLabels.moreSellerFirstName')
                  : t('forms.stepFormLabels.moreBuyerFirstName')
              }
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Input placeholder={t('common.firstName')} />
            </S.FormItem>
            <S.FormItem
              name="lastName"
              label={
                type === RoleTypes.SELLER
                  ? t('forms.stepFormLabels.moreSellerLastName')
                  : t('forms.stepFormLabels.moreBuyerLastName')
              }
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Input placeholder={t('common.lastName')} />
            </S.FormItem>
            <S.FormItem
              name="email"
              label={t('common.email')}
              rules={[
                { required: true, message: t('common.requiredField') },
                {
                  type: 'email',
                  message: t('common.notValidEmail'),
                },
              ]}
            >
              <Input placeholder={t('inputs.enterYourEmail')} />
            </S.FormItem>
            <S.PhoneItem
              name="phone"
              label={t('common.phone')}
              rules={[
                { required: true, message: t('forms.stepFormLabels.phoneError') },
                { pattern: new RegExp(checkOnlyNumber), message: t('common.shouldBeANumber') },
                { len: 7, message: t('common.phoneNumberLengthError', { length: 7 }) },
              ]}
            >
              <Input
                addonBefore={
                  <BaseForm.Item name="phoneprefix" noStyle>
                    <S.Select defaultValue={defaultCountryDialCode}>
                      {localDialCodeIsrael?.map((item, index) => {
                        return (
                          <Option key={`${item.name}-${index}`} value={item.dial_code}>
                            <S.CountriesSpace align="center">{`${item.dial_code}`}</S.CountriesSpace>
                          </Option>
                        );
                      })}
                    </S.Select>
                  </BaseForm.Item>
                }
              />
            </S.PhoneItem>
            <S.Subtitle>
              {type === RoleTypes.SELLER
                ? t('signup.stepForm.uploadSecondSellerDocuments')
                : t('signup.stepForm.uploadSecondBuyerDocuments')}
            </S.Subtitle>
            <S.TakePhotoWrapper>
              <S.FormItem
                name="moreUserFrontImage"
                label={t('common.frontside')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Space.Compact style={{ width: '100%' }}>
                  <Button type="primary" onClick={() => enableCamera('moreUserFront')}>
                    {t('common.takephoto')}
                  </Button>
                  <Input
                    placeholder={t('common.frontside')}
                    disabled
                    value={moreUserFormValues.moreUserFrontImage as string}
                  />
                </Space.Compact>
              </S.FormItem>
              <S.NoteWrapper justifyContent={'flex-end'}>
                <Upload {...props}>
                  <S.UploadFile onClick={() => setImageSide('moreUserFront')}>{t('common.uploadFile')}</S.UploadFile>
                </Upload>
              </S.NoteWrapper>
            </S.TakePhotoWrapper>
            <S.TakePhotoWrapper>
              <S.FormItem
                name="moreUserBackImage"
                label={t('common.backside')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Space.Compact style={{ width: '100%' }}>
                  <Button type="primary" onClick={() => enableCamera('moreUserBack')}>
                    {t('common.takephoto')}
                  </Button>
                  <Input
                    placeholder={t('common.backside')}
                    disabled
                    value={moreUserFormValues.moreUserBackImage as string}
                  />
                </Space.Compact>
              </S.FormItem>
              <S.NoteWrapper justifyContent={'flex-end'}>
                <Upload {...props}>
                  <S.UploadFile onClick={() => setImageSide('moreUserBack')}>{t('common.uploadFile')}</S.UploadFile>
                </Upload>
              </S.NoteWrapper>
            </S.TakePhotoWrapper>
            <S.BtnBlue className="userToDeal" htmlType="submit">
              <S.FlexBtnCenter>
                <S.BtnText>
                  {type === RoleTypes.SELLER
                    ? t('forms.stepFormLabels.addSecondSellerToDeal')
                    : t('forms.stepFormLabels.addSecondBuyerToDeal')}
                </S.BtnText>
              </S.FlexBtnCenter>
            </S.BtnBlue>
            <S.BtnBlue type="primary">
              <Link to="/logout">{t('header.logout')}</Link>
            </S.BtnBlue>
          </S.FormConatiner>
        </S.FormCol>
      </BaseForm>
    </>
  );
};
