import React, { useState, useEffect } from 'react';
import * as S from './TermsAndConditions.styles';
import { Link } from 'react-router-dom';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';

interface TermsAndConditionsProps {
  onClick?: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const [checkboxData, setCheckboxData] = useState({
    terms: false,
  });
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (Object.values(checkboxData).every((data) => data)) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [checkboxData]);

  const handleChange = (e: CheckboxChangeEvent) => {
    setCheckboxData({ ...checkboxData, [e.target.name as string]: e.target.checked });
  };

  return (
    <S.Wrapper>
      <S.TermsAndCondContentWrapper>
        <S.Title>{t('signup.termsAndConditions.ourTermsAndConditions')}</S.Title>
        <S.Title>{t('signup.termsAndConditions.termsAndCondition')}</S.Title>
        <S.Notes>{t('signup.termsAndConditions.note1')}</S.Notes>
        <S.Points>
          <span>{t('signup.termsAndConditions.point1Title')}</span> {t('signup.termsAndConditions.point1')}
        </S.Points>
        <S.Points>
          <span>{t('signup.termsAndConditions.point2Title')}</span> {t('signup.termsAndConditions.point2')}
        </S.Points>
        <S.Points>
          <span>{t('signup.termsAndConditions.point3Title')}</span> {t('signup.termsAndConditions.point3')}
        </S.Points>
      </S.TermsAndCondContentWrapper>

      <S.TermsAndCondWrapper>
        <S.CustomeCheckbox checked={checkboxData.terms} name="terms" onChange={handleChange}>
          <S.Notes className="privacy-terms-check">
            {t('signup.termsAndConditions.signingDescription')}{' '}
            <Link to="">{t('signup.termsAndConditions.termsOfUser')}</Link> {t('common.and')}{' '}
            <Link to="">{t('signup.termsAndConditions.provacyPolicy')}</Link>.
          </S.Notes>
        </S.CustomeCheckbox>
        <S.BtnBlue disabled={disableButton} marginTop={10} type="primary" htmlType="submit" onClick={onClick}>
          {t('signup.termsAndConditions.iAgree')}
        </S.BtnBlue>
      </S.TermsAndCondWrapper>
    </S.Wrapper>
  );
};

export default TermsAndConditions;
