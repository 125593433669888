import styled from 'styled-components';
import loginBackground from '@app/assets/images/login-bg.webp';
import { Button } from '@app/components/common/buttons/Button/Button';

interface BoxPartInterFace {
  gap?: number;
  justifyContent?: string;
}

interface WrapperInterFace {
  disableBgImage?: boolean;
}

interface BtnBlueInterFace {
  marginTop?: number;
}

interface DescriptionInterFace {
  paddingX?: number;
}

export const Wrapper = styled.div<WrapperInterFace>`
  width: 100vw;
  height: 100vh;
  padding: 20px;
  max-width: 420px;
  margin: 0 auto;
  background: ${(props) => (props.disableBgImage ? 'none' : `url(${loginBackground})`)};
`;

export const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

export const BoxPart = styled.div<BoxPartInterFace>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  align-items: center;
`;

export const FooterText = styled.span`
  color: var(--primary-color);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

export const Description = styled.span`
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-top: 16px;
  display: block;
`;

export const DescriptionTwo = styled.span<DescriptionInterFace>`
  color: rgb(185, 178, 178);
  font-size: 16px;
  text-align: center;
  padding: ${(props) => `10px ${props.paddingX ? props.paddingX : '0'}px`};
  display: block;

  span {
    font-weight: bold;
  }
`;

export const BtnWhite = styled.button`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid white;
  background: white;
  color: var(--primary-color);
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  max-width: 360px;
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexBtnCenter = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const BtnText = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const BtnBlue = styled(Button)<BtnBlueInterFace>`
  width: 100%;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid white;
  background: var(--primary-color);
  color: white;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  max-width: 360px;
  margin-top: ${(props) => `${props.marginTop ? props.marginTop : '0'}px`};

  :active,
  :focus,
  :hover {
    background: var(--primary-color) !important;
    border: 2px solid white !important;
    color: white !important;
  }
`;

export const HeaderOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;
`;

export const GlobeIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  src: url(${(props) => props.src});
`;

export const SupportWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  color: var(--primary-color);
  cursor: pointer;
`;
